import styled from 'styled-components';

export const StyledCastling = styled.span< { disabled:boolean }>`
  margin: 5px 0;
  cursor: pointer;
  position: relative;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  padding: 2px 10px;
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};
  border: 1px solid ${({ theme }) => theme.currentTheme.iconLight};
  border-radius: 4px;
  overflow: hidden;
  transition: 0.3s;

  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;

  &:hover {
    background-color: ${({ theme }) => theme.currentTheme.hover};
  }
  
  mask-image${p => p.disabled ? "" : "x"}: linear-gradient(
          -35deg, 
          #000 calc(50% - 3px), 
          transparent calc(50% - 2px),
          transparent calc(50% + 2px),
          #000 calc(50% + 3px) 
  );

`;

export const StyledCastlings = styled.span`
  display: flex;
  gap: 10px;
`
export const StyledRedDiagonalLine = styled.span`
  margin: 3px 0;
  transition: 0.3s;
  transform-origin: calc(50% + 21.5px) 0%;
  pointer-events: none;
  background: linear-gradient(
          -35deg,
          transparent calc(50% - 1px),
          red calc(50% - .5px),
          red calc(50% + .5px),
          transparent calc(50% + 1px)
  );
`