import React, { PropsWithChildren, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import { EnterLessonPageStyledComponents } from './EnterLessonPageStyledComponents';
import { Logo, LogoSize } from '../../components/Icon/Logo';
import { PageFooter, Preloader } from '../../components';
import { EnterLessonInitialPhase } from './EnterLessonInitialPhase';
import { useAuthStore } from '../../store/auth/AuthStore';
import { Link } from '../../components/low-level-components/Link';

const {
  StyledLogoContainer,
} = EnterLessonPageStyledComponents;

export function EnterLessonPage() {

  const { lessonAuth, lessonAuthSettings, user } = useAuthStore();

  const [studentName, setStudentName] = useState<string>('');

  if (!lessonAuthSettings && !user)
    return <Preloader />;

  if (!lessonAuthSettings && user)
    return <MessageWrapper>lesson not found</MessageWrapper>;

  if (lessonAuthSettings && lessonAuthSettings.error)
    return <MessageWrapper>{lessonAuthSettings.error}</MessageWrapper>

  return <EnterLessonPageTemplate>

      {lessonAuthSettings && (
        <EnterLessonInitialPhase
          studentName={studentName}
          setStudentName={setStudentName}
          password={lessonAuthSettings.password}
          nextStep={() => lessonAuth(studentName)} />
      )}

      {/*{phase === 'captcha' && (*/}
      {/*  <CaptchaStep*/}
      {/*    email={'userId'}*/}
      {/*    onSuccess={() => lessonAuth(studentName)}*/}
      {/*    confirmButtonText='Продолжить'*/}
      {/*  />*/}
      {/*)}*/}

    </EnterLessonPageTemplate>
}


function EnterLessonPageTemplate(props: PropsWithChildren<{  }>) {
  return <Container style={{ height: '100%', maxWidth: '90%', display: 'grid', margin: '0 auto', padding: '0' }} fixed>
    <Grid container justifyContent='center'>
      <Grid style={{ maxWidth: '350px' }} item sm={8} md={6} lg={4}>

        <Grid container justifyContent='center'>
          <StyledLogoContainer>
            <Logo size={LogoSize.small}/>
          </StyledLogoContainer>
        </Grid>

        {props.children}

      </Grid>
    </Grid>
    <PageFooter />
  </Container>
}

function MessageWrapper(props: PropsWithChildren<{}>) {
  return <EnterLessonPageTemplate>
    <div style={{
      width: "100%",
      textAlign: "center",
      paddingTop: 30,
      fontSize: 30
    }}>
      {props.children}
    </div>
    <div style={{
      width: "100%",
      textAlign: "center",
      paddingTop: 30,
    }}>
      <Link to='/auth/login'>На главную</Link>
    </div>
  </EnterLessonPageTemplate>
}

