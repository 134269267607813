import React from "react";
import { useLessonStore } from '../../../store/lesson/LessonStore';

export function LessonContainerSubtitle() {

  const {
    isStudentLayout,
    teacherName,
    lessonName,
    monitoringStudentId,
    users,
  } = useLessonStore();

  function getSubtitle() {
    if (isStudentLayout) {
      return teacherName;
    }
    if (monitoringStudentId) {
      return users.find(u => u.id === monitoringStudentId)?.displayName;
    }
    return lessonName;
  }

  return <>{getSubtitle()}</>
}