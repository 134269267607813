import React, { FunctionComponent, useRef, useState } from 'react';
import styled from 'styled-components';
import { IconButton, TextField, InputAdornment } from '@material-ui/core';

import { IInput } from '../../interfaces/input';
import { SvgImage } from '../Icon/SvgImage';
import { KnownIcons } from '../Icon/KnownIcons';

const StyledInput = styled(TextField)<{
  $searchStyle?: boolean;
  $noFixedHeight?: boolean;
  $scrollbarOpacity: number;
  $lessonMaterialStyle?: boolean;
  $minHeight?: number;
  $chatInput: boolean;
}>`
  ${({ $chatInput }) =>
    $chatInput &&
    `
    & .MuiInputLabel-formControl{
      max-width: 90%;
    }
    & .MuiFormControl-root,
    .MuiTextField-root {
      position: relative;
    }
    & .MuiInputBase-root {
      position: relative;
    }
  `}

  & .MuiInputBase-inputMultiline {
    max-height: ${({ rows }) => typeof rows === 'number' && rows * 20}px;
  }
  & .MuiFilledInput-root {
    border-radius: 12px;
    ${({ rows }) => (rows && rows > 1 ? 'align-items: flex-start;' : null)}
    ${({ $lessonMaterialStyle }) => $lessonMaterialStyle && 'height: 48px !important'}
  }

  & .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(12px, 6px) scale(0.75);
  }

  & .MuiFilledInput-root {
    background: none !important;
    border: 1px solid ${({ theme }) => theme.currentTheme.iconLight};
  }

  & .MuiFilledInput-underline:after,
  & .MuiFilledInput-underline:before {
    display: none;
  }

  & .MuiInputAdornment-root {
    margin: 0px !important;
  }

  & .MuiFilledInput-adornedEnd {
    padding-right: 0px;
    position: relative;
  }
  & .MuiInputAdornment-filled {
    ${({ multiline }) =>
      multiline &&
      `top: 20px;
    right: 0px;
    position: absolute;`}
  }

  .MuiInputLabel-filled.MuiInputLabel-marginDense {
    transform: translate(12px, 14px) scale(1);
  }

  & .MuiInputLabel-filled {
    transform: translate(12px, 14px) scale(1);
  }

  & .MuiFormLabel-root {
    color: ${({ theme }) => theme.currentTheme.iconMain} !important;
    font: ${({ theme }) => theme.fonts.normal};
  }

  & .MuiIconButton-root {
    background: none !important;
  }

  & .MuiInputBase-input {
    color: ${({ theme }) => theme.currentTheme.textMain};
    overflow: auto !important;
    font: ${({ theme }) => theme.fonts.normal};

    ${({ $minHeight }) => $minHeight && `min-height: ${$minHeight}px;`}
    ${({ multiline }) => multiline && `padding-right: 2.4rem;`}
    &::-webkit-scrollbar-thumb {
      background: ${({ $scrollbarOpacity }) => `rgb(147 147 147 / ${$scrollbarOpacity})`} !important;
    }
  }

  & .MuiInputBase-root {
    ${({ rows, $noFixedHeight }) => (!rows && !$noFixedHeight ? 'height: 3.001rem;' : '')};
    ${({ $noFixedHeight }) => ($noFixedHeight ? 'height: auto !important;' : '')};

    &.Mui-focused {
      box-shadow: 0px 0px 2px 3px ${({ theme }) => theme.currentTheme.select};
      border-color: ${({ theme }) => theme.currentTheme.button};
    }
    &.Mui-error {
      box-shadow: none;
      border-color: ${({ theme }) => theme.currentTheme.error1};
    }
  }

  & .Mui-disabled.MuiFilledInput-root {
    background: ${({ theme }) => theme.currentTheme.hover} !important;

    * {
      color: ${({ theme }) => theme.currentTheme.iconMain};
    }
  }

  & .MuiInputAdornment-positionStart * {
    color: ${({ theme }) => theme.currentTheme.textMain};
    font: ${({ theme }) => theme.fonts.normal};
  }

  ${({ $searchStyle }) =>
    $searchStyle
      ? `
    & .MuiFilledInput-input {
      padding: 20px 12px 6px;
      border-right: 1px solid #555555;
      padding-right: 2.6rem;
    }
  `
      : null}
`;

const Input: FunctionComponent<IInput> = ({
  label ,
  fullWidth = false,
  type = 'text',
  rows,
  hideClear = false,
  startAdornment,
  endAdornment,
  maxLength,
  noFixedHeight,
  lessonMaterialStyle,
  minHeight,
  chatInput,
  autoComplete,
  ...props
}) => {
  const [value, setValue] = useState('');
  const [error] = useState(false);
  const [scrollbarOpacity, setScrollbarOpacity] = useState(0);

  // Refs
  const intervalId = useRef<NodeJS.Timeout>();

  // Handlers
  const mouseEnterHandler = () => {
    clearInterval(intervalId.current);
    intervalId.current = setInterval(() => {
      setScrollbarOpacity((prev) => {
        if (prev === 1) {
          clearInterval(intervalId.current);
          return 1;
        } else {
          return parseFloat((prev + 0.1).toFixed(2));
        }
      });
    }, 5);
  };
  const mouseLeaveHandler = () => {
    clearInterval(intervalId.current);
    intervalId.current = setInterval(() => {
      setScrollbarOpacity((prev) => {
        if (prev === 0) {
          clearInterval(intervalId.current);
          return 0;
        } else {
          return parseFloat((prev - 0.1).toFixed(2));
        }
      });
    }, 5);
  };

  const inputProps = { endAdornment: endAdornment, startAdornment: null };
  const clearInput = () => setValue('');

  if (value && !hideClear)
    inputProps.endAdornment = (
      <InputAdornment position="start">
        <IconButton disableRipple onClick={clearInput}>
          <SvgImage icon={KnownIcons.cross}/>
        </IconButton>
      </InputAdornment>
    );

  if (startAdornment) {
    inputProps.startAdornment = <InputAdornment position="start">{startAdornment}</InputAdornment>;
  }

  return (
    <StyledInput
      $chatInput={chatInput}
      spellCheck={false}
      $minHeight={minHeight}
      $lessonMaterialStyle={lessonMaterialStyle}
      $scrollbarOpacity={scrollbarOpacity}
      onMouseLeave={mouseLeaveHandler}
      onMouseEnter={mouseEnterHandler}
      type={type}
      error={error}
      fullWidth={fullWidth}
      onChange={(e) => setValue(e.target.value)}
      value={value}
      variant="filled"
      label={label}
      multiline={Boolean(rows)}
      rows={rows}
      maxRows={Boolean(rows) ? Infinity : undefined}
      InputProps={inputProps}
      inputProps={{
        maxLength: maxLength,
        autoComplete: autoComplete === 'off' ? 'new-password' : 'on',
      }}
      $noFixedHeight={noFixedHeight}
      {...props}
    />
  );
};

export default Input;
