import React from "react";
import { KnownIcons } from '../Icon/KnownIcons';
import { SvgImage } from '../Icon/SvgImage';
import { PopupMenu } from '../PopupMenu/PopupMenu';
import { IconSelectWidgetPopupContent } from './IconSelectWidgetPopupContent';
import { IconSelectWidgetProps } from './IconSelectWidgetProps';



export function IconSelectWidget(props: IconSelectWidgetProps) {
  return <div style={{display: 'flex', border: "1px solid var(--color-icon-light)", borderRadius:10}}>
    <PopupMenu popupContent={() => <IconSelectWidgetPopupContent {...props}/>}>
      <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
        <SvgImage icon={KnownIcons[props.icon]} size={44}/>
        <SvgImage icon={KnownIcons.arrow_right} rotate={90} withHover={true} style={{marginLeft:-10}}/>
      </div>
    </PopupMenu>
  </div>
}