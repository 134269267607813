import styled from 'styled-components';
import React, { PropsWithChildren } from 'react';
import { useUiStore } from '../../../store/uiStore';

const StyledFooterIcon = styled.svg<{color: string}>`
  
  cursor: pointer;

  & .mainColor {
    fill: #888;
  }

  & .bgColor {
    fill: ${props => props.theme.currentTheme.background};
  }
  
  &:hover .mainColor {
    fill: ${props => props.theme.currentTheme[props.color] || props.color};
  }

  &:hover .bgColor {
    fill: white;
  }
  
`;

export function FooterIcon(props: PropsWithChildren<{color?:string}>) {
  const {interfaceClr} = useUiStore();
  return <StyledFooterIcon width="24" height="24" color={props.color || interfaceClr}>
    {props.children}
  </StyledFooterIcon>
}