import React, { FormEvent,  useState } from 'react';
import { Box, Grid, InputAdornment } from '@material-ui/core';
import { Link } from '../../components/low-level-components/Link';
import { ContentContainer } from '../../components/ContentContainer';
import Input from '../../components/low-level-components/Input';
import { HelpPageStyledComponents } from './HelpPageStyledComponents';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons } from '../../components/Icon/KnownIcons';

const {
  StyledList,
  StyledListItem,
  StyledParagraph,
  StyledPhotoContainer,
  StyledTitle,
} = HelpPageStyledComponents;

export function HelpPage() {

  const [search, setSearch] = useState('');

  return (
    <PageTemplate>
      <ContentContainer title='Справка'>
        <Grid style={{ marginTop: '1rem' }} container spacing={3}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Input
              value={search}
              onChange={(e: FormEvent<HTMLInputElement>) => setSearch(e.currentTarget.value)}
              // hideClear
              fullWidth
              label='Поиск'
              $searchStyle
              endAdornment={
                <Box position={'relative'} display='flex' alignItems={'center'}>
                  {search ? (
                    <InputAdornment
                      onClick={() => setSearch('')}
                      style={{ paddingRight: '2px', position: 'absolute', right: '3.2rem' }}
                      position='end'
                    >
                      <SvgImage icon={KnownIcons.cross}/>
                    </InputAdornment>
                  ) : null}
                  <InputAdornment style={{ paddingRight: '2px' }} position='start'>
                    <SvgImage style={{ margin: '0.4rem' }} icon={KnownIcons.magnifying_glass} />
                  </InputAdornment>
                </Box>
              }
            />
            {/*<TreeList*/}
            {/*  data={helpList}*/}
            {/*  hideCheckboxes={true}*/}
            {/*  smallPadding={true}*/}
            {/*  helpPage*/}
            {/*  border*/}
            {/*  style={{ marginTop: '20px' }}*/}
            {/*/>*/}
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <StyledTitle>Войти и выйти</StyledTitle>
            <StyledParagraph>
              Вы можете входить в аккаунт через VK Connect, чтобы не вводить имя аккаунта и пароль.
            </StyledParagraph>
            <StyledParagraph>
              VK Connect — это единый аккаунт для сервисов. Если у вас есть профиль в социальной сети ВКонтакте, он
              автоматически стал и вашим VK Connect. Но вы можете создать отдельный VK Connect без профиля ВКонтакте.
            </StyledParagraph>
            <StyledParagraph>
              Прежде чем входить в аккаунт через VK Connect, нужно их связать между собой.
            </StyledParagraph>
            <StyledList>
              <StyledListItem>
                Перейдите на <Link to='/auth/login'>страницу входа</Link>.
              </StyledListItem>
              <StyledListItem>Нажмите изображение сети Вконтакте.</StyledListItem>
              <StyledListItem>Войдите в свой VK Connect, если не авторизованы.</StyledListItem>
            </StyledList>
            <StyledPhotoContainer>

            </StyledPhotoContainer>
            <StyledParagraph>
              Когда вы входите через VK Connect в первый раз, вы связываете с ним аккаунт. Так вы указываете системе,
              какой аккаунт открывать, когда будете входить через этот VK Connect в следующий раз.
            </StyledParagraph>
          </Grid>
        </Grid>
      </ContentContainer>
    </PageTemplate>
  );
}
