import React from 'react';
import { useLessonStore } from '../../../../store/lesson/LessonStore';
import { LessonToolbarButton } from '../components';
import { SvgImage } from '../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../components/Icon/KnownIcons';

export function HandeHochButton() {

  const { handIsUp, handUpAction } = useLessonStore();

  const onClick = (e) => {
    e.stopPropagation();
    handUpAction();
    e.preventDefault();
  };

  return <SvgImage
    icon={KnownIcons.hand}
    active={handIsUp}
    onClick={onClick}
    withHover
  />;
}