import { IQuestion } from '../../interfaces/question';
import { emitCentrifugeEvent } from '@chessclub/realtime_infrastructure';
import { AssignedTasksChangedMessage, makeRoomChannelKey, RoomChannel } from '../../transport/messaging/RoomChannel';
import { create } from 'zustand';
import { ServerApi } from '../../transport/ServerApi';
import {
  ITask,
  RoomId,
  TaskId,
  UserId,
  FEN,
  BoardMove, TaskCustomData,
} from '@chessclub/grpc_wrapper';
import { BoardState, GameState } from '@chessclub/web-game-server';
import { draftTasksDb } from './draftTasksDb';
import { subscribeAtLessonChange } from '../../helpers/subscribeAtLessonChange';
import { uuid } from '@chessclub/web-game-server/src/client/util/uuid';
import { QuestionStoreActions, QuestionStoreInitialState, TasksStorePrivateState } from './TasksStoreApi';
import { IMaterial } from '@chessclub/grpc_wrapper/src/api/types/IMaterial';
import { compareTasks } from '../../logic/compareTasks';
import { wgs, wgsStartTask, wgsStopTask } from '../../app/workers';
import { GameApi } from '@chessclub/web-game-server/src/client/hooks/useCentrifugeChessboard';
import { useLessonStore } from '../lesson/LessonStore';
import { useAuthStore } from '../auth/AuthStore';

type TasksStore = QuestionStoreInitialState & QuestionStoreActions;

const initialState: QuestionStoreInitialState = {
  currentTaskForceUpdate: 'x',
  activeTask: null,
  tasks: [],
  selected: [],
  runtimeTasksStates: {},
};

export const useTasksStore = create<TasksStore>((set, get) => {

  const privateState: TasksStorePrivateState = {
    userId: null,
    roomId: null,
  };

  async function updateTask(taskId: TaskId, updater?: (task: ITask) => Promise<void>) {
    const tasks = get().tasks;

    if (!tasks)
      return;

    const task = tasks.find((q) => q.id === taskId);

    const result = await updater(task);

    Object.assign(task, result);
    set({ tasks: [...tasks] });
  }

  subscribeAtLessonChange(async (roomId: RoomId) => {
    const runtimeTasksStates = {};
    // (await draftTasksDb.getDraftTasks(roomId)).forEach(room => {
    //   runtimeTasksStates[room.id] = room;
    // });
    set({ runtimeTasksStates });
  });

  async function emitAssignedTasksChangedEvent(roomId: RoomId, msg: AssignedTasksChangedMessage) {
    await emitCentrifugeEvent(makeRoomChannelKey(roomId), RoomChannel.ASSIGNED_TASKS_CHANGED, msg,);
  }

  return {
    ...initialState,
    async addOrRemoveMaterial(task: IMaterial, roomId: RoomId, userId: UserId) {
      const found = get().tasks.find(t => compareTasks(t, task));
      if (found) {
        get().deleteQuestionById({ qId: found.id, roomId, userId });
      } else {
        get().addQuestion({
          isActive: false,
          roomId, userId,
          ...task,
          id: null,
        });
      }
    },

    async resetTaskRuntimeState(id: TaskId) {
      const runtimeTasksStates = { ...get().runtimeTasksStates };
      delete runtimeTasksStates[id];
      // await draftTasksDb.deleteDraftTask(id);
      set({ runtimeTasksStates, currentTaskForceUpdate: uuid() });
    },

    async updateRuntimeTaskState(id: TaskId, gameState: GameState) {
      const runtimeTasksStates = { ...get().runtimeTasksStates };
      const originalTask = get().tasks.find(t => t.id === id);
      const task = { ...(runtimeTasksStates[id] || originalTask) };
      task.fen = gameState.fen;
      task.moves = gameState.moves;
      task.tools = gameState.markers;
      task.customData = { ...task.customData };
      task.customData.rulesSettingOn = gameState.applyRules;
      task.customData.timerSettingOn = gameState.isTimerOn;
      task.customData.boardLabels = gameState.boardLabels;
      if (!compareTasks(task, originalTask)) {
        runtimeTasksStates[id] = task;
        // await draftTasksDb.saveDraftTask(task);
        // debugger
      }
      set({ currentTaskGameState: gameState, runtimeTasksStates });
    },

    setCurrentTaskGameState(gameState: GameState) {
      set({ currentTaskGameState: gameState });
    },

    setCurrentTaskBoardState(boardState: BoardState) {
      set({ currentTaskBoardState: boardState });
    },

    setCurrentTaskGameApi(gameApi: GameApi) {
      set({ currentTaskGameApi: gameApi });
    },

    async clearGameProgress(id: TaskId) {
      await updateTask(id, async (task: ITask) => {
        return await ServerApi.tasksService.updateTask({ id: task.id, moves: [] });
      });
    },

    async makeMove(id: TaskId, fen: FEN, moves: BoardMove[]) {
      const tasks = get().tasks;

      if (!tasks)
        return;

      const task = tasks.find((q) => q.id === id);

      if (!task || task.fen === fen)
        return;

      const result = await ServerApi.tasksService.updateTask({ id: task.id, fen, moves });

      Object.assign(task, result);

      set({ tasks: [...tasks] });

    },

    async loadTasksForUserInRoom(params: { roomId: RoomId, userId: UserId, tasks?: ITask[] }) {
      privateState.userId = params.userId;
      privateState.roomId = params.roomId;
      const result = params.tasks || await ServerApi.tasksService.getRoomTasks(params.roomId, params.userId);
      set({ tasks: result, activeTask: result.find(t => t.isActive), currentTaskGameState: null });
    },

    async setActiveTask(taskId: TaskId) {
      const activeTask = get().tasks.find(t => t.isActive);
      if (activeTask)
        activeTask.isActive = false;
      const task = get().tasks.find(t => t.id === taskId);
      task.isActive = true;
      // await ServerApi.tasksService.updateTask(task);
      await ServerApi.tasksService.setActiveTask(task.id);
      set({ activeTask: task, selected: [] });
    },

    updateSelection(taskId: TaskId) {
      if (taskId === null) {
        set({ selected: [] });
        return;
      }
      const { selected, activeTask } = get();
      if (selected.includes(taskId)) {
        set({ selected: selected.filter(q => q !== taskId) });
      } else {
        set({ selected: [...selected, taskId] });
      }
    },

    //// old

    copyQuestions: async (args: { parentId: RoomId; idsList: TaskId[] }) => {
      // const { checkEditingMaterials } = useEditingMaterialStore.getState();
      const result = await ServerApi.tasksService.copyTask(args.parentId, args.idsList);
      set({ tasks: [...get().tasks, ...result] });
      // checkEditingMaterials();
    },

    addQuestion: async (args: IQuestion) => {
      const id = await ServerApi.tasksService.createTask(args);
      const newTask = { ...args, id };
      set({ tasks: [...get().tasks, newTask] });

      if (args.userId === useAuthStore.getState().user.id ) { // teacher
         wgsStartTask(newTask, privateState.userId, privateState.userId);
      } else { // student
        await emitAssignedTasksChangedEvent(args.roomId, {
          userIds: [args.userId],
          // added: [id]
        });
      }
    },
    updateQuestion: async (question: Partial<IQuestion>) => {
      let tasks = get().tasks;
      const task = tasks.find((q) => q.id === question.id);
      const newVersion = (task?.customData?.version || 1) + 1;
      if (!question.customData)
        question.customData = {} as TaskCustomData;
      question.customData.version = newVersion;

      await ServerApi.tasksService.updateTask(question);

      const newTask = {...task, ...question}
      const index = tasks.indexOf(task);
      tasks.splice(index, 1, newTask);

      set({ tasks: [...tasks]  });

      if (!useLessonStore.getState().isOnline) { // offline edit
        return;
      }

      // online edit

      if (question.userId === useAuthStore.getState().user.id) { // local

        await wgsStopTask(question.id);
        await wgsStartTask(question as ITask, question.userId, question.userId);

      } else { // remote

        await emitAssignedTasksChangedEvent(privateState.roomId, {
          userIds: [question.userId],
          changed: question.id,
        });

      }

    },
    assignQuestions: async (data: { questionsIds: TaskId[]; userIds: UserId[]; socketIds: string[]; roomId: RoomId }) => {
      await ServerApi.tasksService.assignTasks(data.questionsIds, data.userIds);
      await emitAssignedTasksChangedEvent(data.roomId, {
        userIds: data.userIds
      });
    },
    deleteQuestions: async (questionsIds: TaskId[]) => {
      await ServerApi.tasksService.deleteTasks(questionsIds);
      set({
        tasks: get().tasks.filter((question) => !questionsIds.includes(question.id)),
        selected: [],
      });
      questionsIds.forEach(wgs.stopTask)
    },
    deleteQuestionById: async (data: { qId: TaskId; roomId: RoomId; userId: UserId }) => {
      await ServerApi.tasksService.deleteTaskById(data.qId);
      await emitAssignedTasksChangedEvent(data.roomId, {
        userIds: [data.userId],
        deleted: [data.qId]
      });
      set({ tasks: get().tasks.filter(t => t.id !== data.qId) });
    },
  } as TasksStore;
});

