import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { clamp01 } from '@chessclub/web-game-server/src/shared/util/math';
import { useThemeColors } from '../../hooks/useThemeColors';
import styled from 'styled-components';


const StyledSvg = styled.svg<{$color, $duration}>`
  & .fill {
    fill: ${p => p.$color};
    transition: ${p => p.$duration}ms;
    transition-property: fill;
  }

  & .stroke {
    stroke: ${p => p.$color};
    transition: ${p => p.$duration}ms;
    transition-property: stroke, fill;
  }
`;

type SvgIconAnimationParams = PropsWithChildren<{
  interpolator: (number) => string;
  active: boolean;
  color?: string;
  duration: number;
  transform?: (number) => string;
  viewport: number;
  size: number;
  strokeWidth?: number;
  id: string;
}>;

export function SvgIconAnimation(props: SvgIconAnimationParams) {
  const { id, interpolator, transform, active, color, duration, size, viewport: vp } = props;

  const ref = useRef<SVGPathElement>();
  const animationStartTimestamp = useRef<number>();
  const frame = useRef<number>(); // interval from 0 to 1
  const direction = useRef<number>(); // -1 or 1 (sign)
  const { accent, icon } = useThemeColors();

  function setFrame() {
    interpolator && ref.current?.setAttribute('d', interpolator(frame.current));
    transform && ref.current?.setAttribute('transform', transform(frame.current));
  }

  function animate() {
    let dt = Date.now() - animationStartTimestamp.current;
    dt /= duration;
    frame.current = clamp01(direction.current === -1 ? dt : 1 - dt);
    setFrame();
    dt < 1 && requestAnimationFrame(animate);
  }

  useEffect(() => {
    direction.current = active ? 1 : -1;
    if (frame.current === undefined) {
      frame.current = active ? 1 : 0;
      setFrame();
    } else if (frame.current === 0 || frame.current === 1) {
      animationStartTimestamp.current = Date.now();
      requestAnimationFrame(animate);
    }
  }, [active]);

  const viewBox = [-vp / 2, -vp / 2, vp, vp].toString();

  return <StyledSvg
    $color={active ? (color || accent) : icon}
    $duration={duration}
    viewBox={viewBox}
    width={size}
    height={size}
  >
    <defs>
      <path
        id={id}
        ref={ref}
      />
    </defs>
    {props.children}
  </StyledSvg>;
}