import styled from 'styled-components';



export const StyledInputGroupTitle = styled.h3`
  padding: 0px;
  margin: 0px;
  font: ${({ theme }) => theme.fonts.smallBold};
  color: ${({ theme }) => theme.currentTheme.textMain};
  margin-bottom: 18px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }
`;



export const Agreement = styled.span`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.iconMain};
`;

export const OneButtonWrapper = styled.div`
  & > * {
    width: 50%;
  }
`;

export const TwoButtonsWrapper = styled.div`
  margin-top: 28px;
  margin-bottom: 48px;
  display: flex;

  & > * {
    width: calc(100% / 3);

    &:first-child {
      margin-right: 30px;
    }
  }
`;

export const StyledMessage = styled.div`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};
  text-align: center;
`;
