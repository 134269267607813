import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import {  IMessageText } from '../../../../../../interfaces/chat-message';
import { MessageRelate } from './MessageRelate';
import { getMessageDate } from '../../../../../../helpers/time.helpers';
import { ChessboardSvg } from '../../../../../../components/Chessboard/ChessboardSvg';
import { FEN } from '@chessclub/grpc_wrapper';

const StyledMessageBody = styled.div<{ height: number; me: boolean }>`
  padding: ${({ me }) => (!me ? '0 0px 0 30px' : '0 5px 0 0')};
  box-sizing: border-box;
  position: relative;
  height: ${({ height }) => `${height}px`};
  display: flex;
  justify-content: ${({ me }) => (!me ? 'start' : 'end')};
  > * {
    position: absolute;
  }
`;

const StyledMessageText = styled.pre<IMessageText & { me: boolean }>`
  max-width: 80%;
  overflow-wrap: break-word;
  background: ${({ me, theme }) => (me ? theme.currentTheme.select : theme.currentTheme.hover)};
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};
  box-sizing: border-box;
  border-radius: ${({ me }) => (me ? '11px 0px 11px 11px' : `0px 11px 11px 11px`)};
  line-height: 1.2;
  padding: 7px 10px 5px 10px;
  min-width: 150px;
  p {
    span {
      color: ${({ theme }) => theme.currentTheme.textMain} !important;
    }
  }
`;

const StyledMessageTime = styled.div`
  font: ${({ theme }) => theme.fonts.small};
  color: ${({ theme }) => theme.currentTheme.iconMain};
  font-size: 14px;
  display: flex;
  justify-content: end;
  float: right;
  margin-top: -3px;
  padding-left: 10px;
  width: 100px;
`;



export function ChatMessageBody({ text, me, relate, time }:{ text, me, relate, time }) {

  const messageTextRef = useRef<HTMLPreElement>(null);
  const [messageTextHeight, setMessageTextHeight] = useState<number>(80);

  useEffect(() => {
    try {
      setMessageTextHeight(parseFloat(getComputedStyle(messageTextRef.current).height));
    } catch (e) {
    }
  }, []);


  let txt = decodeURIComponent(escape(atob(text)));


  if (txt.startsWith('FEN:')) {
    return <StyledMessageBody height={227} me={me}>
      <StyledMessageText me={me} ref={messageTextRef}>
        <div style={{ width: 200, height: 200 }}>
          <ChessboardSvg fen={txt.substring(4).trim() as FEN} />
        </div>
        <StyledMessageTime>{getMessageDate(time)}</StyledMessageTime>
      </StyledMessageText>
    </StyledMessageBody>;
  }

  return (
    <StyledMessageBody height={messageTextHeight} me={me}>
      <StyledMessageText me={me} ref={messageTextRef}>
        {relate && <MessageRelate inMessage headerText={relate.author} messageText={relate?.text as string} />}
        {txt}
        <StyledMessageTime>{getMessageDate(time)}</StyledMessageTime>
      </StyledMessageText>
    </StyledMessageBody>
  );
}

