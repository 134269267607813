import React from 'react';
import { ChessboardWithServerLogic } from '../../../components/Chessboard/ChessboardWithServerLogic';
import { useLessonStore } from '../../../store/lesson/LessonStore';
import { useTaskToDisplay } from '../../../hooks/useTaskToDisplay';
import styled from 'styled-components';
import { LocalLessonChessboardWithControls } from './LocalLessonChessboardWithControls';


export const StyledChessboardCard = styled.div`
  grid-area: C;
  z-index: 1;
  display: flex;
  margin-top: 12px;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledChessboardWrapper = styled.div`
  position: relative;
`;


export function ChessboardWrapper() {
  // const boardWrapperRef = useRef<HTMLDivElement>();
  const { isOnline, monitoringStudentId, users} = useLessonStore();

  const {taskToDisplay} = useTaskToDisplay();

  if (!taskToDisplay)
    return null;

  let online = isOnline && monitoringStudentId ? users.find(user => user.id === monitoringStudentId).socketId : isOnline;

  return <StyledChessboardCard>
    <StyledContainer>
      <StyledChessboardWrapper>
        {
          online ?
            <ChessboardWithServerLogic boardId={taskToDisplay?.id} resizer/> :
              <LocalLessonChessboardWithControls task={taskToDisplay}/>
        }
      </StyledChessboardWrapper>
    </StyledContainer>
  </StyledChessboardCard>;
}

