import React from 'react';
import { FooterIcon } from './FooterIcon';

export function Social_Zen() {
  return <FooterIcon color='black'>
    <path
      d='M0 12C0 5.37258 5.37258 0 12 0C18.6275 0 24 5.37258 24 12C24 18.6275 18.6275 24 12 24C5.37258 24 0 18.6275 0 12Z'
      className='mainColor'
    />
    <path
      d='M24 12.1285V11.8715C18.6856 11.7 16.26 11.5715 14.3144 9.68565C12.4285 7.74 12.2914 5.31435 12.1285 0H11.8715C11.7 5.31435 11.5715 7.74 9.68565 9.68565C7.74 11.5715 5.31435 11.7086 0 11.8715V12.1285C5.31435 12.3 7.74 12.4285 9.68565 14.3144C11.5715 16.26 11.7086 18.6856 11.8715 24H12.1285C12.3 18.6856 12.4285 16.26 14.3144 14.3144C16.26 12.4285 18.6856 12.2914 24 12.1285Z'
      className='bgColor'
    />
  </FooterIcon>;
}