import React from 'react';
import styled from 'styled-components';
import { QuestionCard }  from '../components/Question/QuestionCard';
import { HidingScrollbar } from '../../../../components/low-level-components/HidingScrollbar';
import { useTasksStore } from '../../../../store/tasks/TasksStore';
import { useLocalization } from '../../../../l10n/UseLocalization';
import { TextNode } from '../../../../components/low-level-components/TextNode';


const StyledQuestions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  /* max-height: calc(125px * 3); */
  max-height: 214px;
  gap:3px
 
`;

const StyledQuestionsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export function LessonTasksPanel() {
  const {lesson} = useLocalization();
  const { tasks } = useTasksStore();
  return <StyledQuestionsWrapper>
    <HidingScrollbar style={{ flexGrow: 1, overflowX: 'hidden', overflowY: 'scroll', borderTop: 'none' }}>
      <StyledQuestions style={{ flexGrow: 1 }}>
        {tasks.length ? tasks.map(task => {
          return <QuestionCard {...task} key={task.id} />;
        }) : <TextNode style={{ padding: `10px 5px` }}>{lesson.empty}</TextNode>}
      </StyledQuestions>
    </HidingScrollbar>
  </StyledQuestionsWrapper>;
}

export default LessonTasksPanel;
