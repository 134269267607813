import React from 'react';
import { FooterIcon } from './FooterIcon';

export function Theme_dark() {
  return <FooterIcon>
    <path
      d='M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z'
      className="mainColor"
    />
    <path
      d='M8.47322 12C8.88371 13.6875 9.56786 17.625 16 19.875C14.7431 20.5918 13.2965 21 11.7572 21C6.92071 21 3 16.9706 3 12C3 7.02944 6.92071 3 11.7572 3C8.47322 5.25 8.06273 10.3125 8.47322 12Z'
      className="bgColor"
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 4C16.5523 4 17 4.44772 17 5V6H18C18.5523 6 19 6.44772 19 7C19 7.55228 18.5523 8 18 8H17V9C17 9.55228 16.5523 10 16 10C15.4477 10 15 9.55228 15 9V8H14C13.4477 8 13 7.55228 13 7C13 6.44772 13.4477 6 14 6H15V5C15 4.44772 15.4477 4 16 4Z'
      className="bgColor"
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19 11C19.5523 11 20 11.4477 20 12C20.5523 12 21 12.4477 21 13C21 13.5523 20.5523 14 20 14C20 14.5523 19.5523 15 19 15C18.4477 15 18 14.5523 18 14C17.4477 14 17 13.5523 17 13C17 12.4477 17.4477 12 18 12C18 11.4477 18.4477 11 19 11Z'
      className="bgColor"
    />


  </FooterIcon>;
}
