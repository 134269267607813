import { cos, HALF_PI, PI, sin, TAU } from '@chessclub/web-game-server/src/shared/util/math';
import React from 'react';
import { SvgIconAnimation } from '../SvgIconAnimation';
import { uuid } from '@chessclub/web-game-server/src/client/util/uuid';

let CX = 16;
let CY = 17;
let r1 = 5.5;
let r2 = 3.5;

function interpolator(t) {
  const TT = t * TAU, TT1 = TT - HALF_PI, TT2 = TT / 12;
  return `
    M13 ${ 6 - cos(t * PI*2) }H19
    M${cos(TT1) * r1 + CX} ${sin(TT1) * r1 + CY}
    L16 17 
    L${cos(TT2) * r2 + CX} ${sin(TT2) * r2 + CY}
  `;
}

export function Stopwatch(props: { active: boolean;}) {
  const id = uuid()
  return <SvgIconAnimation
    {...props}
    id={id}
    duration={300}
    viewport={32}
    size={32}
    interpolator={interpolator}
  >
    <g transform="translate(-16,-16)" className="stroke" fill="none" strokeWidth="2">
        <circle cx="16" cy="17" r="8"  />
        {/*<path d="M13 5H19"/>*/}
        <path d="M16 9L16 6"/>
        <path d="M11 10L9 8"/>
        <path d="M21 10L23 8"/>
        {/*<path d="M16 17L16 12"/>*/}
        {/*<path d="M15 18L20 18"/>*/}
        <use xlinkHref={"#" + id} strokeLinejoin="round"/>
    </g>
  </SvgIconAnimation>;
}