import React, { useEffect, useState } from 'react';
import { DndType, useDndStore } from '../../store/dndStore';
import styled from 'styled-components';

const StyledDropZone = styled.div`
  box-sizing: border-box;
  transition: 0.3s;
  z-index: -1;
  border-radius: 5px;

  &.active {
    z-index: 11;
    box-shadow: inset 0 0 10px #f009;
  }

  &.dragOver {
    z-index: 11;
    box-shadow: inset 0 0 10px rgba(11, 152, 0, 0.6);
  }
`;

type DropTarget = {
  type: DndType;
  onDrop: (string) => void;
};

export function DropZone({ type, onDrop }: DropTarget) {
  const { dragEvent } = useDndStore();
  const [ dragOver, setDragOver ] = useState<boolean>();

  useEffect(() => {
    !dragEvent && setDragOver(false)
  }, [dragEvent])

  const className = dragEvent?.type === type ?
    (dragOver ? 'dragOver' : 'active') : '';

  function drag(ev) {
    if (dragEvent?.type === type)
      ev.preventDefault()
  }

  return <StyledDropZone
    onDrop={() => onDrop(dragEvent.data)}
    onDragOver={drag}
    onDragEnter={() => setDragOver(true)}
    onDragLeave={() => setDragOver(false)}
    onDragExit={() => setDragOver(false)}
    className={className}
  />;
}