import React from "react";
import { ApplyCancelToolbarVariant } from '../../../parts/ApplyCancelToolbarVariant';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import { ITask } from '@chessclub/grpc_wrapper';
import { useTasksStore } from '../../../store/tasks/TasksStore';

export function LessonEditTaskToolbarVariant() {

  const {editingTask, stopEditTask} = useEditingTaskStore();
  const {updateQuestion} = useTasksStore();

  function apply() {
    updateQuestion(editingTask as ITask);
    stopEditTask();
  }

  return <ApplyCancelToolbarVariant apply={apply} cancel={stopEditTask}/>
}