import React from 'react';
import {LocalCameraCard} from './CameraCard/variants/LocalCameraCard';
import {useLessonStore} from "../../../../../store/lesson/LessonStore";
import {useConferenceStore} from "../../../../../store/conferenceStore";
import { RemoteCameraCard } from './CameraCard/variants/RemoteCameraCard';

export function RoomOwnerCamera() {
  const { currentUser } = useLessonStore();
  const mainCamera = useConferenceStore(state => state.findRoomOwnerCameraUser());
  if (!mainCamera || !currentUser)
    return
  return <div style={{ marginRight: 3, marginBottom: 5 }}>
    {
    mainCamera.id === currentUser.id ?
      <LocalCameraCard /> :
      <RemoteCameraCard user={mainCamera}/>
  }
  </div>;
}
