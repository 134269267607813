import React, { CSSProperties, useEffect, useMemo, useRef } from 'react';
import { PropsWithChildren, useState } from 'react';
import { useStoredState } from '../../hooks/useStoredState';

type DragStartParams = {
  // width: number;
  x: number;
  y: number;
};

type ResizerParams = PropsWithChildren<{
  maxWidth?: number;
}>;

let path = [...Array(4)].map((_, i) => `M${i*4},12.5L12.5,${i*4}`).join(" ");

export function Resizer(params: ResizerParams) {
  const ref = useRef<HTMLDivElement>();
  const size = 25;
  // const minWidth = useMemo(() => (1 / 6) * 1232, []);
  const [maxWidth, setMaxWidth] = useStoredState<number>("board-width", 600);
  const [dragStart, setDragStart] = useState<DragStartParams | null>(null);

  function startDrag(e) {
    setDragStart({
      x: e.clientX,
      y: e.clientY,
      // width: maxWidth,
    });
  }

  useEffect(() => {
    if (!dragStart) return;
    const resizeListener = (e) => {
      let rect = ref.current.getBoundingClientRect();
      let x = rect.x + rect.width * 0.5;
      let y = rect.y;
      let dx = Math.abs(x - e.clientX);
      let dy = Math.abs(y - e.clientY);

      // let dx = e.clientX - dragStart.x;
      // let dy = e.clientY - dragStart.y;
      setMaxWidth(Math.max(dx*2,dy));
    };
    const resizeEndListener = () => setDragStart(null);
    window.addEventListener('pointerup', resizeEndListener);
    window.addEventListener('mousemove', resizeListener);
    return () => {
      if (!dragStart) return;
      window.removeEventListener('pointerup', resizeEndListener);
      window.removeEventListener('mousemove', resizeListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragStart]);

  const knobStyle = {
    transform: `translate(50%,50%)`,
    borderRadius: '50%',
    cursor: 'nwse-resize',
    gridColumn: 2,
    gridRow: 2,
    zIndex: 1,
    // background: dragStart ? '#aaa7' : '#0000',
  };

  const style: CSSProperties = {
    maxWidth,
    fontSize: 0,
    display: 'grid',
    gridTemplateColumns: `calc(100% - ${size}px) ${size}px`,
    gridTemplateRows: `calc(100% - ${size}px) ${size}px`,
    transition: 'margin 0.3s ease-in',
    margin: `0 auto`,
  };

  let boardWrapperStyle = {
    gridColumn: '1/3',
    gridRow: '1/3',
  };


  return (
    <div style={style} ref={ref}>
      <div style={boardWrapperStyle}>
        {params.children}
      </div>
      <div onMouseDown={startDrag} style={knobStyle} className="knob" >
        <svg width={25} height={25}>
          <path d={path} strokeWidth={1.5} stroke="#847c68"/>
        </svg>
      </div>
    </div>
  );
}
