import vk from '../../svg/social/vk-icon-colored.svg';
import google from '../../svg/social/google-icon-colored.svg';
import  yandex from '../../svg/social/yandex-icon-colored.svg';
//import twitter from '../../svg/social/twitter-icon-colored.svg';
//import ok from '../../svg/social/ok-icon-colored.svg';
import mailru from '../../svg/social/mailru-icon-colored.svg';
//import facebook from '../../svg/social/facebook-icon-colored.svg';
import telegram from '../../svg/social/telega-icon-colored.svg';

export const SocialAuthIcons = {
  vk, google, yandex, mailru, telegram
} 