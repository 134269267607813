import React, { CSSProperties, ReactNode } from 'react';
import styled from "styled-components";
import { SvgImage } from '../Icon/SvgImage';
import { useThemeColors } from '../../hooks/useThemeColors';
import { RawSvgString } from '*.svg';

const StyledMenuItem = styled.div<{bg?:boolean;active?:boolean;height?:number;disabled?:boolean}>`
  min-width: 100px;
  max-width: 250px;
  height: ${p => p.height || 48}px;
  cursor: pointer;
  font-family: Roboto,serif;
  padding: 0 10px 0 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: ${p => p.theme.currentTheme.iconMain};
  background: ${p => p.bg ? "#8883":null};
  &:hover {
    background:${p => p.theme.currentTheme.hover};
  }
  & * {
    vertical-align: middle
  }
  opacity: ${p => p.disabled?0.5:1};
  pointer-events: ${p => p.disabled?"none":"unset"};
`;

const StyledText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

type PopupMenuItemParams = {
  icon?: RawSvgString;
  text: string | ReactNode;
  onClick?: () => void;
  style?: CSSProperties;
  active?: boolean;
  filled?: boolean;
  height?: number;
  disabled?:boolean;
  bg?: boolean;
};

export function PopupMenuItem(props: PopupMenuItemParams) {
  const { accent } = useThemeColors();
  return <StyledMenuItem {...props} >
    <div style={{paddingRight: props.icon ? 5: 10}}>
      {typeof props.icon === "string" ? <SvgImage
        filled={props.active}
        icon={props.icon as any}
      /> : props.icon}
    </div>
    <StyledText style={{color: props.active ? accent: null}}>
      {props.text}
    </StyledText>
  </StyledMenuItem>
}