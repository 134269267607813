import React, { FC } from 'react';

interface IProps {
  fill?: string;
}

export const EditorHighlight: FC<IProps> = ({ fill }) => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 4.98361e-07C0.895429 5.37681e-07 -1.44385e-06 0.895433 0 2L1.83003e-05 16C1.97441e-05 17.1046 0.895447 18 2.00002 18H16C17.1046 18 18 17.1046 18 16V2C18 0.89543 17.1046 -3.93196e-08 16 0L2 4.98361e-07ZM4.89998 14H5.69998C5.89998 14 6.09998 13.9 6.09998 13.6L6.79998 11.4C6.89998 11.1 7.09998 11 7.29998 11H10.7C10.9 11 11.2 11.1 11.2 11.4L11.9 13.6C11.9185 13.6993 11.9666 13.7906 12.038 13.862C12.1094 13.9334 12.2007 13.9815 12.3 14H13.1C13.175 14.0015 13.2493 13.9861 13.3176 13.955C13.3858 13.9239 13.4462 13.8778 13.4942 13.8202C13.5422 13.7626 13.5767 13.6949 13.595 13.6221C13.6133 13.5494 13.615 13.4735 13.6 13.4L10.9 4.4C10.8 4.1 10.6 4 10.4 4H7.59998C7.39998 4 7.19998 4.1 7.09998 4.4L4.39998 13.4C4.38497 13.4735 4.38668 13.5494 4.40498 13.6221C4.42329 13.6949 4.45773 13.7626 4.50575 13.8202C4.55377 13.8778 4.61413 13.9239 4.68238 13.955C4.75062 13.9861 4.82499 14.0015 4.89998 14ZM7.69998 8.4L8.29998 6.4C8.39998 6.1 8.59998 6 8.79998 6H9.19998C9.39998 6 9.69998 6.1 9.69998 6.4L10.3 8.4C10.315 8.47349 10.3133 8.54941 10.295 8.62215C10.2767 8.69489 10.2422 8.76257 10.1942 8.82019C10.1462 8.87781 10.0858 8.92389 10.0176 8.95502C9.94934 8.98615 9.87497 9.00152 9.79998 9H8.19998C8.12499 9.00152 8.05062 8.98615 7.98238 8.95502C7.91413 8.92389 7.85377 8.87781 7.80575 8.82019C7.75773 8.76257 7.72329 8.69489 7.70498 8.62215C7.68668 8.54941 7.68497 8.47349 7.69998 8.4Z'
        fill={fill || '#939393'}
      />
    </svg>
  );
};
