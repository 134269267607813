import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Theme, useThemeStore } from '../store/themeStore';
import { PagePlaceholder } from '../components/PagePlaceholder/PagePlaceholder';

const StyledBg = styled.svg`

  pointer-events: none;
  z-index: -11;
  position: fixed;


  & circle {
    transition: .5s transform;
    transform: scale(0, 0);
  }

  & circle.visible {
    transform: scale(1, 1);
  }
`;

export function Bg() {
  const circleWhite = useRef();
  const { theme, clickData } = useThemeStore();
  const [state, setState] = useState(newState);

  function newState() {
    let w = window.innerWidth;
    let h = window.innerHeight;
    let r = Math.hypot(w, h);
    return { w, h, r };
  }

  useEffect(() => {
    function resize() {
      setState(newState);
    }

    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [clickData]);

  const translate = clickData && (Date.now() - clickData.time < 1000) ?
    [clickData.x, clickData.y] : [state.w, state.h];

  return <div style={{height: 0, overflow: 'visible', width: 0, position: "fixed", zIndex:-100}}>
    <StyledBg overflow={'visible'}>
      <rect fill='#2C2C2C' width={99999} height={99999} />
      <g transform={`translate(${translate})`}>
        <circle r={state.r} fill={'white'} ref={circleWhite} className={theme === Theme.light ? 'visible' : ''} />
      </g>
    </StyledBg>
    <PagePlaceholder />
  </div>;
}