import React, { useState } from 'react';
import { ButtonWrapper, InputWrapper, StyledButton } from './RegisterSteps';
import styled, { useTheme } from 'styled-components';
import Input from '../../components/low-level-components/Input';
import Checkbox from '../../components/low-level-components/Checkbox';
import { Link } from '../../components/low-level-components/Link';
import PasswordInput from '../../components/low-level-components/PasswordInput';
import { Grid, InputAdornment } from '@material-ui/core';
import { StyledTitle } from './EmailStep';
import { useAuthStore } from '../../store/auth/AuthStore';
import { ServerApi } from '../../transport/ServerApi';
import { useLocalization } from '../../l10n/UseLocalization';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import { useUiStore } from '../../store/uiStore';
import { TextLink } from '../../components/low-level-components/TextLink';
import { loggedUserFromGrpcUser } from '../../logic/loggedUserFromGrpcUser';

const AgreementWrapper = styled.div`
  padding: 0;
  margin: 14px 0 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Agreement = styled.span`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.iconMain};

  a:hover {
    text-decoration: underline;
  }
`;

type CompleteRegisterPhaseProps = {
  email: string;
};

export const CompleteStep = (props: CompleteRegisterPhaseProps) => {
  const lang = useLocalization();
  const [checked, setChecked] = useState(false);
  const [password1, setPassword1] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState('');
  const { setUser } = useAuthStore();
  const { lang: siteLang } = useUiStore();
  const { auth, lesson } = useLocalization();
  const styledTheme = useTheme();

  async function completeRegistration() {
    const result = await ServerApi.accessService.completeRegistration(props.email, password1, code, siteLang);

    if (typeof result === 'string') {
      setError(result);
      return;
    }

    const user = loggedUserFromGrpcUser(result);

    setUser(user);
  }

  return (
    <>
      <StyledTitle>{lang.auth.sign_up}</StyledTitle>
      <InputWrapper>
        <div
          style={{
            visibility: error.length ? 'visible' : 'hidden',
            alignSelf: 'flex-start',
            marginLeft: '1rem',
            color: styledTheme.currentTheme['error1'],
          }}
        >
          {error || '-'}
        </div>
        <Input label={lang.auth.email} hideClear fullWidth value={props.email} />
      </InputWrapper>
      <InputWrapper>
        <PasswordInput
          label={lang.auth.password}
          fullWidth
          value={password1}
          onChange={(e) => setPassword1(e.target.value)}
          error={!password1}
        />
      </InputWrapper>
      <InputWrapper>
        <PasswordInput
          label={lang.auth.repeat_password}
          fullWidth
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
          error={!password2}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          label={lang.auth.code}
          fullWidth
          value={code}
          onChange={(e) => setCode(e.target.value)}
          error={!code}
          endAdornment={
            code && (
              <InputAdornment position='start' onClick={() => setCode('')}>
                <SvgImage style={{ marginRight: '.5rem' }} icon={KnownIcons.cross} />
              </InputAdornment>
            )
          }
        />
      </InputWrapper>
      <AgreementWrapper>
        <Checkbox onChange={(e) => setChecked(e.target.checked)} checked={checked} />
        <Agreement>
          {lang.auth.agree_with}<Link to='/terms-of-service'>{lang.auth.terms}</Link>
        </Agreement>
      </AgreementWrapper>
      <ButtonWrapper>
        <StyledButton fullWidth onClick={completeRegistration} disabled={!password1 || !password2 || !code || !checked}>
          {lang.auth.do_sign_up}
        </StyledButton>
      </ButtonWrapper>
      <Grid container justifyContent='center' direction='column'>
        <TextLink linkText={lang.auth.login} to='/auth/login' />
      </Grid>
    </>
  );
};
