import React from 'react';
import { SvgImage } from '../Icon/SvgImage';
import styled from 'styled-components';
import { useThemeColors } from '../../hooks/useThemeColors';
import { RawSvgString } from '*.svg';

const StyledButton = styled.div<{ color; disabled }>`
  height: 32px;
  margin: 5px 5px 5px 10px;
  padding: 0 5px 0 0;
  display: flex;
  align-items: center;
  pointer-events: ${p => p.disabled ? "none" : "unset"};
  opacity: ${p => p.disabled ? 0.5 : 1};
  cursor: pointer;
  font: ${p => p.theme.fonts.small};
  color: ${p => p.color || p.theme.currentTheme.iconMain};
  &:hover {
    background-color: ${p => p.theme.currentTheme.hover};
    border-radius: 6px;
  }
  
  // noinspection CssInvalidPropertyValue
  word-wrap: nowrap;
  
`;

type ToolbarButtonParams = {
  icon?: RawSvgString;
  text?: string;
  onClick?: () => void;
  disabled?: boolean;
  active?: boolean;
};

export function ToolbarButton(params: ToolbarButtonParams) {
  const { icon, text, onClick, disabled, active } = params;
  const { accent } = useThemeColors();
  return <StyledButton onClick={onClick} disabled={disabled} color={active ? accent : null}>
    <SvgImage icon={icon} style={{width: 32, height: 32, marginRight: 5}} filled={active}/>
    <div>{text}</div>
  </StyledButton>;
}