import React from 'react';
import { FooterIcon } from './FooterIcon';

export function Social_Vk() {
  return <FooterIcon color="#07f">
    <path
      d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z'
      className="mainColor"
    />
    <path
      d='M12.77 17.29C7.30005 17.29 4.18005 13.54 4.05005 7.3H6.79005C6.88005 11.88 8.90005 13.82 10.5 14.22V7.3H13.08V11.25C14.66 11.08 16.32 9.28 16.88 7.3H19.46C19.249 8.32689 18.8284 9.29918 18.2244 10.1561C17.6205 11.013 16.8462 11.736 15.95 12.28C16.9504 12.7771 17.8339 13.4806 18.5424 14.3442C19.2508 15.2078 19.7681 16.2118 20.06 17.29H17.22C16.958 16.3535 16.4253 15.5152 15.6889 14.8801C14.9524 14.2451 14.0449 13.8415 13.08 13.72V17.29H12.77Z'
      className="bgColor"
    />
  </FooterIcon>;

}