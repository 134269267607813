import React from 'react';
import { ChessclubUser } from '../../../../../../../interfaces/user';
import styled from 'styled-components';


const StyledName = styled.div`
  width: 100%;
  font: ${({ theme }) => theme.fonts.small};
  color: ${({ theme }) => theme.currentTheme.textMain};
  box-sizing: border-box;
  
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  line-height: 32px;
  padding-left: 10px;
`;

export function CameraCardUserName({ user }: {user: ChessclubUser;}) {
  return <StyledName className="hidden">
     {user.displayName || user.email}
  </StyledName>;
}