import { LessonToolbarButton } from '../components';
import { PopupMenuItem } from '../../../../components/PopupMenu/PopupMenuItem';
import React from 'react';
import { useLocalization } from '../../../../l10n/UseLocalization';
import { useLessonUiStore } from '../../../../store/lesson-ui/LessonUiStoreImpl';
import { MovesDisplayMode } from '../../../../store/lesson-ui/LessonUiStoreApi';
import { PopupMenu } from '../../../../components/PopupMenu/PopupMenu';
import { PopupMenuSeparator } from '../../../../components/PopupMenu/PopupMenuSeparator';
import { SvgImage } from '../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../components/Icon/KnownIcons';
import { TextEditorIcons } from '../../../../components/TextEditor/TextEditorIcons';

export function MovesDropdown() {
  return <PopupMenu popupContent={MovesDropdownContent}>
    <LessonToolbarButton>
      <SvgImage icon={KnownIcons.triple_dot_horizontal} style={{ transform: 'rotate(90deg)' }} />
    </LessonToolbarButton>
  </PopupMenu>;
}

function MovesDropdownContent() {
  const { lesson } = useLocalization();
  const { movesMode, setMovesDisplayMode } = useLessonUiStore();
  return <>
    <PopupMenuItem
      text={lesson.clear}
      icon={KnownIcons.trash}
    />
    <PopupMenuSeparator/>
    <PopupMenuItem
      text={lesson.table}
      icon={TextEditorIcons.table}
      active={movesMode === MovesDisplayMode.TABLE}
      onClick={() => setMovesDisplayMode(MovesDisplayMode.TABLE)}
    />
    <PopupMenuItem
      text={lesson.chain}
      icon={TextEditorIcons.chain}
      active={movesMode === MovesDisplayMode.CHAIN}
      onClick={() => setMovesDisplayMode(MovesDisplayMode.CHAIN)}
    />
    <PopupMenuItem
      text={"Текст"}
      icon={KnownIcons.two_and_half_lines}
      active={movesMode === MovesDisplayMode.TEXT}
      onClick={() => setMovesDisplayMode(MovesDisplayMode.TEXT)}
    />
  </>;
}