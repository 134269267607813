import { pieceKeyToPiece } from '@chessclub/web-game-server';
import { Piece } from '@chessclub/ext_chess.ts';
import { FigureType } from './FigureType';

export const allFiguresWhite = [
  {
    piece: 'wp',
    icon: FigureType.pawn,
    pgnName: '',
  },
  {
    piece: 'wn',
    icon: FigureType.knight,
    pgnName: 'N',
  },
  {
    piece: 'wb',
    icon: FigureType.bishop,
    pgnName: 'B',
  },
  {
    piece: 'wr',
    icon: FigureType.rook,
    pgnName: 'R',
  },
  {
    piece: 'wq',
    icon: FigureType.queen,
    pgnName: 'Q',
  },
  {
    piece: 'wk',
    icon: FigureType.king,
    pgnName: 'K',
  },
].map(({piece, icon, pgnName  }) => {
  return {
    piece: pieceKeyToPiece(piece),
    icon,
    pgnName
  } as {icon:FigureType, piece:Piece, pgnName:string }
});

