import React, { CSSProperties, PropsWithChildren, useRef } from 'react';
import styled, { useTheme } from 'styled-components';

const Svg = styled.svg`
  z-index: 1;

  & .canHide {
    transform: scale(1, 1);
    transition: 0.3s !important;
  }

  & .canHide.hide {
    transform: scale(0, 1) !important;
  }
`;

export function DiagonalLineIconModifier(props: PropsWithChildren<{
  color: string;
  className?: string;
  isOn?: boolean;
  onClick?: (e) => void;
  size?: number;
  ratio?: number;
  style?: CSSProperties;
}>) {

  const mask_id = useRef('mask_' + Math.random().toString().substring(2));
  const style = { transform: 'translate(6px, 26px) rotate(-45deg)' };
  const className = props.isOn === false ? 'canHide hide' : 'canHide';
  const d = 'm0,0h28';
  const h = props.size || 32;
  const w = h*(props.ratio||1);
  return <Svg viewBox={`0 0 ${w} ${h}`} width={w} height={h}
              className={props.className} style={{ ...props.style, cursor: 'pointer' }}
              onClick={(e) => {
                props.onClick && e.stopPropagation();
                props.onClick && props.onClick(e);
              }}
  >
    <defs>
      <mask id={mask_id.current}>
        <rect width={w} height={h} fill='white' />
        <g style={style}>
          <g className={className}>
            <path
              d={d}
              stroke='black' strokeWidth={5} strokeLinecap={'round'} />
          </g>
        </g>
      </mask>
    </defs>

    <foreignObject width={w} height={h} mask={`url(#${mask_id.current})`}>
      {props.children}
    </foreignObject>

    <g style={style}>
      <g className={className}>
        <path d={d}
              stroke={props.color} strokeWidth={1.5} strokeLinecap={'round'} />
      </g>
    </g>
  </Svg>;
}