import React from 'react';

import { Container } from '@material-ui/core';
import { Theme, useThemeStore } from '../../store/themeStore';
import { PageFooterStyledComponents } from './PageFooterStyledComponents';
import { Links } from './Links';
import { Social_Youtube } from './icons/Social_Youtube';
import { Social_Telegram } from './icons/Social_Telegram';
import { Social_Zen } from './icons/Social_Zen';
import { Social_Vk } from './icons/Social_Vk';
import { LangSwitch } from './LangSwitch';
import { ThemeSwitch } from './ThemeSwitch';

const {
  StyledFooter,
  StyledHr,
  StyledLinks,
  StyledRow,
} = PageFooterStyledComponents;

interface IFooter {
  style?: any;
  footerRef?: React.MutableRefObject<HTMLElement>;
}

export function PageFooter(props: IFooter) {
  const { style, footerRef } = props;
  const { theme } = useThemeStore();

  const containerProps = {
    fixed: true,
    style: { padding: '0px', maxWidth: '100%', alignSelf: 'end' } as any,
  };

  return <StyledFooter ref={footerRef} style={{ marginTop: '2rem', ...style }}>
    <Container {...containerProps}>
      {/*<StyledHr color={theme === Theme.dark ? '#555555' : '#D2D2D2'} />*/}
      <StyledRow>
        <StyledLinks>
          ChessClub © {new Date().getFullYear()}
        </StyledLinks>
        &nbsp;
        &nbsp;
        &nbsp;
        <StyledLinks>
          <Social_Youtube />
          <Social_Telegram />
          <Social_Zen />
          <Social_Vk />
        </StyledLinks>

        <StyledLinks><Links /></StyledLinks>
        <StyledLinks style={{gap:15, display:"flex", justifyContent: "right"}}>

          <LangSwitch />
          <ThemeSwitch />
        </StyledLinks>
      </StyledRow>
    </Container>
  </StyledFooter>;
}


