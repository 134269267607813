import { RoomId, UserId } from '@chessclub/grpc_wrapper';
import {ChannelEvent, ChannelKey, initChannel} from '@chessclub/realtime_infrastructure';

export type ChatMessageId = string;

export type ChatMessage = {
  id?: ChatMessageId;
  text: string;
}

export type FullChatMessage = ChatMessage & {
  roomId: RoomId;
  userId: UserId;
  epoch: number,
  responseId?: ChatMessageId;
}

export let ChatChannel = {
  CREATE_EVENT: new ChannelEvent<FullChatMessage>(),
  DELETE_EVENT: new ChannelEvent<ChatMessageId>(),
  EDIT_EVENT: new ChannelEvent<ChatMessage>()
};

ChatChannel = initChannel(ChatChannel)

export function makeChatChannelKey(roomId: RoomId): ChannelKey{
  return {key: 'CHAT_' + roomId};
}