// React
import React, { CSSProperties, FunctionComponent } from 'react';

import styled from 'styled-components';
import {useLessonStore} from "../store/lesson/LessonStore";
import {useUiStore} from "../store/uiStore";
import { useLessonUiStore } from '../store/lesson-ui/LessonUiStoreImpl';
import { LessonViewMode } from '../store/lesson-ui/LessonUiStoreApi';

interface IProps {
  style?: CSSProperties;
}

interface IStyledProps {
  size: number;
  view: LessonViewMode;
}

const StyledContainer = styled.div<IStyledProps>`
  grid-area: C;
  margin-top: 12px;
  z-index: 1;
  align-content: start;
  align-items: baseline;
  justify-content: ${({ size }) => (size === 1 ? 'center' : 'start')};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 1rem;
  padding: 0 1px;

  ${({ size }) => (size === 1 ? 'flex-direction: column; align-items: center;' : null)}

  & > * {
    ${({ size }) => (size === 1 ? 'width: 100%' : `width: calc((100% - ${(size - 1) * 20}px) / ${size})`)};
  }

  ${({ view }) =>
    view === LessonViewMode.CHESSBOARDS &&
    `  @media (max-width: 1029px) {
      & > * { width: 100%; }
     flex-direction: column;
     justify-content: flex-start;
  }`}
`;

export const AllGridDesktop: FunctionComponent<IProps> = ({ children, style }) => {
  // Redux state
  const { view } = useLessonUiStore();
  const { sizes } = useUiStore();

  // View
  return (
    <StyledContainer view={view} style={style} size={sizes[view]}>
      {children}
    </StyledContainer>
  );
};
