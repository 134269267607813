import React from 'react';
import { useLessonStore } from '../../../store/lesson/LessonStore';
import { useModalStore } from '../../../store/modalStore';
import { useLocalization } from '../../../l10n/UseLocalization';
import { ToolbarButton } from '../../../components/ContentContainer/ToolbarButton';
import { KnownIcons } from '../../../components/Icon/KnownIcons';
import { PopupMenu } from '../../../components/PopupMenu/PopupMenu';
import { LessonViewPopupMenu } from './LessonViewPopupMenu';
import { useLessonUiStore } from '../../../store/lesson-ui/LessonUiStoreImpl';
import { LessonViewMode } from '../../../store/lesson-ui/LessonUiStoreApi';
import { OnlineButton } from '../../../components/OnlineButton/OnlineButton';

const viewIcons = {
  [LessonViewMode.STANDARD]: KnownIcons.chessboard,
  [LessonViewMode.CAMERAS]: KnownIcons.four_cams,
  [LessonViewMode.CHESSBOARDS]: KnownIcons.four_chessboards,
}


export function LessonOnlineToolbarVariant() {

  const { lesson: lang } = useLocalization();
  const { openModal } = useModalStore();
  const { view } = useLessonUiStore();

  const {
    // demonstrationState,
    // demonstrateButtonClicked,
    roomId,
    monitoringStudentId,
    isStudentLayout
  } = useLessonStore();


  if (isStudentLayout)
    return null

  return <>
      {/*<PopupMenu popupContent={LessonViewPopupMenu} disabled={!!monitoringStudentId}>*/}
      {/*  <ToolbarButton*/}
      {/*    icon={viewIcons[view]}*/}
      {/*    text={lang.view}*/}
      {/*  />*/}
      {/*</PopupMenu>*/}

      <OnlineButton roomId={roomId}>
        <ToolbarButton
          active
          icon={KnownIcons.power_button}
          text={lang.online}
          // onClick={async () => await turnRoomOff(roomId)}
        />
      </OnlineButton>

  </>;
}