import React from 'react';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import styled from 'styled-components';


const TextBlock = styled.div`
  font-family: Roboto;
  font-size: 18px;
  padding: 20px;
  width: 1000px;
  margin: 0 auto;
  color: ${p => p.theme.currentTheme.iconMain};
`

export function TermsOfService() {
  return <PageTemplate>
    <TextBlock>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse consectetur ipsum vel lectus dapibus, in pulvinar purus scelerisque. Proin in sodales lectus. Phasellus dapibus nunc ultricies libero dictum accumsan. Vestibulum porta ornare ex, a molestie tortor placerat quis. Nam posuere dolor eu justo viverra laoreet dignissim ac risus. Maecenas nec arcu eu mi eleifend lacinia ac sed ante. Pellentesque vitae iaculis turpis. Nunc vestibulum quam ut magna commodo molestie. Aliquam neque elit, auctor ut libero id, congue iaculis elit. Aliquam nulla massa, consequat eu metus id, auctor posuere neque. Etiam ac turpis blandit, tempus elit sed, commodo arcu. Integer porttitor ligula eget felis euismod bibendum. Etiam vehicula lectus ligula, vel hendrerit diam iaculis at. Ut cursus imperdiet volutpat.
    </TextBlock>
    <TextBlock>
      Aliquam varius egestas ante, nec feugiat elit pharetra et. Ut nec enim tellus. Etiam condimentum tortor nec sem commodo iaculis. Cras eget nunc varius nisl rutrum dapibus. Morbi ac erat vitae nulla iaculis fermentum. Ut eget pellentesque ante. Donec rhoncus at odio at viverra. Sed a feugiat nulla. Vivamus imperdiet velit ac sapien condimentum mollis. Etiam vitae sem mi. Maecenas semper sapien ac metus vehicula tincidunt eu vitae sem. Vestibulum commodo, leo vitae ullamcorper pulvinar, elit ligula pharetra ligula, in commodo enim nulla at velit. Vestibulum pellentesque tincidunt libero et luctus.
    </TextBlock>
    <TextBlock>
      Duis sed orci malesuada, sagittis eros vel, semper risus. Sed aliquet leo ac sem cursus commodo. Pellentesque ut euismod sem. Cras facilisis ultrices diam, at condimentum magna rutrum eget. Cras sit amet sapien quis quam ullamcorper pulvinar a eget nulla. Sed lorem dui, dapibus sed ex vel, tincidunt consectetur felis. Donec eu vestibulum eros. Quisque quis ullamcorper lectus. Donec vestibulum rhoncus turpis ut cursus. Vestibulum tristique dictum erat, eget pulvinar ligula dictum at. Sed fringilla placerat nisl in vehicula. Donec sit amet semper nisl. Fusce molestie ornare ex, id rhoncus ante semper accumsan. Mauris bibendum, elit non gravida dictum, magna eros aliquet enim, sit amet semper libero dui et neque. Nullam mattis sem accumsan aliquet congue. Nunc id interdum lacus.
    </TextBlock>
    <TextBlock>
      Morbi nunc risus, elementum eu porta eu, gravida non nunc. Nunc eu metus ut libero faucibus commodo. Phasellus efficitur, sapien vel sollicitudin auctor, odio turpis finibus tortor, vel vestibulum nulla dui ac orci. Integer varius volutpat mattis. Nulla at dui elit. Nam sit amet augue rutrum, imperdiet quam ut, congue odio. Vestibulum sagittis purus eu arcu sagittis elementum. Vivamus iaculis, odio sit amet convallis elementum, eros tortor lobortis est, nec aliquet leo nibh et arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum sit amet ligula et lectus euismod eleifend eu aliquet libero. Suspendisse scelerisque urna at velit efficitur euismod. Nulla sed velit neque. Vivamus accumsan aliquet rutrum. Quisque in nibh leo. Donec ultricies, felis sed viverra luctus, neque felis volutpat enim, vitae rutrum eros sem in tellus.
    </TextBlock>
    <TextBlock>
      Donec a dictum risus, eu lobortis leo. Maecenas commodo accumsan luctus. Pellentesque diam nulla, pulvinar non turpis at, vulputate congue dui. Nulla eu arcu at massa eleifend semper quis sed enim. Sed quis auctor justo. Curabitur iaculis sapien a tellus tempor, et gravida enim tincidunt. In augue magna, aliquam at volutpat et, ultricies in magna.
    </TextBlock>
    <TextBlock>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse consectetur ipsum vel lectus dapibus, in pulvinar purus scelerisque. Proin in sodales lectus. Phasellus dapibus nunc ultricies libero dictum accumsan. Vestibulum porta ornare ex, a molestie tortor placerat quis. Nam posuere dolor eu justo viverra laoreet dignissim ac risus. Maecenas nec arcu eu mi eleifend lacinia ac sed ante. Pellentesque vitae iaculis turpis. Nunc vestibulum quam ut magna commodo molestie. Aliquam neque elit, auctor ut libero id, congue iaculis elit. Aliquam nulla massa, consequat eu metus id, auctor posuere neque. Etiam ac turpis blandit, tempus elit sed, commodo arcu. Integer porttitor ligula eget felis euismod bibendum. Etiam vehicula lectus ligula, vel hendrerit diam iaculis at. Ut cursus imperdiet volutpat.
    </TextBlock>
    <TextBlock>
      Aliquam varius egestas ante, nec feugiat elit pharetra et. Ut nec enim tellus. Etiam condimentum tortor nec sem commodo iaculis. Cras eget nunc varius nisl rutrum dapibus. Morbi ac erat vitae nulla iaculis fermentum. Ut eget pellentesque ante. Donec rhoncus at odio at viverra. Sed a feugiat nulla. Vivamus imperdiet velit ac sapien condimentum mollis. Etiam vitae sem mi. Maecenas semper sapien ac metus vehicula tincidunt eu vitae sem. Vestibulum commodo, leo vitae ullamcorper pulvinar, elit ligula pharetra ligula, in commodo enim nulla at velit. Vestibulum pellentesque tincidunt libero et luctus.
    </TextBlock>
    <TextBlock>
      Duis sed orci malesuada, sagittis eros vel, semper risus. Sed aliquet leo ac sem cursus commodo. Pellentesque ut euismod sem. Cras facilisis ultrices diam, at condimentum magna rutrum eget. Cras sit amet sapien quis quam ullamcorper pulvinar a eget nulla. Sed lorem dui, dapibus sed ex vel, tincidunt consectetur felis. Donec eu vestibulum eros. Quisque quis ullamcorper lectus. Donec vestibulum rhoncus turpis ut cursus. Vestibulum tristique dictum erat, eget pulvinar ligula dictum at. Sed fringilla placerat nisl in vehicula. Donec sit amet semper nisl. Fusce molestie ornare ex, id rhoncus ante semper accumsan. Mauris bibendum, elit non gravida dictum, magna eros aliquet enim, sit amet semper libero dui et neque. Nullam mattis sem accumsan aliquet congue. Nunc id interdum lacus.
    </TextBlock>
    <TextBlock>
      Morbi nunc risus, elementum eu porta eu, gravida non nunc. Nunc eu metus ut libero faucibus commodo. Phasellus efficitur, sapien vel sollicitudin auctor, odio turpis finibus tortor, vel vestibulum nulla dui ac orci. Integer varius volutpat mattis. Nulla at dui elit. Nam sit amet augue rutrum, imperdiet quam ut, congue odio. Vestibulum sagittis purus eu arcu sagittis elementum. Vivamus iaculis, odio sit amet convallis elementum, eros tortor lobortis est, nec aliquet leo nibh et arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum sit amet ligula et lectus euismod eleifend eu aliquet libero. Suspendisse scelerisque urna at velit efficitur euismod. Nulla sed velit neque. Vivamus accumsan aliquet rutrum. Quisque in nibh leo. Donec ultricies, felis sed viverra luctus, neque felis volutpat enim, vitae rutrum eros sem in tellus.
    </TextBlock>
    <TextBlock>
      Donec a dictum risus, eu lobortis leo. Maecenas commodo accumsan luctus. Pellentesque diam nulla, pulvinar non turpis at, vulputate congue dui. Nulla eu arcu at massa eleifend semper quis sed enim. Sed quis auctor justo. Curabitur iaculis sapien a tellus tempor, et gravida enim tincidunt. In augue magna, aliquam at volutpat et, ultricies in magna.
    </TextBlock>
  </PageTemplate>;
}