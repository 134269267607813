import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Grid, InputAdornment } from '@material-ui/core';
import { Logo, LogoSize } from '../../components/Icon/Logo';
import Input from '../../components/low-level-components/Input';
import { CaptchaStep } from '../../components/Captcha/CapthcaStep';
import {TextLink} from '../../components/low-level-components/TextLink';
import PasswordInput from '../../components/low-level-components/PasswordInput';
import { StyledButton } from '../RegisterPage/RegisterSteps';
import { PageFooter } from '../../components';
import { useAuthStore } from '../../store/auth/AuthStore';
import { useParams } from 'react-router-dom';
import { useStoredState } from '../../hooks/useStoredState';
import { ServerApi } from '../../transport/ServerApi';
import { loggedUserFromGrpcUser } from '../../logic/loggedUserFromGrpcUser';
import { useLocalization } from '../../l10n/UseLocalization';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import { useUiStore } from '../../store/uiStore';

export interface IRecoverForm {
  email: string;
  password: string;
  passwordRepeat: string;
  code: string;
}

export interface IRecoverFormErrors {
  email?: boolean;
  password?: boolean;
  passwordRepeat?: boolean;
  code?: boolean;
}


const StyledLogoContainer = styled.div<{ contentFooterFreespace: number }>`
  margin-top: 10vh;

  ${({ contentFooterFreespace }) => {
    return contentFooterFreespace <= 114 && `margin-top: 0`;
  }}
`;

const StyledMessage = styled.p`
  font: ${({ theme }) => theme.fonts.normal};
  color: #bebebe;
  padding: 0px;
  margin: 40px 0px 28px 0px;
  text-align: center;
`;

const InputWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium}px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const ButtonWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.large}px;
  width: 100%;
`;

export function RecoverPage() {
  const lang = useLocalization();
  const { user, setUser } = useAuthStore();
  const {lang: siteLang} = useUiStore();
  const { email } = useParams<{ email: string }>();

  const initialFormData: IRecoverForm = { email, password: '', passwordRepeat: '', code: '' };
  const initialErrors: IRecoverFormErrors = { email: false, password: false };
  const [_, setEmail] = useStoredState<string | undefined>('register-email', '');

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);
  const [step, setStep] = useState<'initial' | 'enter-password' | 'captcha'>('initial');
  const [confirmError, setConfirmError] = useState('');
  const { auth } = useLocalization();
  let isButtonActionDisabled: boolean;

  // Component state
  // space beetweenthe main content and footer
  const [contentFooterFreespace, setContentFooterFreespace] = useState<number>();

  // Refs
  const containerRef = useRef<HTMLDivElement>();
  const footerRef = useRef<HTMLElement>();
  const mainContentRef = useRef<HTMLDivElement>();

  // Hooks
  const styledTheme = useTheme();

  // Others
  function calculateMainContentFooterFreespace() {
    const contentFooterSpace =
      parseInt(getComputedStyle(containerRef.current).height) -
      parseInt(getComputedStyle(mainContentRef.current).height) -
      parseInt(getComputedStyle(footerRef.current).height) +
      parseInt(getComputedStyle(footerRef.current).marginTop);

    contentFooterSpace !== contentFooterFreespace && setContentFooterFreespace(contentFooterSpace);
  }

  // Effects
  useEffect(() => {
    calculateMainContentFooterFreespace();
    window.addEventListener('resize', calculateMainContentFooterFreespace);
    return () => {
      window.removeEventListener('resize', calculateMainContentFooterFreespace);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (step) {
    case 'initial':
      isButtonActionDisabled = !formData.email;
      break;
    case 'enter-password':
      isButtonActionDisabled = !formData.password || !formData.passwordRepeat || !formData.code;
      break;
  }

  const handleClick = async () => {
    let errors: IRecoverFormErrors = {};

    switch (step) {
      case 'initial':
        if (!formData.email) errors.email = true;
        if (!Object.values(errors).length) {
          const result = await ServerApi.accessService.restorePassword(formData.email, siteLang);
          if( typeof result === 'string') {
            setConfirmError(result);
            setStep('initial');
          } else {
            setConfirmError('');
            setStep('captcha');
          }
        }
        break;

      default:
        if (!formData.password) errors.password = true;
        if (!formData.passwordRepeat) errors.passwordRepeat = true;
        if (formData.password !== formData.passwordRepeat) {
          errors.password = true;
          errors.passwordRepeat = true;
        }
        if (!formData.code) errors.code = true;
        if (!Object.values(errors).length) {
          const user = await ServerApi.accessService.completeRestorePassword(
              formData.email,
              formData.password,
              formData.code
          )

          if (typeof user === "string") {
            setConfirmError(user)
          } else {
            setUser(loggedUserFromGrpcUser(user));
          }

          setStep('enter-password');
        }
        break;
    }

    setErrors(errors);
  };

  return (
    <>
      <div
        ref={containerRef}
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: contentFooterFreespace <= 114 ? '1fr' : 'auto 1fr',
          height: '100%',
          maxWidth: '90%',
          margin: '0 auto',
        }}
      >
        <Grid ref={mainContentRef} container justifyContent="center">
          <Grid
            style={{
              maxWidth: '22rem',
              ...(contentFooterFreespace <= 114
                ? { display: 'flex', flexDirection: 'column', justifyContent: 'center' }
                : {}),
            }}
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
          >
            <Grid container justifyContent="center">
              <StyledLogoContainer contentFooterFreespace={contentFooterFreespace}>
                <Logo size={LogoSize.large}/>
              </StyledLogoContainer>
            </Grid>
            <Grid container justifyContent="center">
              {step === 'initial' && (
                <StyledMessage>{lang.auth.email_to_restore}</StyledMessage>
              )}
              {step === 'captcha' && (
                <CaptchaStep
                  calculateMainContentFooterFreespace={calculateMainContentFooterFreespace}
                  email={formData.email}
                  setPhase={() => setStep('enter-password')}
                  confirmButtonText={lang.auth.continue}
                  onSuccess={() => setStep('enter-password')}
                />
              )}
              {step === 'enter-password' && (
                <StyledMessage>
                  {lang.auth.consider_new_password + ' ' + formData.email}
                </StyledMessage>
              )}
            </Grid>
            <div
              style={{
                visibility: confirmError.length ? 'visible' : 'hidden',
                alignSelf: 'flex-start',
                marginLeft: '1rem',
                color: styledTheme.currentTheme['error1'],
              }}
            >
              {confirmError || '-'}
            </div>
            <Grid style={{ width: '100%' }} container justifyContent="center" direction="column">
              {step === 'initial' && (
                <InputWrapper>
                  <Input
                    label={lang.auth.email}
                    fullWidth
                    value={formData.email}
                    onChange={(e: any) => {
                      setFormData((prev) => ({ ...prev, email: e.target.value }));
                      setEmail(e.target.value);
                    }}
                    error={errors.email}
                    endAdornment={
                      formData.email && (
                        <InputAdornment
                          position="start"
                          onClick={() => setFormData((prev) => ({ ...prev, email: '' }))}
                        >
                          <SvgImage style={{ marginRight: '.5rem' }} icon={KnownIcons.cross} />
                        </InputAdornment>
                      )
                    }
                  />
                </InputWrapper>
              )}
              {step === 'enter-password' && (
                <>
                  <InputWrapper>
                    <PasswordInput
                      label={lang.auth.new_password}
                      fullWidth
                      value={formData.password}
                      onChange={(e: any) => setFormData((prev) => ({ ...prev, password: e.target.value }))}
                      error={errors.password}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <PasswordInput
                      label={lang.auth.repeat_new_password}
                      fullWidth
                      value={formData.passwordRepeat}
                      onChange={(e: any) => setFormData((prev) => ({ ...prev, passwordRepeat: e.target.value }))}
                      error={errors.passwordRepeat}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Input
                      label={lang.auth.code}
                      fullWidth
                      value={formData.code}
                      onChange={(e: any) => setFormData((prev) => ({ ...prev, code: e.target.value }))}
                      error={errors.code}
                    />
                  </InputWrapper>
                </>
              )}

              {step !== 'captcha' && (
                <ButtonWrapper>
                  <StyledButton disabled={isButtonActionDisabled} fullWidth onClick={handleClick}>
                    {step === 'initial' && lang.auth.continue}
                    {step === 'enter-password' && lang.auth.confirm}
                  </StyledButton>
                </ButtonWrapper>
              )}
            </Grid>
            <Grid container justifyContent="center" direction="column">
              <TextLink linkText={lang.auth.login} to={'/auth/login'} />
            </Grid>
          </Grid>
        </Grid>
        <PageFooter footerRef={footerRef} style={{ marginTop: '0px' }} />
      </div>
    </>
  );
}
