import { ToolbarButton } from '../components/ContentContainer/ToolbarButton';
import { KnownIcons } from '../components/Icon/KnownIcons';
import React from 'react';
import { useLocalization } from '../l10n/UseLocalization';

type ApplyCancelToolbarVariantParams = {
  apply: () => void;
  cancel: () => void;
};

export function ApplyCancelToolbarVariant(props: ApplyCancelToolbarVariantParams) {

  const lang = useLocalization();

  return <div style={{display: 'flex', flexDirection: 'row-reverse'}}>

    <ToolbarButton
      icon={KnownIcons.check_mark}
      text={lang.lesson.save}
      onClick={props.apply}
    />

    <ToolbarButton
      icon={KnownIcons.cross}
      text={lang.lesson.cancel}
      onClick={props.cancel}
    />

  </div>;
}