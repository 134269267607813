import React from 'react';
import { Grid } from '@material-ui/core';
import { Logo, LogoSize } from '../../components/Icon/Logo';
import { RegisterSteps } from './RegisterSteps';
import styled from 'styled-components';
import { PageFooter } from '../../components';

const StyledPage = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: 100%;
  max-width: 90%!important;
  margin: 0 auto;
`;

const StyledContainer = styled.div`
  grid-row: 1/3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
`;

export function RegisterPage() {
  return <StyledPage>
    <StyledContainer >
      <Logo size={LogoSize.large} />
      <Grid style={{ maxWidth: '21.875rem', margin: '0 auto 100px auto' }}
            container justifyContent='center' direction='column'>
        <RegisterSteps />
      </Grid>
    </StyledContainer>

    <PageFooter style={{ marginTop: '16px' }} />
  </StyledPage>;
}
