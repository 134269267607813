import { useAuthStore } from '../../store/auth/AuthStore';
import { useLocalization } from '../../l10n/UseLocalization';
import { Role } from '@chessclub/grpc_wrapper';
import { Link } from '../low-level-components/Link';
import { Box } from '@material-ui/core';
import { PopupMenu } from '../PopupMenu/PopupMenu';
import { UserProfilePopupMenu } from './UserProfilePopupMenu';
import { UserProfile } from './UserProfile/UserProfile';
import React from 'react';

export function UserProfileWidget() {

  const { user } = useAuthStore();
  const { auth } = useLocalization();

  if (!user || user.role === Role.GUEST) {
    return <>
      <div style={{ display: 'flex', gap: 10 }}>
        <Link to='/auth/register'>{auth.sign_up}</Link>
        <Link to='/auth/login'>{auth.login}</Link>
      </div>
    </>;
  }

  return <div>
    <Box display={'flex'} justifyContent={'end'}>
      <PopupMenu popupContent={UserProfilePopupMenu}>
        <UserProfile />
      </PopupMenu>
    </Box>
  </div>;
}