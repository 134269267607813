import Image from '@tiptap/extension-image';
import Placeholder from '@tiptap/extension-placeholder';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useState, useEffect } from 'react';
import { TipTapCustomImage } from '../components/TextEditor/ImageNode';
import Link from '@tiptap/extension-link';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';

export const useTextEditor = (
  placeholder: string,
  content: string,
  onUpdate: (str: string) => void
) => {

  const [focus, setFocus] = useState(false);
  // const { draggingFigure, setDraggingFigure  } = useUiStore();
  // const { currentMaterial, editingMaterial, edit } = useEditingMaterialStore();

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
  };


  const editor = useEditor({
    extensions: [
      TextStyle,
      Color,
      Underline,
      StarterKit.configure({ heading: { levels: [1, 2, 3, 4, 5] } }),
      Highlight.configure({ multicolor: true }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Placeholder.configure({
        placeholder: placeholder,
      }),
      Image.configure({
        inline: true,
      }),
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Link.configure({
        openOnClick: true,
      }),
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      TipTapCustomImage(handleUpload),
    ],
    content,
    onUpdate: e => {
      let html = e.editor.getHTML();
      html !== content && onUpdate(html);
    }
  }, []);

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(content)
    }
  }, [content, editor])



  const [img, setImg] = useState<File | null>(null);

  function readFileAsDataURL(file: File): Promise<string> {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();

      fr.onload = function () {
        resolve(fr.result as string);
      };

      fr.onerror = function () {
        reject(fr);
      };

      fr.readAsDataURL(file);
    });
  }

  async function handleUpload(file: File) {
    const result = await readFileAsDataURL(file);

    return result;
  }

  useEffect(() => {
    async function handleImageUpload() {
      if (img && editor) {
        const src = await handleUpload(img);
        editor.chain().focus()?.setImage({ src })?.run();
      }
    }
    handleImageUpload();
  }, [img]);

  const handlePointerUp = () => {
    // if (!draggingFigure) return;
    // editor.chain().focus().setImage({ src: draggingFigure }).run();
    // setDraggingFigure(null);
  };


  return { focus, handleFocus, handleBlur, handleUpload, editor, readFileAsDataURL, handlePointerUp };
};
