import styled from 'styled-components';
import { Button } from '../../components/low-level-components/Button';

const StyledLogoContainer = styled.div`
  margin-top: 10vh;
`;

const StyledMessage = styled.p`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};
  padding: 0px;
  margin: 40px 0px 28px 0px;
  text-align: center;
`;

const InputWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium}px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const StyledButton = styled(Button)`
  height: 48px;
  padding: 13px;
`;

export const EnterLessonPageStyledComponents = {
  StyledButton, InputWrapper, StyledMessage, StyledLogoContainer
}