import React, { PropsWithChildren } from 'react';
import { useLocalization } from '../../l10n/UseLocalization';
import styled from 'styled-components';
import { Link } from '../low-level-components/Link';

export const StyledLinkWrapper = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  height: 32px;
  color: ${({ theme }) => theme.currentTheme.iconMain};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.currentTheme.hover};
  }
`;

function StyledLink(props: PropsWithChildren<{ to:string }>){
  return <StyledLinkWrapper>
    <Link to={props.to}>{props.children}</Link>
  </StyledLinkWrapper>
}

function VerticalBar() {
  return <span className='vertical-bar' style={{ margin: '0px 5px' }}>|</span>;
}

export function Links() {
  const { footer: lang } = useLocalization();
  return <>
    <StyledLink to={'/help'}>{lang.help}</StyledLink>
    {/*<VerticalBar/>*/}
    <StyledLink to={'/terms-of-service'}>{lang.termsOfService}</StyledLink>
    {/*<VerticalBar/>*/}
    <StyledLink to={'/contacts'}>{lang.contacts}</StyledLink>
    {/*<VerticalBar/>*/}
    <StyledLink to={'/support-us'}>{lang.supportUs}</StyledLink>
  </>;
}