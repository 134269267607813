import React, { CSSProperties, FunctionComponent, SyntheticEvent, useState } from 'react';
import styled from 'styled-components';

export interface ICheckbox {
  onChange?: any;
  checked?: boolean;
  minus?: boolean;
  className?: string;
  checkInputRef?: React.MutableRefObject<HTMLLabelElement>;
  style?: CSSProperties;
  onClick?: (e: SyntheticEvent) => void;
}

const StyledLabel = styled.label<ICheckbox>`
  border: 1px solid ${({ theme }) => theme.currentTheme.iconLight};
  height: 16px;
  width: 16px;
  background-color: ${({ theme }) => theme.currentTheme.background};
  padding: 1px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  ${({ checked, theme }) =>
    checked
      ? `
        border-color: ${theme.currentTheme.button} !important;
        background-color: ${theme.currentTheme.button};
      `
      : null}

  & > svg {
    width: 100%;
    height: 100%;
    stroke: ${({ theme }) => theme.currentTheme.background};
    ${({ checked }) => (checked ? null : 'display: none;')}
  }

  & > input {
    display: none;
  }
`;

export interface ClickableCheckbox extends ICheckbox {
  onClick?: (e: SyntheticEvent) => void;
}

const Checkbox: FunctionComponent<ClickableCheckbox> = ({
  checked = undefined,
  onChange,
  onClick,
  minus = false,

  checkInputRef,
  ...props
}) => {
  const [stateChecked, setStateChecked] = useState(false);

  const handleChange = (e) => {
    setStateChecked(e.target.checked);
    if (onChange) onChange(e);
  };

  return (
    <StyledLabel
      onClick={onClick}
      ref={checkInputRef}
      checked={checked !== undefined ? checked : stateChecked}
      {...props}
    >
      <input type="checkbox" checked={checked !== undefined ? checked : stateChecked} onChange={handleChange} />
      {minus ? (
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="2" y="4" width="7" height="3" fill="white" stroke="transparent" />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 14 11" fill="none">
          <path d="M2 4L6 8L12 2" strokeWidth="3" />
        </svg>
      )}
    </StyledLabel>
  );
};

export default Checkbox;
