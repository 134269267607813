import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { PopupMenu } from '../PopupMenu/PopupMenu';

export function AutosizeContainerWithDropdown<T>(props: {
  gap?: number;
  button: ReactNode;
  elementWidth?: number;
  // limit?: number;
  layout?: 'horizontal' | 'vertical',
  items: T[];
  makeItem1: (item: T) => ReactNode;
  makeItem2: (item: T) => ReactNode;
}) {

  const containerRef = useRef<HTMLDivElement>();
  const [visibleCount, setVisibleCount] = useState<any>();
  const [children, setChildren] = useState<[ReactNode[], ReactNode[]]>(calcChildren());

  useEffect(() => {
    recalculateVisibility();
    const observer = new ResizeObserver(recalculateVisibility);
    observer.observe(containerRef.current.parentElement);
    return () => observer.disconnect();
  }, []);

  function calcChildren() : [ReactNode[], ReactNode[]]{
    return [
      items(i => i < visibleCount).map(props.makeItem1),
      items(i => i >= visibleCount).map(props.makeItem2),
    ];
  }

  useEffect(() => {
    setChildren(calcChildren);
  }, [visibleCount])

  function items(fn) {
    return props.items.filter((_, i) => fn(i));
  }

  function recalculateVisibility() {
    const bounds = containerRef.current.parentElement.getBoundingClientRect();
    let count = Math.floor((bounds.width - props.elementWidth) / (props.elementWidth + props.gap));
    if (count === props.items.length - 1)
      count++
    setVisibleCount(count);
  }

  return <div ref={containerRef} style={{
    display: 'flex',
    gap: props.gap
  }}>

    {children[0]}

    {children[1]?.length > 0 && (
      <PopupMenu popupContent={() => <>{children[1]}</>}>
        {props.button}
      </PopupMenu>
    )}

  </div>;
}