import React from 'react';
import { FooterIcon } from './FooterIcon';

export function Social_Telegram() {
  return <FooterIcon color='#40b3e0'>
    <path
      d='M12 0C5.37253 0 0 5.37253 0 12C0 18.6275 5.37253 24 12 24C18.6275 24 24 18.6275 24 12C24 5.37253 18.6275 0 12 0Z'
      className='mainColor'
    />
    <path
      d='M17.839 6.90289L15.6957 17.7092C15.6957 17.7092 15.396 18.4586 14.5716 18.0989L9.62556 14.3069L7.82706 13.4376L4.7995 12.4184C4.7995 12.4184 4.33488 12.2536 4.28988 11.8939C4.24497 11.5341 4.8145 11.3393 4.8145 11.3393L16.8498 6.61808C16.8498 6.61808 17.839 6.18345 17.839 6.90289Z'
      className='bgColor'
    />
    <path
      d='M9.24549 17.5878C9.24549 17.5878 9.10112 17.5743 8.92121 17.0047C8.7414 16.4352 7.82715 13.4376 7.82715 13.4376L15.0962 8.82136C15.0962 8.82136 15.516 8.56654 15.501 8.82136C15.501 8.82136 15.5759 8.86626 15.3511 9.07608C15.1262 9.28598 9.64065 14.217 9.64065 14.217'
      className='mainColor'
    />
    <path
      d='M11.522 15.7608L9.56561 17.5445C9.56561 17.5445 9.41271 17.6606 9.24536 17.5878L9.61999 14.2746'
      className='mainColor'
    />
  </FooterIcon>;
}