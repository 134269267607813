import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  RoomsPage,
  LoginPage,
  RegisterPage,
  EnterLessonPage,
  HelpPage,
  LessonPage,
  NotFoundPage,
  OfflineGame,
  RecoverPage,
  UserSettingsPage,
  LandingPage,
  AdminDashboard,
  AdminDashboardSubpageUsers,
  AdminDashboardSubpageLogs,
  AdminDashboardSubpageRooms,
  AdminDashboardSubpageKnowledge,
} from '../pages';
import { useRoomsStore } from '../store/rooms/RoomsStore';
import { TermsOfService } from '../pages/TermsOfService/TermsOfService';
import { SupportUs } from '../pages/SupportUs/SupportUs';
import { Contacts } from '../pages/Contacts/Contacts';
import { MaterialsPage } from '../pages/MaterialsPage/MaterialsPage';
import { adminKnowledgePath, enterRoomKey, lessonPath, materialsPath } from './routesPaths';

const commonRoutes = [
  {
    path: '/offline-game',
    component: OfflineGame,
  },
  {
    path: '/help',
    component: HelpPage,
  },
  {
    path: '/terms-of-service',
    component: TermsOfService,
  },
  {
    path: '/support-us',
    component: SupportUs,
  },
  {
    path: '/contacts',
    component: Contacts,
  },
  {
    path: '/not-found',
    component: NotFoundPage,
  },
];

const authorizedUserRoutes = [
  ...commonRoutes,
  {
    path: lessonPath + ':id',
    component: LessonPage,
  },
  {
    path: '/user-settings',
    component: UserSettingsPage,
  },
  {
    path: '/rooms',
    component: RoomsPage,
  },
];

const authorizationRoutes = [
  {
    path: lessonPath + ':id',
    component: EnterLessonPage,
  },
  {
    path: '/auth/login',
    component: LoginPage,
  },
  {
    path: '/google',
    component: LoginPage,
  },
  {
    path: '/auth/register',
    component: RegisterPage,
  },
  {
    path: '/auth/recover/:email?',
    component: RecoverPage,
  },
];

function redirectToConcreteRoomOrElse(elseRoute) {
  const roomId = localStorage.getItem(enterRoomKey);
  if (roomId) {
    localStorage.removeItem(enterRoomKey);
  }
  // console.log("restored", roomId)
  return roomId ? lessonPath + roomId : elseRoute;
}

function calculateTeacherAuthRedirectRoute() {
  const rooms = useRoomsStore.getState().items;
  let route = rooms.length === 1 ? lessonPath + rooms[0].id : '/rooms';
  let to = redirectToConcreteRoomOrElse(route);
  return <Redirect to={to} />;
}


function calculateStudentAuthRedirectRoute() {
  return <Redirect to={redirectToConcreteRoomOrElse('/rooms')} />;
}

export const teacherRoutes = [
  ...authorizedUserRoutes,
  {
    path: materialsPath,
    component: MaterialsPage,
  },
  {
    path: '/auth',
    component: calculateTeacherAuthRedirectRoute,
  },
  {
    path: '/',
    exact: true,
    component: LandingPage,
  },
];

export const studentRoutes = [
  ...authorizedUserRoutes,
  {
    path: '/auth',
    component: calculateStudentAuthRedirectRoute,
  },
  {
    path: '/',
    exact: true,
    component: LandingPage,
  },
];

export const guestRoutes = [
  ...commonRoutes,
  ...authorizationRoutes,
  {
    path: '/rooms',
    component: () => <Redirect to='/' />,
  },
  {
    path: '/',
    exact: true,
    component: LandingPage,
  },
];

export const adminRoutes = [
  ...teacherRoutes,
  {
    path: '/admin/users',
    component: AdminDashboardSubpageUsers,
  },
  {
    path: '/admin/logs',
    component: AdminDashboardSubpageLogs,
  },
  {
    path: '/admin/rooms',
    component: AdminDashboardSubpageRooms,
  },
  {
    path: adminKnowledgePath,
    component: AdminDashboardSubpageKnowledge,
  },
  {
    path: '/admin',
    component: AdminDashboard,
  },
];