import { lerp } from '@chessclub/web-game-server/src/shared/util/math';
import React from 'react';
import { SvgIconAnimation } from '../SvgIconAnimation';
import { uuid } from '@chessclub/web-game-server/src/client/util/uuid';

export function ChessRules(props: { active: boolean;}) {
  const id = uuid()
  return <SvgIconAnimation
    {...props}
    id={id}
    duration={300}
    viewport={32}
    size={32}

    interpolator={t => {

      const x = 10.5-16, y=6.5-16, x1 = 17.5-16, y1= 13.5-16,
        X = lerp(x, x1,  t),
        Y = lerp(y, y1,  t);

      return `M ${X} ${Y} h 7 v 7 h -7 z`;

    }} >
    <g className="stroke" fill="none"  strokeLinejoin={'round'} strokeWidth={1.5}>
      <use xlinkHref={"#" + id} />

      <g transform={"translate(-16,-16)"}>
        <rect x="10.5" y="13.5" width="7" height="6" className="stroke fill"/>
        <rect x="17.5" y="6.5" width="7" height="7" className="stroke fill"/>
        <rect x="10.5" y="19.5" width="7" height="7" className="stroke"/>
        {/*<rect x="10.5" y="6.5" width="7" height="7" className="stroke"/>*/}
      </g>

    </g>

  </SvgIconAnimation>;
}