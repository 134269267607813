import React from 'react';
import { IUser } from '@chessclub/grpc_wrapper';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  z-index: 111;
  pointer-events: none;

  & > img {
    width: 40px;
    border-radius: 40px;
    padding: 5px;
  }
`;

export function CameraCardAvatar({ user }: { user: IUser }) {
  return <Wrapper>
    <img
      className='hidden'
      alt={''}
      src={user.imageUrl}
    />
  </Wrapper>;
}