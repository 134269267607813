import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { SvgImage } from '../../components/Icon/SvgImage';

const StyledLogoContainer = styled.div`
  margin-top: 10vh;

  @media (max-height: 850px) {
    margin-top: 8vh;
  }
  @media (max-height: 805px) {
    margin-top: 6vh;
  }
  @media (max-height: 770px) {
    margin-top: 4vh;
  }
  @media (max-height: 740px) {
    margin-top: 2vh;
  }
  @media (max-height: 705px) {
    margin-top: 1vh;
  }
`;

const StyledSocialLogin = styled(SvgImage)`
  width: 40px;
  height: 40px;
  opacity: 0.8;
  transition: 0.2s opacity;

  :hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const StyledMessage = styled.p`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};
  padding: 0px;
  margin: 40px 0px 28px 0px;
`;

const StyledOr = styled.p`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.iconMain};
  padding: 0px;
  margin: ${({ theme }) => theme.spacing.medium}px 0px;
`;

const InputWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium}px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.large}px;
  width: 100%;

  button {
    height: 48px;
    padding-top: 14px;
  }
`;

const LinkWrapper = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.large}px;

  a:hover {
    text-decoration: underline;
  }
`;

const StyledSocialsContainer = styled(Grid).attrs({
  container: true,
})`
  align-items: center;
  width: 333px !important;
  justify-content: center;
  margin: 0 auto;
  height: 40px;

  img {
    margin-right: 55px;
    filter: grayscale(50%);
  }
  img:hover {
    filter: grayscale(0%);
  }

  img:last-child {
    margin-right: 0;
  }
`;


export const LoginPageStyledComponents = {
  StyledSocialsContainer, LinkWrapper, StyledSocialLogin,
  ButtonWrapper, InputWrapper, StyledOr, StyledMessage, StyledLogoContainer
}