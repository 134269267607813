
import { LessonAuthSettings } from '../../store/auth/AuthStoreApi';
import { ServerApi } from '../../transport/ServerApi';

export async function getLessonSettings(roomId): Promise<LessonAuthSettings> {
  console.log('async getLessonSettings(roomId)', roomId);

  let room = await ServerApi.roomsService.getRoom(roomId);

  return {
    captcha: room.settings.captcha,
    error: "",
    password: room.password,
  };

}