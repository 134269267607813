import React from 'react';
import styled from 'styled-components';
import {useModalStore} from "../../store/modalStore";
import { SvgImage } from '../Icon/SvgImage';
import { KnownIcons } from '../Icon/KnownIcons';

export const CloseIcon = () => {
  const { closeModal } = useModalStore();
  return <SvgImage icon={KnownIcons.cross} onClick={() => closeModal()} withHover/>;
};

export const StyledModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledModalTitle = styled.b`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};
  margin-right: 0.8rem;
`;

export const StyledModalBody = styled.div`
  margin-top: 22px;
  position: relative;
`;

export const StyledInputWrapper = styled.div`
  margin-top: 16px;
  &:first-child {
    margin-top: 0px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const IconButton = styled.button`
  background: none;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;

  padding: 14px;
  border-radius: 8px;
  margin-left: 10px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.currentTheme.hover};
  }
`;

interface IModalFooter {
  mobileTwoButtons?: boolean;
}

export const StyledModalFooter = styled.div<IModalFooter>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  & > * {
    padding-left: 40px;
    padding-right: 40px;
  }

  & > *:first-child {
    margin-right: 30px;
  }
`;

export const StyledButtons = styled.div`
  margin-top: 16px;
  & > *:first-child {
    margin-right: 16px;
  }
`;

export const StyledBodyText = styled.div`
  padding: 0px;
  margin: 0px;
  // margin-bottom: 20px;
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: #939393;
    }
  }

  & b {
    font: ${({ theme }) => theme.fonts.normalBold};
    font-size: 15px;
    color: #939393;
  }

  div:last-child > p {
    margin-bottom: 0;
  }
  div:first-child > p {
    margin-top: 0;
  }
`;

interface IButtonsContainer {
  buttons?: number;
}

export const ButtonsContainer = styled.div<IButtonsContainer>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;

  ${({ buttons }) => (buttons ? `& > * { width: calc((100% - ${16 * (buttons - 1)}px) / ${buttons}); }` : null)}

  ${({ buttons }) =>
    buttons > 1
      ? `
      & > * {
        margin-right: 16px;
      }
      & > *:last-child {
        margin-right: 0px;
      }`
      : null}
`;

export const IconWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
`;

export const StyledDivider = styled.div`
  border-bottom: 1px solid var(--color-icon-light);
  width: calc(100% - 2rem);
  margin: 1rem;
  box-sizing: border-box;  
`;
