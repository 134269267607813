import React, { useState } from 'react';
import styled from 'styled-components';
import { EnumSettingsTab, IUserProfile, SettingsTab } from './types';
import { SecurityTab } from './SecurityTab';
import { PersonalInfoTab } from './PersonInfoTab';
import { ThemeTab } from './ThemeTab';
import { Theme, useThemeStore } from '../../store/themeStore';
import { useAuthStore } from '../../store/auth/AuthStore';

const StyledTabItem = styled.div<{ active: boolean; disabled?: boolean }>`
  ${({ theme, active }) => active && `background: ${theme.currentTheme.select};`}
  ${({ disabled }) => disabled && `cursor: default !important; opacity: 0.5;`}
`;

const StyledTabsList = styled.div<{ isDarkMode: boolean }>`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ isDarkMode }) => (isDarkMode ? '#bebebe' : '#000')};
  margin-right: 52px;

  ${StyledTabItem} {
    width: 252px;
    height: 56px;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

const StyledContainer = styled.div`
  padding-top: 28px;
  display: flex;
`;

export function UserProfilePage(props: IUserProfile) {
  let { profile, onChange } = props;
  const { theme } = useThemeStore();
  const [tab, setTab] = useState<SettingsTab>(EnumSettingsTab.personalInfo);
  const { user } = useAuthStore();
  const shouldDisableSecurityTab = !!user?.social;

  return (
    <StyledContainer>
      <StyledTabsList isDarkMode={theme === Theme.dark}>
        {Object.entries(EnumSettingsTab)
          .filter(([_, value]) => (user ? true : value !== EnumSettingsTab.security))
          .map(([_, value], i) => (
            <StyledTabItem
              disabled={value === EnumSettingsTab.security ? shouldDisableSecurityTab : false}
              onClick={() =>
                value === EnumSettingsTab.security
                  ? !shouldDisableSecurityTab && setTab(value as SettingsTab)
                  : setTab(value as SettingsTab)
              }
              active={value === tab}
              key={i}
            >
              {value}
            </StyledTabItem>
          ))}
      </StyledTabsList>
      {tab === EnumSettingsTab.personalInfo && <PersonalInfoTab onChange={onChange} profile={profile} />}
      {tab === EnumSettingsTab.security && <SecurityTab onChange={onChange} profile={profile} />}
      {tab === EnumSettingsTab.theme && <ThemeTab />}
    </StyledContainer>
  );
}

