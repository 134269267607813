import React from 'react';
import { useEditingTaskStore } from '../../../../store/editing-task/EditingTaskStore';
import { useEditingTopicStore } from '../../../../store/editing-topic/EditingTopicStore';
import { Space } from '../../../../components/low-level-components/Space';
import { KnowledgeEditingTaskToolbar } from './KnowledgeEditingTaskToolbar';
import { KnowledgeEditingTopicToolbar } from './KnowledgeEditingTopicToolbar';

export function KnowledgeToolbar() {

  const { editingTask } = useEditingTaskStore();
  const { editingTopic } = useEditingTopicStore();

  if (editingTask)
    return <KnowledgeEditingTaskToolbar/>;

  if (editingTopic)
    return <KnowledgeEditingTopicToolbar/>

  return <>
    <Space size={42} />
  </>;
}