import {create} from 'zustand';
import { LessonViewMode } from './lesson-ui/LessonUiStoreApi';
import { ServerApi } from '../transport/ServerApi';
import { useAuthStore } from './auth/AuthStore';
import { localStorageObject } from '../helpers/localStorageObject';

export type InterfaceClr = 'sky' | 'orange' | 'green' | 'violet';
const defaultInterfaceClr: InterfaceClr = 'orange';

function getStoredSizes() {
    const LStorageViewSizes = localStorage.getItem('chess--view-sizes');

    const sizes: Sizes = LStorageViewSizes
        ? JSON.parse(LStorageViewSizes)
        : {
            [LessonViewMode.STANDARD]: 2,
            [LessonViewMode.CAMERAS]: 2,
            [LessonViewMode.CHESSBOARDS]: 2
        };
    localStorage.setItem('chess--view-sizes', JSON.stringify(sizes));

    return sizes;
}

function getStoredInterfaceClr() {
    const interfaceClr: InterfaceClr =
        (localStorage.getItem('chess--interfaceClr') as InterfaceClr) || defaultInterfaceClr;
    localStorage.setItem('chess--interfaceClr', interfaceClr);
    return interfaceClr;
}


type Sizes = {
    [key in LessonViewMode]?: number;
};

export enum Lang {
    ru = "ru",
    en = "en",
    cn = "cn",
    hi = "hi",
}

export interface UiStoreInitialState {
    lang: Lang
    sizes: Sizes;
    interfaceClr: InterfaceClr;
    draggingFigure: null | string;
    placeholder: string;
}

export interface UiStoreActions {
    setPlaceholder(placeholder: string);
    setLang(lang: Lang);
    setDraggingFigure(draggingFigure: string | null): void;
    setHandupAnimate(handUpAnimate: boolean): void;
    setInterfaceCLr(interfaceClr: UiStoreInitialState['interfaceClr']): void;
    changeSize(sizes: UiStoreInitialState['sizes']): void;
}

export type UiStore = UiStoreInitialState & UiStoreActions;


const storedLang = localStorageObject<Lang>('lang', Lang.ru);

const initialState: UiStoreInitialState = {
    lang: storedLang.restore() || Lang.ru,
    sizes: getStoredSizes(),
    interfaceClr: getStoredInterfaceClr(),
    draggingFigure: null,
    placeholder: null
};

export const useUiStore = create<UiStore>((
  set,
  get
) => {

    return {
        ...initialState,

        setPlaceholder(placeholder: string) {
            set({placeholder})
        },

        async setLang(lang: Lang) {
            storedLang.save(lang);
            set({ lang });

            const user = useAuthStore.getState().user;
            if (user && user.id) {
                await ServerApi.profileService.setProfileLanguage(user.id, lang);
            }
        },
        setDraggingFigure: (draggingFigure: string | null) => {
            set({ draggingFigure });
        },
        setInterfaceCLr: (interfaceClr: UiStoreInitialState['interfaceClr']) => {
            localStorage.setItem('chess--interfaceClr', interfaceClr);
            set({ interfaceClr });
        },

        changeSize: (sizes: UiStoreInitialState['sizes']) => {
            localStorage.setItem('chess--view-sizes', JSON.stringify({ ...getStoredSizes(), ...sizes }));

            set({ ...get(), sizes: { ...get().sizes, ...sizes } });
        },
    } as UiStore;
});
