import React, { CSSProperties } from 'react';
import { RawSvgString } from '*.svg';
import styled from 'styled-components';
import { Theme, useThemeStore } from '../../store/themeStore';

export interface RawSvgProps {
  rawSvg: RawSvgString;
  rawSvgDark?: RawSvgString;
  width?: number;
  height?: number;
  className?: string;
  rotate?: number;
  style?: CSSProperties;
  onClick?: (e) => void;
}

const StyledDiv = styled.div<Omit<RawSvgProps, "rawSvg">>`
  width: ${p => p.width ? p.width + 'px' : 'unset'};
  height: ${p => p.height ? p.height + 'px' : 'unset'};
  transform: rotate(${p => p.rotate || 0}deg);
  display: flex;
`;

export function RawSvg(props: RawSvgProps) {
    const isDark = useThemeStore().theme === Theme.dark && props.rawSvgDark;
    return <StyledDiv
      {...props}
      dangerouslySetInnerHTML={{__html: isDark ? props.rawSvgDark : props.rawSvg}}
    />
}