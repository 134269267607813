import { ITask, TaskType } from '@chessclub/grpc_wrapper';
import { useLessonUiStore } from '../../../store/lesson-ui/LessonUiStoreImpl';
import { useTasksStore } from '../../../store/tasks/TasksStore';
import React, { useEffect, useState } from 'react';
import { GameApi } from '@chessclub/web-game-server/src/client/hooks/useCentrifugeChessboard';
import { Resizer } from '../../../components/Resizer/Resizer';
import { ChessboardWithLocalLogic } from '../../../components/Chessboard/ChessboardWithLocalLogic';
import { UnderChessboardPanel } from '../../../components/Chessboard/UnderChessboardPanel';
import { GameState } from '@chessclub/web-game-server';
import { useRoomUserId } from '../../../hooks/useRoomUserId';

export function LocalLessonChessboardWithControls(props: { task: ITask }) {

  const userId = useRoomUserId()
  const { boardRotations, rotateBoard } = useLessonUiStore();
  const { setCurrentTaskGameApi, currentTaskForceUpdate, runtimeTasksStates, currentTaskGameState: s, updateRuntimeTaskState } = useTasksStore();
  const [gameApi, setGameApi] = useState<GameApi>();
  const taskToDisplay = props.task;//runtimeTasksStates[props.task.id] || props.task;

  useEffect(() => {
    gameApi && gameApi.requestUpdate()
  }, [currentTaskForceUpdate, gameApi])

  useEffect(() => {
    setCurrentTaskGameApi(gameApi);
  }, [gameApi])

  return <>
    <div>{s?.result}</div>
    <Resizer>
      <ChessboardWithLocalLogic
        task={{...taskToDisplay, taskId: taskToDisplay.id}}
        onGameApiChanged={setGameApi}
        onGameStateChanged={(gs: GameState) => updateRuntimeTaskState(taskToDisplay.id, gs)}
        reversed={boardRotations[taskToDisplay.id]}
      />
    </Resizer>
    <UnderChessboardPanel
      clearMarkers={() => gameApi?.clearMarkers({ userId })}

      reverse={() => rotateBoard(taskToDisplay?.id)}
      isReversed={boardRotations[taskToDisplay.id]}

      isRulesOn={s?.applyRules}
      toggleRules={() => gameApi?.setApplyRules(!s?.applyRules, true)}

      isLegalMovesOn={s?.legalMoves}
      toggleLegalMoves={() => gameApi?.setLegalMoves(!s?.legalMoves, true)}

      isGameRunning={s?.isPlaying}
      toggleGameRunning={() => gameApi?.setPlayStop(!s?.isPlaying)}

      isTimerOn={s?.isTimerOn}
      toggleTimer={() => gameApi?.setUseTimer(!s?.isTimerOn, true)}
    />
  </>;
}