import React from 'react';

import { FENEnum } from '../../interfaces/question';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { useStoredState } from '../../hooks/useStoredState';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import { ChessboardWithLocalLogic } from '../../components/Chessboard/ChessboardWithLocalLogic';
import { FEN, TaskType } from '@chessclub/grpc_wrapper';

export function OfflineGame() {
  const [fen, setFen] = useStoredState<FEN>("offline-fen", FENEnum.initial);
  return <PageTemplate>
    <div style={{display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center'}}>
    <div style={{width:"min(70vh, 70vw)"}}>
      <ChessboardWithLocalLogic
        onGameApiChanged={() => {}}
        onGameStateChanged={() => {}}
        task={{
          fen,
          title: "game",
          taskId: "game_TaskId",
          type: TaskType.GAME
        }}
      />
    </div>
      <SvgImage withHover icon={KnownIcons.reverse} onClick={() => {
        setFen(FENEnum.initial);
      }}/>
    </div>
  </PageTemplate>
}