import React, { useEffect, useRef } from 'react';
import { ICameraCard } from './CameraCardBase';
import { useConferenceStore } from '../../../../../../../store/conferenceStore';

export function CameraCardVideoTag({ user, stream, muted, mirror, cameraEnabled }: ICameraCard) {

  const { getVideoTag, releaseVideoTag } = useConferenceStore();

  const videoStreamRef = useRef<HTMLVideoElement>();
  const containerRef = useRef<HTMLDivElement>();

  const animationName = (cameraEnabled ? 'on' : 'off') + (mirror ? '-my' : '');

  useEffect(() => {
    let videoTag = getVideoTag(user.id);
    videoTag.style.pointerEvents = "none"
    containerRef.current.appendChild(videoTag);
    videoStreamRef.current = videoTag;
    return () => {
      releaseVideoTag(videoTag);
    }
  }, []);


  useEffect(() => {
    if (user.videoStream) {
      videoStreamRef.current.srcObject = user.videoStream;
    }
  }, [user.videoStream]);

  useEffect(() => {
    if (!stream) {
      return;
    }
    videoStreamRef.current!.srcObject = stream;

    const isPlaying = videoStreamRef.current!.currentTime > 0
      && !videoStreamRef.current!.paused && !videoStreamRef.current!.ended
      && videoStreamRef.current!.readyState > videoStreamRef.current!.HAVE_CURRENT_DATA;

    if (!isPlaying) {
      videoStreamRef.current!.play();
    }
  }, [stream]);

  useEffect(() => {
    videoStreamRef.current!.muted = muted;
  },[muted])

  return <div
    ref={containerRef}
    style={{
      display:'flex',
      pointerEvents: 'none',
      animation: `0s video-${animationName} forwards`,
    }}
  />;

}