import bold from "../../svg/text-editor/bold.svg"
import italic from "../../svg/text-editor/italic.svg"
import underline from "../../svg/text-editor/underline.svg"
import linethrought from "../../svg/text-editor/linethrought.svg"
import left from "../../svg/text-editor/left.svg"
import center from "../../svg/text-editor/center.svg"
import right from "../../svg/text-editor/right.svg"
import justify from "../../svg/text-editor/justify.svg"
import quote from "../../svg/text-editor/quote.svg"
import chain from "../../svg/text-editor/chain.svg"
import unordList from "../../svg/text-editor/unordList.svg"
import ordList from "../../svg/text-editor/ordList.svg"
import taskList from "../../svg/text-editor/taskList.svg"
import table from "../../svg/text-editor/table.svg"
import ImageIcon from "../../svg/text-editor/ImageIcon.svg"
import paragraph from "../../svg/text-editor/paragraph.svg"
import clearFormat from "../../svg/text-editor/clearFormat.svg"
import undo from "../../svg/text-editor/undo.svg"
import redo from "../../svg/text-editor/redo.svg"

export const TextEditorIcons = {
  bold, italic, underline, linethrought, left, center, right, justify, quote, chain, unordList, ordList,
  taskList, table, ImageIcon, paragraph, clearFormat, undo, redo,


}