import React from 'react';
import { Grid } from '@material-ui/core';

// Adaptive
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Layout
/*
    ---------------------------------------
    LEFT                 CENTER
    ---------------------------------------
*/

export function UserSettingsLayout({ left, center }: any) {
  const is1051PxScreen = useMediaQuery('@media (max-width: 1295px)');

  return (
    <Grid container>
      {<Grid item>{left}</Grid>}
      <Grid style={{ width: is1051PxScreen ? '100%' : '540px' }} item>
        {center}
      </Grid>
    </Grid>
  );
}
