import React  from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';

export function CheckMarkIcon(props: SvgIconProps) {
  return <SvgIcon colored>
    <circle cx='16' cy='16' r='9' className="filled"/>
    <path d='M12 16L15 19L20 14' className="bgStroke"/>
  </SvgIcon>;
}

