import React, { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Stack } from '../../components/low-level-components/Stack';
import { Space } from '../../components/low-level-components/Space';

const palette = [
  "#f0e2d670",
  "#cd9d7670",
  "#674F3B70"
];

function pick(arr) {
  return arr[(Math.random() * arr.length) | 0];
}

function triangle(x, y, w, h, reverse) {
  h = h/2;
  return reverse ?
    `M ${x}   ${y}  l ${w}  ${h}  l${-w} ${h} Z` :
    `M ${x+w} ${y}  l ${-w} ${h}  l${w}  ${h} Z`;
}

const SvgContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  & > * {
    grid-column: 1;
    grid-row: 1;
  }
`

export function GenerativeBackground(props: PropsWithChildren<{}>) {
  const svgRef = useRef<SVGSVGElement>();
  const [viewBox, setViewBox] = useState<string>();
  const [content, setContent] = useState<ReactNode[]>([]);

  useEffect(() => {
    const containerNode = svgRef.current.parentNode as any;
    const rect = containerNode.getBoundingClientRect();
    const {width:w, height: h} = rect;

    const elements = [];
    const ratio = 1.618;
    const tw = w*1.5;
    const th = tw/ratio;
    const x = -tw/4;
    const safeTop = window.innerHeight*0.7;
    const safeBottom = window.innerHeight*0.1;

    const n = 7
    for (let i = 0; i < n; i++) {
      const top = safeTop + (h-safeTop-safeBottom-th)*i/(n-1);
      elements.push(<path
        key={i}
        d={triangle(x, top, tw, th, Math.random() > 0.5)}
        fill={pick(palette)}
      />);
    }
    setContent(elements);
    setViewBox(`0,0,${rect.width},${rect.height}`);
  }, []);

  return <SvgContainer>
    <Stack style={{overflow: 'hidden'}}>
      <svg ref={svgRef} viewBox={viewBox}>
        {content}
      </svg>
      <div>
        <Space size={500} />
        {/*<PagePlaceholder/>*/}
      </div>
    </Stack>
    {props.children}
  </SvgContainer>;
}