import { SocialAuthProvider } from '../../store/auth/AuthStoreApi';
import {
  AutosizeContainerWithDropdown,
} from '../../components/AutosizeContainerWithDropdown/AutosizeContainerWithDropdown';
import React, { useEffect, useRef } from 'react';
import { LoginPageStyledComponents } from './LoginPageStyledComponents';
import { useAuthStore } from '../../store/auth/AuthStore';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import { SocialAuthIcons } from './SocialAuthIcons';
import { PopupMenuItem } from '../../components/PopupMenu/PopupMenuItem';
import { Stack } from '../../components/low-level-components/Stack';
import styled from 'styled-components';
import { TelegramButton } from './TelegramButton';



const names = {
  // [SocialAuthProvider.FB]: 'Facebook',
  [SocialAuthProvider.GOOGLE]: 'Google',
  [SocialAuthProvider.TELEGRAM]: 'Telegram',
  // [SocialAuthProvider.MAIL_RU]: 'Mail.ru',
  // [SocialAuthProvider.OK]: 'Одноклассники',
  // [SocialAuthProvider.TWITTER]: 'Twitter',
  [SocialAuthProvider.VK]: 'Вконтакте',
  [SocialAuthProvider.YANDEX]: 'Yandex',
};

const {
  StyledSocialsContainer, StyledSocialLogin,
} = LoginPageStyledComponents;

export function SocialAuth() {
  const { socialAuth } = useAuthStore();
  const { socialAuthProviders } = useAuthStore();

  return <StyledSocialsContainer>
    <AutosizeContainerWithDropdown
      gap={50}
      layout={'vertical'}
      elementWidth={40}
      items={socialAuthProviders}
      makeItem1={m => {
        if (m === SocialAuthProvider.TELEGRAM)
          return <TelegramButton key={m}/>;
        return <StyledSocialLogin key={m} icon={SocialAuthIcons[m]} onClick={() => socialAuth(m)} />;
      }}
      makeItem2={m => <PopupMenuItem key={m} icon={SocialAuthIcons[m]} text={names[m]} style={{ paddingLeft: 5 }} />}
      button={<SvgImage icon={KnownIcons.triple_dot_horizontal} />}
    />
  </StyledSocialsContainer>;
}


