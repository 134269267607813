import React from 'react';
import styled from 'styled-components';
import { useMaterialsStore } from '../../store/materials/MaterialsStore';
import { MaterialsMode } from '../../store/materials/MaterialsStoreApi';
import { HidingScrollbar } from '../../components/low-level-components/HidingScrollbar';
import { ITopic, TopicType } from '@chessclub/grpc_wrapper';
import { TextNode } from '../../components/low-level-components/TextNode';
import { TopicEntry } from '../../parts/MaterialsEditView/TopicEntry';
import { AddTopicElement } from '../../parts/MaterialsEditView/AddTopicElement';
import { newMaterialTopic } from './newMaterialTopic';
import { JustText } from '../../components';

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

function MaterialsModeSelector(props:{text; mode}) {
  const { setMode, mode } = useMaterialsStore();
  return <JustText
    style={{height: 32, cursor: "pointer", alignItems: 'center', color: props.mode === mode ? "var(--color-accent)": null}}
    onClick={() => setMode(props.mode)}
  >{props.text}</JustText>
}


export function MaterialsPageTopics() {
  const { materialTopics, knowledgeTopics, mode, setMode } = useMaterialsStore();

  return <div style={{display: 'flex', flexDirection: 'column'}}>
    <div style={{marginLeft:5, display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
      <MaterialsModeSelector text={"Мои материалы"} mode={MaterialsMode.MATERIALS}/>
      <MaterialsModeSelector text={"База занний"} mode={MaterialsMode.KNOWLEDGE}/>
    </div>

    <HidingScrollbar>
      <List>
        {mode !== MaterialsMode.KNOWLEDGE &&
          <MaterialsPageTopicList canAdd topics={materialTopics} />}
        {mode !== MaterialsMode.MATERIALS &&
          <MaterialsPageTopicList topics={knowledgeTopics} />}
      </List>
    </HidingScrollbar>
  </div>;
}


export function MaterialsPageTopicList(props: { topics: ITopic[]; canAdd?:boolean}) {
  const { setSelectedTopic, selectedTopic, deleteTopic } = useMaterialsStore();
  return <>
    {props.canAdd && <AddTopicElement topicFactory={newMaterialTopic}/>}
    {props.topics.length ?
      props.topics.map(topic => <TopicEntry
        selected={selectedTopic?.id === topic.id}
        onClick={() => setSelectedTopic(topic)}
        onDelete={topic.type === TopicType.MATERIAL ?() => deleteTopic(topic) : null}
        topic={topic}
        key={topic.id}
      />) :
      <TextNode style={{padding:10}}>Ничего не найдено</TextNode>
    }
  </>;
}

