import React from 'react';
import { useParams } from 'react-router-dom';
import { useLocalization } from '../../../l10n/UseLocalization';
import { ToolbarButton } from '../../../components/ContentContainer/ToolbarButton';
import { KnownIcons } from '../../../components/Icon/KnownIcons';
import { OnlineButton } from '../../../components/OnlineButton/OnlineButton';
import { useLessonStore } from '../../../store/lesson/LessonStore';
import { RoomId } from '@chessclub/grpc_wrapper';

export function LessonOfflineToolbarVariant() {

  const { isStudentLayout } = useLessonStore();
  const { lesson } = useLocalization();
  const { id: roomId } = useParams<{ id: RoomId }>();

  if (isStudentLayout)
    return null

  return <>
    <OnlineButton roomId={roomId} >
      <ToolbarButton
        icon={KnownIcons.power_button}
        text={lesson.offline}
      />
    </OnlineButton>
  </>
}