import React from "react";
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & .ring1 {
    animation: rot 3s  infinite;
    stroke: red;
    stroke-width: 3px;
    stroke-dasharray: 13.8px 4px;
    fill: none;
  }

  & .ring2 {
    animation: rot 3s  infinite reverse;
    stroke: red;
    stroke-width: 3px;
    stroke-dasharray: 13px 4px;
    fill: none;
  }
  
  @keyframes rot {
    0% {transform: rotate(0)}
    100% {transform: rotate(360deg)}
  }
`;

export function LoadingIndicator({size = 32}: {size?}) {
  return <StyledSvg width={size} height={size} viewBox={"-16 -16 32 32"}>
    <circle className="ring1" r="14"/>
    <circle className="ring2" r="8"/>
  </StyledSvg>
}