import styled from 'styled-components';
import React, { PropsWithChildren, SyntheticEvent, useEffect } from 'react';
import { useModalStore } from '../../store/modalStore';
import { HidingScrollbar } from '../low-level-components/HidingScrollbar';

export const StyledBackdrop = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledModal = styled.div`
  z-index: 1302;
  margin: 0 1rem;
  box-sizing: border-box;
  background: ${({ theme }) => theme.currentTheme.background};
  box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.3);
  border-radius: 31px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.currentTheme.hover};
  overflow: auto;
  max-height: 100%;

  *::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: ${({ theme }) => theme.currentTheme.background};
  }

  *::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.currentTheme.background};
  }

  *::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.currentTheme.iconMain};
    border-radius: 5px;
    outline: none;
  }

  @media (max-width: 595px) {
    width: 100%;
    min-width: unset;
  }
`;

export const StyledModalInnerWrapper = styled.div`
  user-select: none;
  margin: 28px 0px;
  padding: 0 20px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: ${({ theme }) => theme.currentTheme.background};
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.currentTheme.background};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.currentTheme.iconMain};
    border-radius: 5px;
    outline: none;
  }
`;

export function ModalBody(props: PropsWithChildren<{}>) {
  const { closeModal } = useModalStore();
  const handleBackdropClick = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // (e.target as any).classList.contains('backdrop') && closeModal();
  };

  function esc(e) {
    e.key === "Escape" && closeModal()
  }

  useEffect(() => {
    window.addEventListener("keydown", esc);
    return () => {
      window.removeEventListener("keydown", esc);
    }
  }, []);


  return <StyledBackdrop onClick={handleBackdropClick} className='backdrop'>
    <StyledModal>
        <StyledModalInnerWrapper>{props.children}</StyledModalInnerWrapper>
    </StyledModal>
  </StyledBackdrop>;
}