import { create } from 'zustand';
import { localStorageObject } from '../helpers/localStorageObject';
import { XY } from '@chessclub/web-game-server/src/shared';

export enum Theme {
  dark = 'dark',
  light = 'light'
}

interface ThemeStoreInitialState {
  theme: Theme;
  clickData?: XY&{time: number};
}

interface ThemeStoreActions {
  setTheme(theme: Theme, clickPoint?: XY): void;
}

type ThemeStore = ThemeStoreInitialState & ThemeStoreActions

const themeStore = localStorageObject<Theme>('theme', Theme.light);

export const useThemeStore = create<ThemeStore>((
  set,
  get
) => ({
  theme: themeStore.restore(),
  clickData: null,
  setTheme: (theme: Theme, clickPoint?: XY) => {
    themeStore.save(theme)
    set({ theme, clickData: {...clickPoint, time: Date.now()} });
  },
} as ThemeStore));
