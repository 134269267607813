import { L10n } from "./L10n";

export const Lang_ru: L10n = {
  "landing": {
    "mainTitle": "Обучай<br/>на новом уровне",
    "mainSubTitle": "платформа для дистанционного<br/>обучения игре в шахматы",
    "aboutUs": "О нас",
    "aboutUsText": "Миссия платформы <b>ChessClub Training</b> - сделать процесс обучения максимально комфортным, эффективным и увлекательным, как для преподавателей, так и для учеников. ",
    "ourCapabilitiesTitle": "Наши возможности",
    "ourCapabilitiesElements": [
      ["/img/landing/icons/crown.svg", "Проведение как индивидуальных, так и групповых занятий"],
      ["/img/landing/icons/crown.svg", "Геймификация и внедрение новых игровых форм в обучение детей шахматам"],
      ["/img/landing/icons/crown.svg", "Возможность проведения онлайн-турниров"],
      ["/img/landing/icons/crown.svg", "Расширенный инструментарий преподавателя (тренера)"],
      ["/img/landing/icons/crown.svg", "Удаленное обучение с эффектом присутствия"]
    ],
    "ourPlatformTitle": "Наша платформа для Вас, если Вы:",
    "ourPlatformElements": [
      ["/img/landing/icons/knight.svg", "Занимаетесь фрилансом в области обучения игре в шахматы"],
      ["/img/landing/icons/rook.svg", "Преподаватель в шахматном клубе"],
      ["/img/landing/icons/king.svg", "Профессиональный тренер по шахматам"]
    ],
    "ourAdvantagesTitle": "Наши приемущества",
    "ourAdvantagesElements": [
      ["/img/landing/icons/pawns-3.svg", "Возможность проведения занятий с разными возрастными категориями"],
      ["/img/landing/icons/ai.svg", "Применение технологий искусственного интеллекта"],
      ["/img/landing/icons/pawns-6.svg", "Возможность проведения групповых онлайн-занятий"],
      ["/img/landing/icons/pc.svg", "Адаптивные интерфейсы под ПК, ноутбук, смартфон, планшет, мобильная версия"],
      ["/img/landing/icons/two-knights.svg", "Возможность проведения онлайн-турниров"],
      ["/img/landing/icons/arrows.svg", "Виртуальная указка с синхронизацией перемещений курсора и фигур "],
      ["/img/landing/icons/lamp.svg", "Контроль честной игры"],
      ["/img/landing/icons/server.svg", "Синергия с сервисами TimeWeb (надежный хостинг на серверах)"],
    ],
    "ourExpertsTitle": "Наши эксперты",
    "ourExpertsElements": [
      ["/img/landing/experts/vladimir-bykov.png", "<b>Владимир Быков</b><br/><br/>Исполнительный директор федерации шахмат Санкт-Петербурга, капитан команды «Медный Всадник»"],
      ["/img/landing/experts/ekaterina-sudoplatova.png", "<b>Екатерина Судоплатова</b><br/><br/>Международный женский гроссмейстер, международный мужской мастер. Двукратная чемпионка России и чемпионка мира среди девушек, чемпионка Европы в составе сборной России, член олимпийской сборной России, чемпионка России среди женщин, многократная чемпионка Санкт-Петербурга.Тренер, учитель внеурочной деятельности, педагог дополнительного образования"],
      ["/img/landing/experts/alina-balayan.png", "<b>Алина Балаян</b><br/><br/>Международный женский мастер, мастер спорта России. Чемпионка России в составе команды, чемпионка России среди женщин по рапиду, многократная чемпионка Санкт-Петербурга. Тренер, педагог дополнительного образования"],
      ["/img/landing/experts/valeriy-popov.png", "<b>Валерий Попов</b><br/><br/>Международный гроссмейстер, Тренер высшей категории, Чемпион Европы, Старший тренер отделения шахмат СПБ ГБУ СШОР по шахматам"],
      ["/img/landing/experts/dina-belenkaya.png", "<b>Дина Беленькая</b><br/><br/>Женский международный гроссмейстер, многократная чемпионка г.Санкт-Петербурга, многократный призёр Чемпионата России в составе команды, преподаватель по шахматам."],
      ["/img/landing/experts/anastasia-bondaruk.png", "<b>Анастасия Боднарук</b><br/><br/>Международный мастер по шахматам, многократная чемпионка города Санкт-Петербурга, России, член сборной России."],
    ]
  },
  "footer": {
    "help" : "Справка",
    "contacts": "Контакты",
    "supportUs": "Поддержать проект",
    "termsOfService": "Условия использования"
  },
  "lesson": {
    "newTask": "Новый вопрос",
    "chain": "Цепочка",
    "table": "Таблица",
    "clear": "Очистить",
    "room": "Комната",
    "defaultRoom": "Комната по умолчанию",
    "ok": "Ok",
    "empty": "Пусто",
    "sizeHuge": "Огромный",
    "sizeLarge": "Большой",
    "sizeStandard": "Стандартный",
    "markersBlue": "Синий маркер",
    "markersGreen": "Зеленый маркер",
    "markersOff": "Выключить маркеры",
    "markersRed": "Красный маркер",
    "markersDelete": "Удалить маркеры",
    "withoutTitles": "Без подписей",
    "titlesAtCellCorners": "В углах ячеек",
    "titlesInEveryCell": "В каждой ячейке",
    "titlesAroundChessboard": "Вокруг доски",
    "standardView": "Стандартный",
    "allChessboardsView": "Все доски",
    "allCamsView": "Все камеры",
    "size": "Размер",
    "exit": "Выйти",
    "materials": "База знаний",
    "rooms": "Комнаты",
    "profile": "Профиль",
    "edit": "Редактировать",
    "paste": "Вставить",
    "copy": "Копировать",
    "save": "Сохранить",
    "cancel": "Отменить",
    "delete": "Удалить",
    "add": "Добавить",
    "waitForTrainer": "Ожидайте входа тренера",
    "chat": "Чат",
    "cams": "Камеры",
    "online": "Онлайн",
    "view": "Вид",
    "demonstration": "Демонстрация",
    "settings": "Настройки",
    "offline": "Оффлайн",
    "black": "Черные",
    "white": "Белые",
    "tasks": "Вопросы",
    "moves": "Ходы",
    "activeLessons": "Активные уроки",
    "activeLesson": "Активный урок",
    "student": "Студент",
    "trainer": "Тренер",
    "lesson": "Урок",
  },
  "captcha": {
    "language": "Русский",
    "errors": {
      "0": "Ошибок не найдено",
      "3": "Неверные параметры каптчи",
      "501": "Каптча не найдена",
      "502": "Неверные параметры каптчи",
      "503": "Неверный емайл каптчи",
      "504": "Каптча просрочена",
      "505": "Ответ просрочен",
      "506": "Неверный ответ каптчи"
    }
  },
  "auth": {

    "new_password": "Новый пароль",
    "repeat_new_password": "Повторите новый пароль",
    "consider_new_password": "Придумайте новый пароль и для подтверждения введите код, который был отправлен на Ваш электронный ящик",
    "confirm": "Подтвердить",
    "agree_with": "Я согласен (-на) с ",
    "terms": "праивлами сервиса",
    "do_sign_up": "Зарегистрироваться",
    "continue": "Продолжить",
    "code": "Код  из письма",
    "email_to_restore": "Введите адрес электронной почты для восстановления доступа",
    "receive_code_in_mail": "Получить код в письме",
    "repeat_password": "Повторите пароль",

    "login_with": "Войдите с помощью",
    "login": "Войти",
    "forgot_password": "Восстановить пароль",
    "or": "или",
    "email": "Электронная почта",
    "password": "Пароль",
    "no_account_yet": "Ище нет аккаунта?",
    "sign_up": "Регистрация",
    "yandex": "Яндекс",
    "ok": "Одноклассники",
    "mail": "Майл.ру",
    "fb": "Фейсбук",
    "twitter": "Твитер",
    "vk": "Вконтакте",
    "google": "Гугл",
    "choose_lg": "Выберите ваш язык",
    "language": "Русский",
    "errors": {
      "0": "Ошибок нет",
      "1": "Пользователь не найден",
      "2": "Пользователь уже существует",
      "3": "Неверные параметры доступа",
      "4": "Пользователь не активирован",
      "5": "Неверный пароль",
      "6": "Неверный код активации",
      "7": "Неверный знак сеанса сессии",
      "8": "Пользователь не аутентифицирован",
      "9": "Неверный код востановления",
      "10": "Пользователь уже актвирован",
      "11": "Неверный код аутентификации",
      "12": "Переданы неверный настройки",
      "13": "Неверный социальный провайдер",
      "14": "Слишком слабый пароль",
      "15": "Ошибки при попытке получении информации о пользователе",
      "16": "OAuth приложение уже существует",
      "17": "OAuth приложение не найдено",
      "18": "OAuth потребитель не найден",
      "19": "Достигнуто максимальное количествоь OAuth приложений",
      "20": "OAuth код авторизации не найден",
      "21": "OAuth токен доступа не найден",
      "22": "Ошибка отправки SMTP майла",
      "23": "Неверный тип изображения",
      "24": "Ошибка отправки изображения"
    }
  },
  "permissions": {
    "OtherError": {
      "otherError": "Ваша камера недоступна. Воспользуйтесь другим устройством."
    },
    "BlockedAtSystemLevelMacOs": {
      "camera": "Камера не подключена, либо доступ {{browserName}} к камере запрещён на уровне операционной системы.",
      "check": "Проверьте, работает ли камера в других приложениях (например, Photo Booth). Если с камерой всё в порядке, убедитесь, что {{browserName}} имеет доступ к камере.",
      "p1_menu": "Выберите меню Apple",
      "p1_pref": "→ «Системные настройки», нажмите «Защита и безопасность», затем нажмите «Конфиденциальность»;",
      "p2": "Выберите «Камера», установите флажок рядом с приложением {{browserName}};",
      "p3": "Выберите «Микрофон», установите флажок рядом с приложением {{browserName}};",
      "p4": "Перезапустите {{browserName}} и попробуйте пройти интервью ещё раз.",
      "more": "Подробнее на сайте",
      "support": "поддержки Apple"
    },
    "NoSoundWindowsFirefox": {
      "sureConnected": "Если вы уверены, что ваш микрофон подключен, вероятнее всего доступ к нему запрещён в настройках приватности Windows.",
      "goToStart": "Перейдите Пуск",
      "parameters": "Параметры",
      "privacy": "Конфиденциальность",
      "mic": "Микрофон. Убедитесь, что:",
      "p1":" Доступ к микрофону для этого устройства включен;",
      "p2": "«Разрешить приложениям доступ к микрофону» находится в положении «Вкл.»;",
      "p3": "«Разрешить классическим приложениям доступ к микрофону» (если есть) находится в положении «Вкл.»;",
      "p4": "После внесения изменений в настройки обновите страницу.",
      "more": "Подробнее на сайте",
      "support": "поддержки Microsoft"
    },
    "NotAllowedAndroidChrome": {
      "check": "Не удалось получить доступ к камере и микрофону.",
      "p1": "Слева от адреса сайта нажмите на значок замка",
      "p2": "Нажмите на раздел Разрешения;",
      "p3": "Разрешите доступ к Камере и Микрофону;",
      "p4": "Перезагрузите страницу.",
      "i1": "Если вы не видите раздел Разрешения, значит доступ к камере и микрофону заблокирован на уровне операционной системы Android. В Настройках Android в разделе Приложения убедитесь, что для Chrome разрешён доступ к Камере и Микрофону. После этого вернитесь в Chrome и перезагрузите страницу. Подробнее на сайте",
      "more": "поддержки Android"
    },
    "NotAllowedBlink": {
      "check": "Вы не дали разрешение на доступ к камере или микрофону.",
      "p1": "Слева от адреса сайта нажмите на значок замка",
      "p2": "Разрешите доступ к камере и микрофону;",
      "p3": "Обновите страницу",
      "p3_check": "или нажмите сюда"
    },
    "NotAllowedBlinkWindowsSystem": {
      "check": "Невозможно получить доступ к микрофону/камере. Вероятнее всего доступ к микрофону запрещён в настройках конфиденциальности Windows 10.",
      "goToStart": "Перейдите Пуск",
      "parameters": "Параметры",
      "privacy": "Конфиденциальность",
      "cameraAndSure": "Перейдите в раздел Микрофон, а затем камера и убедитесь, что доступ к устройству не запрещён:",
      "p1": "Доступ к микрофону/камере для этого устройства включен;",
      "p2": "«Разрешить приложениям доступ к камере» / «Разрешить приложениям доступ к микрофону» находится в положении «Вкл.»;",
      "p3": "«Разрешить классическим приложениям доступ к камере» / «Разрешить классическим приложениям доступ к микрофону» (если есть) находится в положении «Вкл.»;",
      "p4": "После внесения изменений в настройки обновите страницу.",
      "more": "Подробнее на сайте",
      "support": "поддержки Microsoft"
    },
    "NotAllowedFirefox": {
      "check": "Вы не дали разрешение на доступ к камере или микрофону.",
      "p1": "Слева от адреса сайта нажмите на значок перечёркнутой камеры или микрофона;",
      "p2": "Сбросьте запрет на доступ к камере и микрофону;",
      "p3": "Обновите страницу;",
      "p4": "Нажмите Разрешить, когда браузер снова запросит доступ.",
      "more": "Если рекомендации не помогли, воспользуйтесь",
      "support": "документацией Firefox"
    },
    "NotAllowedMacOsSafari": {
      "check": "Вы не дали разрешение на доступ к камере или микрофону.",
      "p1": "Выберите меню «Safari» → «Настройки» и нажмите «Веб-сайты»;",
      "p2": "Выберите по очереди Камера и Микрофон и установите «Разрешить» для сайта huntica.works;",
      "p3": "Закройте настройки и перезагрузите страницу.",
      "more": "Подробнее на сайте",
      "support": "поддержки Apple"
    },
    "NotConnected": {
      "notConnected": "Похоже, что ваша камера не подключена. Пожалуйста подключите камеру."
    },
    "NotReadableErrorAudioWindows": {
      "check": "Невозможно получить доступ к микрофону. Вероятнее всего доступ к микрофону запрещён в настройках конфиденциальности Windows 10.",
      "goToStart": "Перейдите Пуск",
      "parameters": "Параметры",
      "privacy": "Конфиденциальность",
      "mic": "Микрофон. Убедитесь, что:",
      "p1": "Доступ к микрофону для этого устройства включен;",
      "p2": "«Разрешить приложениям доступ к микрофону» находится в положении «Вкл.»;",
      "p3": "«Разрешить классическим приложениям доступ к микрофону» (если есть) находится в положении «Вкл.»;",
      "p4": "После внесения изменений в настройки обновите страницу",
      "more": "Подробнее на сайте",
      "support": "поддержки Microsoft"
    },
    "NotReadableErrorVideoWindows": {
      "check": "Невозможно получить доступ к камере. Возможные причины:",
      "p1": "Камера сейчас используется другим приложением (например, Skype или Zoom). Обычно при этом на камере горит индикатор. Закройте все приложения, которые могут использовать камеру, после чего перезагрузите страницу.",
      "p2": "Если вы уверены, что камера сейчас не используется, значит доступ к ней запрещён в настройках конфиденциальности Windows 10.",
      "goToStart": "Перейдите Пуск",
      "parameters": "Параметры",
      "privacy": "Конфиденциальность",
      "cameraAndSure": "Камера и убедитесь, что:",
      "a": "Доступ к камере для этого устройства включен;",
      "b": "«Разрешить приложениям доступ к камере» находится в положении «Вкл.»;",
      "c": "«Разрешить классическим приложениям доступ к камере» (если есть) находится в положении «Вкл.»;",
      "d": "После внесения изменений в Настройки обновите страницу.",
      "more": "Подробнее на сайте",
      "support": "поддержки Microsoft"
    }
  },
  "rooms": {
    "rooms": "Комнаты"
  }
};