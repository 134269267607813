import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';

export function OnOffIcon(props: SvgIconProps) {
  return <SvgIcon {...props} colored >
    <path
      d='M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z'
      className="filled"
    />
    <g className="bgStroke">
      <path
        d='M10.5092 10.2C8.9642 11.6778 8 13.6775 8 16C8 20.4764 11.5817 24 16 24C20.4183 24 24 20.4764 24 16C24 13.6775 23.0358 11.6778 21.4908 10.2'
      />
      <path d='M16 8L16 14' />
    </g>
  </SvgIcon>;
}

