import { IProfile } from '@chessclub/grpc_wrapper';

export type IUserProfile =  {
  profile: IProfile;
  onChange;
}

export enum EnumSettingsTab {
  personalInfo = 'Профиль',
  security = 'Безопасность',
  theme = 'Оформление',
}

export type SettingsTab =
  EnumSettingsTab.personalInfo |
  EnumSettingsTab.security |
  EnumSettingsTab.theme;

