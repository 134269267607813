import Input from '../../../components/low-level-components/Input';
import React from 'react';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';

export function TaskEditNameInput() {

  const { updateEditingTaskData, editingTask} = useEditingTaskStore();

  return <Input
    autoFocus
    fullWidth
    label={'Наименование'}
    maxLength='256'
    value={editingTask.title}
    onChange={(e) => updateEditingTaskData({ title: e.target.value })}
  />
}