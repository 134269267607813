import { Lang, useUiStore } from '../store/uiStore';
import { L10n } from './L10n';

import { Lang_ru } from './Lang_ru';
import { Lang_en } from './Lang_en';
import { Lang_cn } from './Lang_cn';
import { Lang_hi } from './Lang_hi';

const langs = {
  [Lang.ru] : Lang_ru,
  [Lang.en] : Lang_en,
  [Lang.cn] : Lang_cn,
  [Lang.hi] : Lang_hi,
};

const loading = [];

function load(src: string) {
  if (loading.includes(src))
    return;
  loading.push(src);
  const script = document.createElement('script');
  script.src = src;
  // script.onload = () =>
  document.body.append(script)
}

// load('1.js');


export function useLocalization(): L10n {

  const { lang } = useUiStore();

  return langs[lang || Lang.ru];
}

export function t(s, obj) {
  Object.entries(obj).forEach(([key, value])=>{
    s = s.split(`{{${key}}}`).join(value);
  })
  return s;
}