export function diff<T extends { id? }>(a: T[], b: T[]): { added: T[]; removed: T[] } {
  const added = [];
  const removed = [];

  a.forEach((aEl) => {
    if (!b.find(bEl => aEl.id === bEl.id)) {
      removed.push(aEl);
    }
  })

  b.forEach(bEl => {
    if (!a.find(aEl => aEl.id === bEl.id)) {
      added.push(bEl);
    }
  })

  return {
    added,
    removed
  };
}