import React from 'react';
import { SvgImage } from '../../../../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../../../../components/Icon/KnownIcons';

export function CanMoveIndicator(props: { canMove: boolean }) {
  if (!props.canMove)
    return null;
  return <SvgImage
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
    }}
    r={4}
    size={24}
    icon={KnownIcons.pawn_with_arrows}
    active
  />;
}