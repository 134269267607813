import pawn from '../../../svg/figures/figure-pawn.svg'
import rook from '../../../svg/figures/figure-rook.svg'
import knight from '../../../svg/figures/figure-horse.svg'
import bishop from '../../../svg/figures/figure-bishop.svg'
import queen from '../../../svg/figures/figure-queen.svg'
import king from '../../../svg/figures/figure-king.svg'
import { FigureType } from './FigureType';

export const FiguresIconsBlack_LightTheme = {
  [FigureType.pawn]: pawn,
  [FigureType.rook]: rook,
  [FigureType.knight]: knight,
  [FigureType.bishop]: bishop,
  [FigureType.queen]: queen,
  [FigureType.king]: king,
}

