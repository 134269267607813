import { create } from 'zustand';
import { ITopic, TopicType, UserId } from '@chessclub/grpc_wrapper';
import { useAuthStore } from '../auth/AuthStore';
import { subscribeAtPageChange } from '../../helpers/subscribeAtPageChange';
import { indexeddbObject } from '../../helpers/indexeddbObject';
import { adminKnowledgePath, materialsPath } from '../../app/routesPaths';
import { EditingTopicStoreActions, EditingTopicStoreState } from './EditingTopicStoreApi';

type EditingTopicStore = EditingTopicStoreState & EditingTopicStoreActions;

const editingTopicDb = indexeddbObject<ITopic>("editing-topic");

export const useEditingTopicStore = create<EditingTopicStore>((
  set,
  get,
) => {

  const privateState = {userId: null};

  useAuthStore.subscribe(async (state, prev) => {
    if (prev.user !== state.user && state.user?.id) {
      await init();
    }
  });

  subscribeAtPageChange(async (url) => {
    if (url.includes(adminKnowledgePath) || url.includes(materialsPath))
      await init();
  });

  async function init() {
    const newUserId = useAuthStore.getState().user?.id;
    if (!newUserId)
      return;

    const topicType = document.location.pathname.includes(adminKnowledgePath) ?
      TopicType.KNOWLEDGE : TopicType.MATERIAL;

    const { type } = get();
    if (topicType === type && privateState.userId === newUserId)
      return;

    privateState.userId = newUserId;

    const editingTopic = await editingTopicDb.get(key(topicType));
    set({  type: topicType, editingTopic });
  }

  function key(type: TopicType) {
    return type + "_" + privateState.userId;
  }

  return {
    async updateEditingTopic(data: Partial<ITopic>) {
      const { editingTopic, type } = get();
      const newTopicData = { ...editingTopic, ...data };
      await editingTopicDb.put(key(type), newTopicData);
      set({ editingTopic: newTopicData });
    },

    async stopEditTopic() {
      const {  type } = get();
      await editingTopicDb.del(key(type));
      set({ editingTopic: null });
    },

    async startEditTopic(editingTopic: ITopic) {
      const { type } = get();
      await editingTopicDb.put(key( type), editingTopic);
      set({ editingTopic });
    },
  };
});

