import pawn from '../../../svg/figures/figure-pawn-dark.svg';
import rook from '../../../svg/figures/figure-rook-dark.svg';
import knight from '../../../svg/figures/figure-horse-dark.svg';
import bishop from '../../../svg/figures/figure-bishop-dark.svg';
import queen from '../../../svg/figures/figure-queen-dark.svg';
import king from '../../../svg/figures/figure-king-dark.svg';
import { FigureType } from './FigureType';

export const FiguresIconsBlack_DarkTheme = {
  [FigureType.pawn]: pawn,
  [FigureType.rook]: rook,
  [FigureType.knight]: knight,
  [FigureType.bishop]: bishop,
  [FigureType.queen]: queen,
  [FigureType.king]: king,
};

