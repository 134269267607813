import React from 'react';
import { FooterIcon } from './FooterIcon';

export function Social_Youtube() {
  return <FooterIcon color='red'>
    <path
      className='mainColor'
      d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z'
    />
    <path
      className='bgColor'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.2367 7.76561C18.4541 7.98733 18.6108 8.26328 18.691 8.56594C19.1535 10.4643 19.0466 13.4624 18.7 15.4341C18.6197 15.7367 18.4631 16.0127 18.2457 16.2344C18.0283 16.4561 17.7577 16.6159 17.461 16.6978C16.3746 17 12.0019 17 12.0019 17C12.0019 17 7.62926 17 6.54284 16.6978C6.24608 16.6159 5.97552 16.4561 5.75813 16.2344C5.54074 16.0127 5.38408 15.7367 5.30379 15.4341C4.83866 13.5439 4.96618 10.5439 5.2948 8.57511C5.37509 8.27239 5.53175 7.99644 5.74914 7.77472C5.96654 7.553 6.23709 7.39328 6.53385 7.31133C7.62027 7.00917 11.9929 7 11.9929 7C11.9929 7 16.3656 7 17.452 7.30222C17.7488 7.38411 18.0193 7.54389 18.2367 7.76561ZM14.2286 12L10.6012 14.1428V9.85711L14.2286 12Z'
    />
  </FooterIcon>;
}