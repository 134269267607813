import React, { MutableRefObject, RefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useAuthStore } from '../../store/auth/AuthStore';
import { KnownIcons } from '../../components/Icon/KnownIcons';

import { cos, HALF_PI, sin, TAU } from '@chessclub/web-game-server/src/shared/util/math';
import { ToolbarButton } from '../../components/ContentContainer/ToolbarButton';
import { Stack } from '../../components/low-level-components/Stack';
import { SvgImage } from '../../components/Icon/SvgImage';
import { LoadingIndicator } from '../../components/low-level-components/LoadingIndicator';

const StyledEditAvatarButtons = styled.div`
  margin-top: 15px;
  display: flex;

`;

const StyledImgWrapper = styled(Stack)`
  width: 345px;
  height: 345px;
  box-shadow: 2px 2px 9px #8888;
  border-radius: 345px;
  overflow: hidden;

  & > div, & > svg {
    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.3s;
    background: radial-gradient(#0009, #fff0);
    border-radius: 345px;
    cursor: pointer;
  }

  & > div.upload {
    opacity: 0;
  }

  &:hover div {
    opacity: 1;
  }
`;

interface IImageUploaderProps {
  imgExtractFnRef: MutableRefObject<() => string>;
  profileImage: string;
  uploading: boolean;
}

interface UploaderState {
  img?: HTMLImageElement;
  scale: number;
  rotation: number;
  mirrorX: number;
  center: {
    x: number;
    y: number;
  };
}

const defaultState = {
  center: { x: 0, y: 0 },
  mirrorX: 1,
  rotation: 0,
  scale: 1,
};

const size = 345;

export function PfpEditor(props: IImageUploaderProps) {

  const canvasRef = useRef<HTMLCanvasElement>();
  const ctxRef = useRef<CanvasRenderingContext2D>();
  const dragRef = useRef<{ mouse; center; }>();

  const [image, setImage] = useState<string>();
  const [state, setState] = useState<UploaderState>({ ...defaultState });

  useEffect(() => {
    if (!image)
      return;
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => setState({ ...defaultState, img });
    img.src = image;
  }, [image]);

  useEffect(() => {
    props.imgExtractFnRef.current = () => {
      if (!image)
        return null;
      paintImg(ctxRef.current, 1);
      const dataURL = canvasRef.current.toDataURL('image/png');
      draw();
      cancel();
      return dataURL;
    };
  }, [image]);

  useEffect(draw, [state]);

  function updateState(data: Partial<UploaderState>) {
    setState({ ...state, ...data });
  }

  function pointerMove(e) {
    const { scale, mirrorX, rotation } = state;
    let dx = e.clientX - dragRef.current.mouse.x;
    let dy = e.clientY - dragRef.current.mouse.y;
    dx /= scale * mirrorX;
    dy /= scale;
    const x = dragRef.current.center.x + cos(rotation) * dx + sin(rotation) * dy;
    const y = dragRef.current.center.y - sin(rotation) * dx + cos(rotation) * dy;
    updateState({ center: { x, y } });
  }

  function pointerUp(e) {
    window.removeEventListener('pointermove', pointerMove);
    window.removeEventListener('pointerup', pointerUp);
    dragRef.current = null;
  }

  function pointerDown(e) {
    window.addEventListener('pointermove', pointerMove);
    window.addEventListener('pointerup', pointerUp);
    dragRef.current = { mouse: { x: e.clientX, y: e.clientY }, center: { ...state.center } };
  }

  function paintImg(ctx: CanvasRenderingContext2D, alpha = 1) {
    const { scale, mirrorX, img, center, rotation } = state;
    if (!img) {
      return;
    }
    ctx.save();
    ctx.globalAlpha = alpha;
    ctx.scale(mirrorX * scale, scale);
    ctx.rotate(rotation);
    ctx.translate(center.x, center.y);
    ctx.drawImage(img, -img.width / 2, -img.height / 2);
    ctx.restore();
  }

  function draw() {

    if (!canvasRef.current || !image)
      return;

    if (!ctxRef.current) {
      ctxRef.current = canvasRef.current.getContext('2d');
      ctxRef.current.translate(size / 2, size / 2);
    }

    const ctx = ctxRef.current;
    ctx.clearRect(-size / 2, -size / 2, size, size);

    paintImg(ctx, 0.2);

    ctx.save();

    ctx.beginPath();
    ctx.arc(0, 0, size / 2, 0, Math.PI * 2, true);
    ctx.clip();

    paintImg(ctx, 1);

    ctx.lineWidth = .5;
    ctx.strokeStyle = '#888';
    ctx.beginPath();
    ctx.arc(0, 0, size / 2 - 1, 0, Math.PI * 2, true);
    ctx.stroke();

    ctx.restore();
  }

  function selectImage() {
    let input = document.createElement('input');
    input.accept = 'image/png, image/gif, image/jpeg';
    input.type = 'file';
    input.click();
    input.onchange = () => {
      const fr = new FileReader();
      fr.onload = () => {
        setImage(fr.result as string);
      };
      fr.readAsDataURL(input.files[0]);
    };
  }

  function cancel() {
    setState({ ...defaultState });
    ctxRef.current = null;
    setImage(null);
  }

  return <div>

    <div style={{ width: size, display: 'flex', flexDirection: 'column', padding: '50px 50px 50px 0' }}>
      {image ? <>

        <canvas
          onMouseDown={pointerDown}
          width={size}
          height={size}
          ref={canvasRef}
        />

        <input
          style={{ width: '100%' }}
          value={state.scale}
          min={.3}
          max={3}
          step={0.01}
          type='range'
          onInput={e => updateState({ scale: (e.target as any).value })}
        />

        <StyledEditAvatarButtons>

          <ToolbarButton
            disabled={!state.img}
            icon={KnownIcons.reverse}
            active={!!state.rotation}
            text={`Повернуть`}
            onClick={() => updateState({ rotation: (state.rotation + HALF_PI) % TAU })}
          />

          <ToolbarButton
            disabled={!state.img}
            icon={KnownIcons.flip_vertical}
            active={state.mirrorX === -1}
            text={`Отразить`}
            onClick={() => updateState({ mirrorX: -state.mirrorX })}
          />

          <ToolbarButton
            disabled={!state.img}
            icon={KnownIcons.cross}
            text={`Отмена`}
            onClick={cancel}
          />

        </StyledEditAvatarButtons>

      </> : <StyledImgWrapper>
        <img alt='' src={props.profileImage} width={size} height={size} />
        {props.uploading ? <div>
          <LoadingIndicator size={100} />
        </div> : <div onClick={selectImage} className={'upload'}>
          <SvgImage icon={KnownIcons.folder} size={100} />
        </div>}
      </StyledImgWrapper>
      }

    </div>


  </div>;
}
