import { useUiStore } from '../store/uiStore';
import { useTheme } from 'styled-components';

export function useThemeColors() {
  const { interfaceClr } = useUiStore();
  const { currentTheme } = useTheme();
  return {
    accent: currentTheme[interfaceClr],
    red: currentTheme.error1,
    icon: currentTheme.iconMain
  };
}