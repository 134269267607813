import styled from 'styled-components';
import { IUser } from '@chessclub/grpc_wrapper';
import { TextNode } from '../../../components/low-level-components/TextNode';
import { SvgImage } from '../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../components/Icon/KnownIcons';
import React from 'react';
import { Stack } from '../../../components/low-level-components/Stack';
import { useUserListStore, UserListMode } from './UserListStore';

const User = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 32px;
  gap: 20px;
  align-items: center;
`;

const Avatar = styled.img`
  height: 50px;
  border-radius: 50%;
`;

export function UserInList(props: { user: IUser; }) {
  const {removed, doAdd, doDelete, mode} = useUserListStore();

  const isRemoved = removed.find(u => props.user.id === u.id);
  const isAddMode = mode === UserListMode.ADD;

  let text = props.user.displayName;
  if (props.user.userName) {
    text += ` (${props.user.userName})`;
  }

  const icon = isAddMode || isRemoved ? KnownIcons.plus : KnownIcons.trash;

  function click() {
    if (isAddMode || isRemoved) {
      doAdd(props.user);
    } else {
      doDelete(props.user)
    }
  }

  return <User>
    <Avatar src={props.user.imageUrl} alt='img' />
    <TextNode style={{textDecoration: isRemoved ? 'line-through' : 'none'}}>{text}</TextNode>
    <SvgImage icon={icon} withHover onClick={click} />
  </User>;
}

