

export function subscribeAtPageChange(callback: (url: string) => void) {

  let url;

  function onChange () {
    const pathname = document.location.pathname;
    if (url === pathname)
      return
    url = pathname;
    url && url !== "none" && callback(url);
  }

  window.addEventListener("url", onChange)

  onChange()
}



