import { FiguresIconsWhite_DarkTheme } from './FiguresIconsWhite_DarkTheme';
import { FiguresIconsWhite_LightTheme } from './FiguresIconsWhite_LightTheme';
import { FiguresIconsBlack_DarkTheme } from './FiguresIconsBlack_DarkTheme';
import { FiguresIconsBlack_LightTheme } from './FiguresIconsBlack_LightTheme';

export function selectFiguresIcons(isWhiteFigures?: boolean, isDarkTheme?: boolean) {
  const iconsWhite = isDarkTheme ? FiguresIconsWhite_DarkTheme : FiguresIconsWhite_LightTheme;
  const iconsBlack = isDarkTheme ? FiguresIconsBlack_DarkTheme : FiguresIconsBlack_LightTheme;
  const icons = isWhiteFigures ? iconsWhite : iconsBlack;
  return icons;
}