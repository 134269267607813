import { workerGameServer } from '@chessclub/web-game-server/src/worker/workerGameServer';
import { stockfishWorker } from '@chessclub/web-game-server/src/stockfish/stockfishWorker';
import { ITask, TaskId, UserId } from '@chessclub/grpc_wrapper';

export const stockfish = stockfishWorker('/stockfish.js');

export const wgs = workerGameServer('/wgs.__BUILD_TIMESTAMP__.js', stockfish);

export function wgsStartTask(task: ITask, roomOwnerId: UserId, boardOwnerId: UserId) {
  wgs.startTask({
    roomId: task.roomId,
    taskId: task.id,
    isActive: true,
    roomOwnerId,
    boardOwnerId,
    fen: task.fen,
    moves: task.moves || [],
    tools: task.tools || {},
    customData: task.customData,
    taskType: task.type,
    moveIndex: task.moveIndex
  })
}

// wgs.onMessage(message => {
//   console.log(message.data)
// })

export function wgsStopTask(taskId: TaskId) {
  wgs.stopTask(taskId)
}