import { ChessboardStoreActions, ChessboardStoreState } from './ChessboardStoreApi';
import { create } from 'zustand';
import { localStorageObject } from '../../helpers/localStorageObject';
import { PiecesColor } from '../lesson-ui/LessonUiStoreApi';
import { InteractivityMode } from '@chessclub/web-game-server';
import { audio } from '@chessclub/web-game-server/src/client/audio/audio';

export type ChessboardStore = ChessboardStoreActions & ChessboardStoreState;

const savedPiecesColor = localStorageObject<PiecesColor>('pieces-color', PiecesColor.WHITE);
const savedMarkerHue = localStorageObject<string>('marker-hue', 'f');
const savedInteractivityMode = localStorageObject<InteractivityMode>('interactivity-mode',
  InteractivityMode.DRAG);

export const useChessboardStore = create<ChessboardStore>((
  set,
  get,
) => {


  audio.setVolume(0.3)

  window.addEventListener('keydown', (e) => {
    if (e.key !== 'Shift')
      return;
    const markerHue = ((parseInt(get().markerHue, 16) + 1) % 15).toString(16);
    set({ markerHue });
  });

  return {
    markerHue: savedMarkerHue.restore(),
    piecesColor: savedPiecesColor.restore(),
    interactivityMode: savedInteractivityMode.restore(),

    setInteractivityMode(interactivityMode: InteractivityMode) {
      set({ interactivityMode });
      savedInteractivityMode.save(interactivityMode);
    },

    setMarkerHue(markerHue: string) {
      set({ markerHue });
      savedMarkerHue.save(markerHue);
    },

    togglePiecesColor() {
      const piecesColor = get().piecesColor === PiecesColor.BLACK ? PiecesColor.WHITE : PiecesColor.BLACK;
      savedPiecesColor.save(piecesColor);
      set({ piecesColor });
    },
  };
});