import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { ContentContainer } from '../../components/ContentContainer';
import React, { PropsWithChildren, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export function AdminDashboardSubpage(props: PropsWithChildren<{subtitle: string; toolbar?: ReactNode;}>) {

  const title = <RouterLink to={'/admin'} style={{
    textDecoration: "none",
    color: "var(--color-text-main)"}
  }>Панель администратора</RouterLink>;

  return <PageTemplate>
    <ContentContainer
      title={title}
      subtitle={props.subtitle}
      toolbar={props.toolbar}
    >
      {props.children}
    </ContentContainer>
  </PageTemplate>
}