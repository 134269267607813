import React from "react"
import { useTaskToDisplay } from '../../../../hooks/useTaskToDisplay';
import { useLessonStore } from '../../../../store/lesson/LessonStore';
import { KnownIcons } from '../../../../components/Icon/KnownIcons';
import { SvgImage } from '../../../../components/Icon/SvgImage';
import { DemoIcon } from '../../../../components/Icons';

export function DemonstrationButton() {
  const { taskToDisplay } = useTaskToDisplay();

  const {
    demonstrationState,
    demonstrateButtonClicked,
  } = useLessonStore();

  const isDemonstrationOn = !!demonstrationState;

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    demonstrateButtonClicked(taskToDisplay);
  };
  return <div onClick={onClick}>
    {isDemonstrationOn && <DemoIcon/>}
    {!isDemonstrationOn && <SvgImage
      withHover
      disabled={!taskToDisplay}
      filled={isDemonstrationOn}
      icon={KnownIcons.arrow_up_in_rect}
    />}
  </div>
}