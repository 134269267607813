import { ChessboardSvg } from '../../../../components/Chessboard/ChessboardSvg';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Stack } from '../../../../components/low-level-components/Stack';
import { useThemeColors } from '../../../../hooks/useThemeColors';

const StyledWrapper = styled.div<{$borderColor}>`
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  border: 2px solid ${p => p.$borderColor};
  padding: 4px;
  transition: 0.3s;
`;

const StyledFenButton = styled(Stack)`
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;

export function FenButtonRaw(props: PropsWithChildren<{ onClick; fen; active?; borderColor?;}>) {
  const {accent} = useThemeColors();
  return (
    <StyledWrapper $borderColor={props.active ? (props.borderColor || accent) : '#fff0'}>
      <StyledFenButton onClick={props.onClick}>
        <ChessboardSvg fen={props.fen} markers={[]}/>
        {props.children}
      </StyledFenButton>
    </StyledWrapper>
  );
}