import moment from 'moment';
import 'moment/locale/ru';

export const getMessageDate = (epoch: number) => {
  const date = new Date(epoch * 1000);
  return `${date.getHours()}:${date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`}`;
};

export const getDayAndMonth = (date: number | Date) => {
  moment.locale('ru');
  const format = moment(date).format('DD MMMM');
  if (+format.slice(0, 2) < 10) {
    return format.slice(1);
  }
  return format;
};
