import React from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';
import { Theme, useThemeStore } from '../../store/themeStore';

interface IProps {
  checked: boolean;
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledSwitch = styled(Switch)<{ checked: boolean }>`
  ${({ checked, theme }) =>
    !checked &&
    `.react-switch-bg { outline: 1px solid ${theme.currentTheme.switchOffBorderColor}; background: ${theme.currentTheme.background} !important } .react-switch-handle { background: ${theme.currentTheme.switchOffBorderColor} !important }`}
`;

function CustomSwitch(props: IProps) {
  let { checked, onChange } = props;
  const { theme } = useThemeStore();
  return (
    <StyledSwitch
      checked={checked}
      activeBoxShadow={`0 0 0px #000`}
      handleDiameter={22}
      onColor={`${theme === Theme.dark ? '#007AF3' : '#007AF3'}`}
      offColor={`${theme === Theme.dark ? '#18191E' : '#D2D2D2'}`}
      onHandleColor={`${theme === Theme.dark ? '#18191E' : '#fff'}`}
      checkedIcon={false}
      uncheckedIcon={false}
      onChange={onChange}
    />
  );
}

export default CustomSwitch;
