import {create} from 'zustand';
import {Room, RoomSession} from "@chessclub/video-conf";
import { ChessclubUser} from "../interfaces/user";
import {useLessonStore} from "./lesson/LessonStore";
import { Role, UserId } from '@chessclub/grpc_wrapper';

export interface ConferenceStoreInitialState {
    streamSessions: RoomSession[];
    updatedSessions: RoomSession[];
    room: Room;
    videoRoomUsers: string[];
    stream?: MediaStream;
    streamRoom?: Room;
    myVideo?: boolean;
    myAudio?: boolean;
    selectedCameras: ChessclubUser[];
    video: {[ key: UserId]: HTMLVideoElement}
}

export interface ConferenceStoreActions {
    getVideoTag(userId: UserId): HTMLVideoElement;
    releaseVideoTag(videoElement:HTMLVideoElement);

    addUpdatedSessions(action): void;
    setRoom(room: Room): void;
    setMyVideo(myVideo: boolean): void;
    setMyAudio(myAudio: boolean): void;
    setStream(stream: MediaStream): void;
    setStreamRoom(streamRoom: Room): void;
    setUserVideoStream(data: { videoStream: MediaStream; socketId: string }): void;
    setVideoRoomUsers(videoRoomUsers: string[]): void;
    setStreamSessions(streamSessions: ConferenceStoreInitialState['streamSessions']): void;
    addStreamSession(roomSession: RoomSession): void;
    toggleCameraEnabledBySocketId(data: { socketId: string; enabled: boolean }): void;
    toggleMicroEnabledBySocketId(data: { socketId: string; enabled: boolean }): void;
    findRoomOwnerCameraUser():  ChessclubUser;
    getLastAudioStateKeyByRoom(roomId: string): string;
    getLastVideoStateKeyByRoom(roomId: string): string;
    setLastAudioState(roomId: string, state: boolean): void;
    setLastVideoState(roomId: string, state: boolean): void;
    getLastAudioState(roomId: string): boolean;
    getLastVideoState(roomId: string): boolean;
    setSelectedCameras(selectedCameras: ConferenceStoreInitialState['selectedCameras']): void;
}

export type ConferenceStore = ConferenceStoreInitialState & ConferenceStoreActions

const initialState: ConferenceStoreInitialState = {
    room: {} as Room,
    streamSessions: [],
    updatedSessions: [],
    videoRoomUsers: [],
    selectedCameras: [],
    video: {}
};

export const useConferenceStore = create<ConferenceStore>((set, get) => {



    return {
        ...initialState,
        getVideoTag(userId: UserId): HTMLVideoElement {
            let videoElement = get().video[userId];
            if (!videoElement) {
                videoElement = document.createElement("video")
                videoElement.setAttribute("autoPlay", "true");
                videoElement.setAttribute("width", "100%");
                // videoElement.setAttribute("poster", "")
                get().video[userId] = videoElement;
            }
            videoElement.parentNode?.removeChild(videoElement);
            return videoElement;
        },
        releaseVideoTag(videoElement:HTMLVideoElement) {
            document.querySelector("#video").append(videoElement)
        },
        addUpdatedSessions: (roomSession) => {
            if (get().updatedSessions.find((s) => s.user === roomSession.user)) {
                set({
                    updatedSessions: get().updatedSessions.map((s) => {
                        if (s?.user === roomSession.user) return { ...roomSession };
                        return s;
                    }),
                });
            } else {
                set({ updatedSessions: [...get().updatedSessions, roomSession] });
            }
        },
        setRoom: (room: Room) => {
            set({ room });
        },
        setMyVideo: (myVideo: boolean) => {
            set({ myVideo });
        },
        setMyAudio: (myAudio: boolean) => {
            set({ myAudio });
        },
        setStream: (stream: MediaStream) => {
            set({ stream });
        },
        setStreamRoom: (streamRoom: Room) => {
            set({ streamRoom });
        },
        setUserVideoStream: (data: { videoStream: MediaStream; socketId: string }) => {
            const { socketId, videoStream } = data;
            const { users, setUsers } = useLessonStore.getState();
            setUsers(
                users.map((user) => {
                    if (user.socketId === socketId) user.videoStream = videoStream;
                    return user;
                }),
            );
        },
        setVideoRoomUsers: (videoRoomUsers: string[]) => {
            set({ videoRoomUsers });
        },
        setStreamSessions: (streamSessions: ConferenceStoreInitialState['streamSessions']) => {
            set({ streamSessions });
        },
        addStreamSession: (roomSession: RoomSession) => {
            set({
                streamSessions: [...get().streamSessions, roomSession],
            });
        },
        toggleCameraEnabledBySocketId: (data: { socketId: string; enabled: boolean }) => {
            // const { socketId, enabled } = data;
            // const { users, setUsers } = useLessonStore.getState();
            // setUsers(
            //     users.map((user) => {
            //         if (user.socketId !== socketId)
            //             return user;
            //         user.cameraEnabled = enabled
            //         return user;
            //     }),
            // );
        },
        toggleMicroEnabledBySocketId: (data: { socketId: string; enabled: boolean }) => {
            // const { socketId, enabled } = data;
            // const { users, setUsers } = useLessonStore.getState();
            // setUsers(
            //     users.map((user) => {
            //         user.socketId === socketId && (user.microEnabled = enabled);
            //         return user;
            //     }),
            // );
        },
        findRoomOwnerCameraUser: () => {
            const { users, teacherId } = useLessonStore.getState();
            return users.find(user => user.id === teacherId);
        },
        getLastAudioStateKeyByRoom: (roomId: string) => {
            return `audio_state_${roomId}`;
        },
        getLastVideoStateKeyByRoom: (roomId: string) => {
            return `video_state_${roomId}`;
        },
        setLastAudioState: (roomId: string, state: boolean) => {
            localStorage.setItem(get().getLastAudioStateKeyByRoom(roomId), state ? 'enabled' : 'disabled');
        },
        setLastVideoState: (roomId: string, state: boolean) => {
            localStorage.setItem(get().getLastVideoStateKeyByRoom(roomId), state ? 'enabled' : 'disabled');
        },
        getLastAudioState: (roomId: string) => {
            return 'enabled' === localStorage.getItem(get().getLastAudioStateKeyByRoom(roomId));
        },
        getLastVideoState: (roomId: string) => {
            return 'enabled' === localStorage.getItem(get().getLastVideoStateKeyByRoom(roomId));
        },
        setSelectedCameras: (selectedCameras: ConferenceStoreInitialState['selectedCameras']) => {
            set({ selectedCameras });
        },
    } as ConferenceStore;
});
