import React from 'react';
import { ChessboardIcon } from '../../Icons';
import { useLocalization } from '../../../l10n/UseLocalization';
import { PopupMenuItem } from '../../PopupMenu/PopupMenuItem';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import { BoardLabels } from '@chessclub/grpc_wrapper';

export function ChessboardCellsTitleDropdown() {
  const { lesson } = useLocalization();

  return <>
    <Item type={BoardLabels.OUTSIDE} text={lesson.titlesAroundChessboard}/>
    <Item type={BoardLabels.CENTER} text={lesson.titlesInEveryCell}/>
    <Item type={BoardLabels.INSIDE} text={lesson.titlesAtCellCorners}/>
    <Item type={BoardLabels.NONE} text={lesson.withoutTitles}/>
  </>
}

function Item({type, text}: {type: BoardLabels; text}) {
  const {updateEditingTaskData, editingTask} = useEditingTaskStore();
  const icon = <ChessboardIcon title={type} size={24} style={{paddingRight:5, paddingLeft:5}}/> as any;
  return <PopupMenuItem text={text} icon={icon} onClick={() => {
    updateEditingTaskData({ customData: { ...editingTask.customData, boardLabels: type } }, true);
  }}/>
}