import { Theme, useThemeStore } from '../../../../../../../store/themeStore';
import React from 'react';

export function CameraCardBgLine() {
  const {theme} = useThemeStore()
  const background = theme === Theme.dark ?  "#333333" : '#ffffff'
  return <div
    style={{display: 'flex', flexDirection:'column-reverse'}}>
    <div className="hidden" style={{height: 32, width: "100%", background, opacity:.8}}/>
  </div>
}