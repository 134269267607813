import React from 'react';
import styled from 'styled-components';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { BoardMove } from '@chessclub/grpc_wrapper';
import { HidingScrollbar } from '../low-level-components/HidingScrollbar';
import { PgnRecordWidget } from './PgnRecordWidget';
import { FullMoveDescription } from '@chessclub/web-game-server/src/shared/core/calcMoves';



export function MovesText({ moves }: { moves?: FullMoveDescription[]}) {

  let pairs : FullMoveDescription[][]= [];
  if (moves) {
    for (let i = 0; i < moves.length; i += 2) {
      pairs.push(moves.slice(i, i + 2));
    }
  }

  return <div contentEditable style={{font: "var(--font-normal)"}}>
    {moves.map((m, i) => {
      return (i % 2 ? "" :((1+ i/2)|0) + ".") + (m.pgn || m.move);
    }).join(" ")}
  </div>;
}


