import React, { CSSProperties } from 'react';
import styled from 'styled-components';

function Text(props:{text:string; style?: CSSProperties}) {
  return <div {...props} dangerouslySetInnerHTML={{__html: props.text}}/>
}

type CardParams = {
  icon: string;
  text: string
};

function Card(props: CardParams) {
  return <div {...props}>
    <img src={props.icon} alt="image"/>
    <Text text={props.text}/>
  </div>
}
const Title = styled(Text)`
  font-family: Cormorant,serif;
  font-weight: bold;
  font-size: 48px;
  color: ${p => p.theme.currentTheme.textMain};
  margin-bottom: 70px ;
`;

const MainTitle = styled(Text)`
  font-family: Cormorant,serif;
  font-weight: bolder;
  font-size: 85px;
  color: ${p => p.theme.currentTheme.textMain};
`;

const MainSubTitle = styled(Text)`
  font-family: Roboto,serif;
  font-size: 20px;
  color: ${p => p.theme.currentTheme.textMain};
`;

const CardCapabilities = styled(Card)`
  display: flex;
  background-color: #CD9D7620;
  width: 300px;
  height: 150px;
  align-items: center;
  & img {
    padding: 15.5px;
    width: 24px;
  }
  & div {
    padding: 0 20px 0 4px;
    font-family: Roboto,serif;
    font-size: 18px;
    color: ${p => p.theme.currentTheme.textMain};
  }
`;

const CardOurPlatform = styled(Card)`
  background-color: #CD9D7620;
  width: 210px;
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & img {
    padding: 15px;
    width: 64px;
  }
  & div {
    font-family: Roboto,serif;
    font-size: 18px;
    text-align: center;
    padding: 20px 10px;
    color: ${p => p.theme.currentTheme.textMain};
  }
`;

const CardAdvantages = styled(Card)`
  background-color: #CD9D7620;
  width: 380px;
  height: 124px;
  display: flex;
  align-items: center;
  & img {
    padding: 15px;
    width: 64px;
  }
  & div {
    font-family: Roboto,serif;
    font-size: 18px;
    text-align: center;
    padding: 20px 10px;
    color: ${p => p.theme.currentTheme.textMain};
  }
`;

const CardExpert = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  background-color: #CD9D7620;
  width: 279px;
  height: 699px;
  & img {
    width: 230px;
  }
  & div {
    padding: 36px 10px 0px 10px;
    font-family: Roboto,serif;
    font-size: 18px;
    text-align: center;
    color: ${p => p.theme.currentTheme.textMain};
  }
`;

type Justify = 'center' | 'left' | 'right';

const Section = styled.div<{justify?:Justify}>`
  gap: 40px;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: ${props => props.justify};
`;



function CardList(props: {
  justify: Justify;
  elements: [string,string][];
  card: React.ComponentType<CardParams>
}) {
  return <Section justify={props.justify}>
    {props.elements.map(([ico, text], i) => {
      return <props.card key={i} icon={ico} text={text}/>;
    })}
  </Section>
}

export const LandingPageStyledComponents = {
  Section, Title, MainSubTitle, MainTitle,  CardCapabilities,
  CardOurPlatform, CardAdvantages, CardExpert, CardList
}