import React from 'react';
import TextEditor from '../../../components/TextEditor/TextEditor';
import { useTextEditor } from '../../../hooks/useTextEditor';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';

export function TaskEditDescription() {
  const { editingTask, updateEditingTaskData } = useEditingTaskStore();

  function onUpdate(str) {
    editingTask && updateEditingTaskData({
      description: str,
    });
  }

  const { editor, focus, handleBlur, handleFocus, readFileAsDataURL, handlePointerUp } =
    useTextEditor('Описание', editingTask.description, onUpdate);

  return <TextEditor
    editor={editor}
    focus={focus}
    handleBlur={handleBlur}
    handleFocus={handleFocus}
    readFileAsDataURL={readFileAsDataURL}
    handlePointerUp={handlePointerUp}
    withMenu={true}
  />;
}
