import React, { useState } from 'react';
import { IQuestion } from '../../../../../interfaces/question';
import { useTasksStore } from '../../../../../store/tasks/TasksStore';
import { SvgImage } from '../../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../../components/Icon/KnownIcons';
import styled from 'styled-components';
import { SvgChessboard } from '@chessclub/web-game-server';
import { boardSettings } from '../../../../../components/Chessboard/boardSettings';
import { ChessboardSvg } from '../../../../../components/Chessboard/ChessboardSvg';


const StyledMiniChessboard = styled.div`
  position: relative;
  width: 149px;
  
  div {
    opacity: 0;
    transition: 0.2s;
  }
  svg {
    transition: 0.2s;
  }
  
  :hover {
    svg {
      opacity: 0.5;
      transition: 0.2s;
    }
    div {
      opacity: 1;
    }
  }
`;

// превьюшка для досок, выбираемых чекбоксами в вопросах в комнате
export function MiniChessboard({ q }:{ q: IQuestion; }) {

  // const [isHovered, setIsHovered] = useState<boolean>(false);
  const {updateSelection} = useTasksStore()

  return (
    <StyledMiniChessboard>
        <SvgImage
          withHover
          style={{
            position: 'absolute',
            right: '4px',
            top: '4px',
            zIndex: 1,
          }}
          onClick={() => updateSelection(q.id)}
          icon={KnownIcons.cross}
        />
      <ChessboardSvg fen={q.fen}/>
    </StyledMiniChessboard>
  );
}

