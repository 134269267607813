import React, { FC } from 'react';

interface IProps {
  fill?: string;
}

export const EditorColor: FC<IProps> = ({ fill }) => {
  return (
    <svg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 13C0 12.4477 0.447715 12 1 12H17C17.5523 12 18 12.4477 18 13V14C18 14.5523 17.5523 15 17 15H1C0.447716 15 0 14.5523 0 14V13Z'
        fill={fill ? fill : `#939393`}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.69998 10H4.89999C4.82499 10.0015 4.75062 9.98615 4.68238 9.95502C4.61414 9.9239 4.55377 9.87782 4.50575 9.82019C4.45773 9.76257 4.42329 9.69489 4.40499 9.62215C4.38668 9.54941 4.38497 9.47349 4.39999 9.4L7.09999 0.4C7.19999 0.1 7.39999 0 7.59999 0H10.4C10.6 0 10.8 0.1 10.9 0.4L13.6 9.4C13.615 9.47349 13.6133 9.54941 13.595 9.62215C13.5767 9.69489 13.5422 9.76257 13.4942 9.82019C13.4462 9.87782 13.3858 9.9239 13.3176 9.95502C13.2493 9.98615 13.175 10.0015 13.1 10H12.3C12.2007 9.98151 12.1094 9.9334 12.038 9.86201C11.9666 9.79061 11.9185 9.69927 11.9 9.6L11.2 7.4C11.2 7.1 10.9 7 10.7 7H7.29999C7.09999 7 6.89999 7.1 6.79999 7.4L6.09999 9.6C6.09999 9.9 5.89998 10 5.69998 10ZM8.29999 2.4L7.69998 4.4C7.68497 4.47349 7.68668 4.54941 7.70499 4.62215C7.72329 4.69489 7.75773 4.76257 7.80575 4.82019C7.85377 4.87782 7.91414 4.9239 7.98238 4.95502C8.05062 4.98615 8.12499 5.00152 8.19998 5H9.79999C9.87498 5.00152 9.94935 4.98615 10.0176 4.95502C10.0858 4.9239 10.1462 4.87782 10.1942 4.82019C10.2422 4.76257 10.2767 4.69489 10.295 4.62215C10.3133 4.54941 10.315 4.47349 10.3 4.4L9.69999 2.4C9.69999 2.1 9.39998 2 9.19999 2H8.79999C8.59999 2 8.39999 2.1 8.29999 2.4Z'
        fill={fill ? fill : `#939393`}
      />
    </svg>
  );
};
