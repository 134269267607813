import { LayoutSlots, LessonTabs, TabsLayout } from './LessonUiStoreApi';

export function createDefaultLessonLayout() : TabsLayout {
  return {
    visible: {
      [LessonTabs.CHAT]: false,
      [LessonTabs.CAMS]: true,
      [LessonTabs.TASKS]: true,
      [LessonTabs.MOVES]: false,
    },
    tabs: {
      [LessonTabs.CHAT]: LayoutSlots.TOP_RIGHT,
      [LessonTabs.CAMS]: LayoutSlots.TOP_RIGHT,
      [LessonTabs.TASKS]: LayoutSlots.TOP_LEFT,
      [LessonTabs.MOVES]: LayoutSlots.TOP_LEFT,
    },
    slots: {
      [LayoutSlots.TOP_LEFT]: [LessonTabs.TASKS, LessonTabs.MOVES],
      [LayoutSlots.TOP_RIGHT]: [LessonTabs.CAMS, LessonTabs.CHAT],
      [LayoutSlots.BOTTOM_LEFT]: [],
      [LayoutSlots.BOTTOM_RIGHT]: [],
    },
  };
}