import React, { CSSProperties, PropsWithChildren } from 'react';
import styled, { useTheme } from 'styled-components';
import { useUiStore } from '../../store/uiStore';

const StyledIcon = styled.svg<{
  hoverColor: string;
  color: string
  bgColor: string;
}>`
  cursor: pointer;
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  --icon-color: ${props => props.color};
  --bg-color: ${props => props.bgColor};
  stroke: var(--icon-color);
  transition: 0.2s;

  & .bgStroke {
    stroke: var(--bg-color);
  }

  & .bgFill {
    fill: var(--bg-color);
  }
  
  & .filled {
    fill: var(--icon-color);
  }
  
  &:hover {
    --icon-color: ${props => props.hoverColor}
  }
  
`;

export type SvgIconProps = {
  onClick?: (e) => void;
  className?: string;
  colored?: boolean;
  withHover?: boolean;
  size?: number;
  style?: CSSProperties;
};

export function SvgIcon(props: PropsWithChildren<SvgIconProps>) {
  const { currentTheme } = useTheme();
  const { interfaceClr } = useUiStore();
  const s = props.size || 32;
  return <StyledIcon
    color={props.colored ? currentTheme[interfaceClr] : "#868686"}
    hoverColor={props.withHover ? currentTheme.textMain : null}
    bgColor={currentTheme.background}
    className={props.className}
    onClick={props.onClick}
    style={props.style}
    width={s}
    height={s}
    viewBox={`0 0 ${s} ${s}`}
    xmlns='http://www.w3.org/2000/svg'
  >
    {props.children}
  </StyledIcon>;
}

