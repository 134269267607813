import React from 'react';
import { adminRoutes, guestRoutes, studentRoutes, teacherRoutes } from './desktop-routes';
import { Route } from 'react-router-dom';
import { Preloader } from '../components';
import { IUser, Role } from '@chessclub/grpc_wrapper';
import { LandingPage } from '../pages';


function Routes(routes) {
  // console.log('Routes '+ type)
  return routes.map((r) => <Route key={r.path} {...r} />);
}

export function Router({ user }: { user: IUser }) {
  if (!user)
    return [<Route key="0" component={LandingPage} path={'/'}/>]
  if (user.role === Role.ADMIN)
    return Routes(adminRoutes);
  if (user.role === Role.TEACHER)
    return Routes(teacherRoutes);
  if (user.role === Role.STUDENT || user.role === Role.EXTERNAL)
    return Routes(studentRoutes);
  if (user.role === Role.GUEST)
    return Routes(guestRoutes);
  return <Preloader />;
}
