import { StyledButton } from './common';
import React, { PropsWithChildren } from 'react';
import { makePieceKey, newFigureDragStartEvent } from '@chessclub/web-game-server';
import { useUiStore } from '../../store/uiStore';
import { KnownPieces } from '@chessclub/web-game-server/index';
import { Piece } from '@chessclub/ext_chess.ts';

export interface IFigureButtonProps {
  fig: Piece;
  onMouseDown?;
  custom?;
}

export function FigureButton({ fig, children, onMouseDown }: PropsWithChildren<IFigureButtonProps>) {
  const { setDraggingFigure } = useUiStore();
  const src = KnownPieces.getResourcesPath(makePieceKey(fig));

  return (
    <StyledButton
      onPointerDown={(e) => {
        e.preventDefault();
        newFigureDragStartEvent.fire(fig);
        onMouseDown&&onMouseDown();
        setDraggingFigure(src);
      }}
    >
      {children}
    </StyledButton>
  );
}
