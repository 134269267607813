import React, { CSSProperties, PropsWithChildren, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type TextElipsisProps = PropsWithChildren<{
  lineClamp?: number;
  style?: CSSProperties;
  text: string;
  contentEditable?: boolean;
  onChange?(): void;
}>;

interface IStyledProps {
  lineClamp?: number;
  contentEditable?: boolean;
  onChange?(): void;
}

const StyledTextElipsis = styled.div<IStyledProps>`
  -webkit-line-clamp: ${({ lineClamp }) => lineClamp || 2};
  ${({ lineClamp }) => lineClamp && `display: flex; display: -webkit-box;`};

  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: ${({ lineClamp }) => (lineClamp ? 'none' : 'nowrap')};
  text-overflow: ellipsis;
`;

export function TextEllipsis(props: TextElipsisProps) {
  let {
    lineClamp,
    text,
    style,
    contentEditable,
    onChange,
    children,
  } = props;

  const ref = useRef<HTMLDivElement>();

  // show tooltip only if we have overflow
  const [tooltip, setTooltip] = useState('');

  useEffect(() => {
    ref.current &&
    (ref.current.scrollHeight > ref.current.clientHeight || ref.current.scrollWidth > ref.current.clientWidth) &&
    setTooltip(text);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledTextElipsis
      onBlur={onChange}
      contentEditable={contentEditable}
      style={style}
      title={tooltip}
      lineClamp={lineClamp}
      ref={ref}
    >
      {children || text}
    </StyledTextElipsis>
  );
}
