import { pieceKeyToPiece } from '@chessclub/web-game-server';
import { Piece } from '@chessclub/ext_chess.ts';
import { FigureType } from './FigureType';

export const allFiguresBlack = [
  {
    piece: 'bp',
    icon: FigureType.pawn,
    pgnName: ""
  },
  {
    piece: 'bn',
    icon: FigureType.knight,
    pgnName: "N"
  },
  {
    piece: 'bb',
    icon: FigureType.bishop,
    pgnName: "B"
  },
  {
    piece: 'br',
    icon: FigureType.rook,
    pgnName: "R"
  },
  {
    piece: 'bq',
    icon: FigureType.queen,
    pgnName: "Q"
  },
  {
    piece: 'bk',
    icon: FigureType.king,
    pgnName: "K"
  },
].map(fig => {
  return {
    icon: fig.icon,
    piece: pieceKeyToPiece(fig.piece),
    pgnName: fig.pgnName
  } as {icon: FigureType, piece:Piece, pgnName: string}
});

