import { create } from 'zustand';
import {
  MaterialsMode,
  MaterialsStoreActions,
  MaterialsStoreState,
} from './MaterialsStoreApi';
import { ITopic, TopicType, UserId } from '@chessclub/grpc_wrapper';
import { ServerApi } from '../../transport/ServerApi';
import { useAuthStore } from '../auth/AuthStore';
import { selectedTopicDb } from './materialStoreDb';
import { localStorageObject } from '../../helpers/localStorageObject';
import { IMaterial, MaterialSearchParams } from '@chessclub/grpc_wrapper/src/api/types/IMaterial';
import { searchCriteriaIsEmpty } from './searchCriteriaIsEmpty';
import { replaceById } from '../../helpers/replaceById';

export type MaterialsStore = MaterialsStoreState & MaterialsStoreActions;

const savedMode = localStorageObject<MaterialsMode>('materials-mode', MaterialsMode.MATERIALS);

const initialState: MaterialsStoreState = {
  mode: savedMode.restore(),
  materialTopics: [],
  knowledgeTopics: [],
  materials: [],
  selectedTopic: null,
  searchCriteria: null,
}

export const useMaterialsStore = create<MaterialsStore>((
  set,
  get,
) => {

  const privateState : {userId: UserId} = {
    userId: null
  }

  useAuthStore.subscribe(async (state, prevState) => {
    if (state.user?.id !== prevState.user?.id) {
      await init(state.user?.id);
    }
  });

  async function requestMaterials(
    selectedTopic: ITopic,
    searchCriteria: MaterialSearchParams,
    userId: UserId
  ) : Promise<IMaterial[]> {

    if (!selectedTopic) {
      return;
    }

    if (searchCriteriaIsEmpty(searchCriteria)) {
      if (selectedTopic.type === TopicType.MATERIAL) {
        return await ServerApi.materialsService.getNextMaterialByTopicId(
          selectedTopic.id, null, 11);
      } else {
        return await ServerApi.knowledgeService.getNextKnowledgeByTopicId(
          selectedTopic.id, null, 11);
      }
    } else {
      const searchParams = { ...searchCriteria, userId};
      if (selectedTopic.type === TopicType.MATERIAL) {
        return await ServerApi.materialsService.searchNextMaterial(searchParams);
      } else {
        return await ServerApi.knowledgeService.searchNextKnowledge(searchParams);
      }
    }

  }

  async function init(userId: UserId) {

    if (!userId)
      return;
    privateState.userId = userId;

    const materialTopics = await ServerApi.materialsService.getMaterialTopicByUserId(userId);
    const knowledgeTopics =  await ServerApi.knowledgeService.getKnowledgeTopicAll();

    let selectedTopic = await selectedTopicDb.getSelectedTopic(userId) || materialTopics[0];
    let mode = get().mode;
    if (selectedTopic && ![...materialTopics, ...knowledgeTopics].find(t => t.id === selectedTopic.id) ){
      selectedTopic = mode === MaterialsMode.KNOWLEDGE ? knowledgeTopics[0] : materialTopics[0];
    }

    const searchCriteria = null;
    let materials= await requestMaterials(selectedTopic, searchCriteria, userId);
    set({ searchCriteria, materials, selectedTopic, knowledgeTopics, materialTopics });
  }

  return {
    ...initialState,

    setMode(mode: MaterialsMode) {
      set({ mode });
      savedMode.save(mode);
    },

    async setSelectedTopic(selectedTopic: ITopic) {
      await selectedTopicDb.saveSelectedTopic(privateState.userId, selectedTopic);
      const { searchCriteria} = get();
      const materials = await requestMaterials(selectedTopic, searchCriteria, privateState.userId);
      set({ selectedTopic, materials });
    },

    async putTopic(topic: ITopic) {
      const { materialTopics } = get();

      let isEdit = topic.id;
      if (isEdit) {
        await ServerApi.materialsService.updateMaterialTopic(topic)
      } else {
        topic.id = await ServerApi.materialsService.createMaterialTopic(topic, privateState.userId);
      }

      set({
        selectedTopic: topic,
        materialTopics: isEdit ? replaceById(materialTopics, topic) : [...materialTopics, topic],
        materials: [],
      });
    },

    async putMaterial(material: IMaterial) {
      const { selectedTopic, materials, materialTopics } = get();
      const isEdit = material.id;
      if (isEdit) {
        await ServerApi.materialsService.updateMaterialFields(material);
      } else {
        material.topicId = selectedTopic.id;
        material.id = await ServerApi.materialsService.createMaterial(material);
        selectedTopic.itemsCount++;
      }
      set({
        materialTopics: replaceById(materialTopics, selectedTopic),
        materials: isEdit ? replaceById(materials, material) : [...materials, material]
      });
    },

    async deleteTopic(topic: ITopic) {
      const {selectedTopic, materialTopics} = get();
      await ServerApi.materialsService.deleteMaterialTopicById(topic.id);
      const newMaterialTopics = materialTopics.filter(t => t.id !== topic.id);
      set({
        selectedTopic: selectedTopic.id === topic.id ? newMaterialTopics[0] : selectedTopic,
        materialTopics: newMaterialTopics
      });
    },

    async deleteMaterial(mat: IMaterial) {
      const { selectedTopic, materialTopics } = get();
      selectedTopic.itemsCount--;
      set({
        materialTopics: replaceById(materialTopics, selectedTopic),
        materials: get().materials.filter(m => m.id !== mat.id),
      });
      await ServerApi.materialsService.deleteMaterialById(mat.id);
    },

  } ;
});