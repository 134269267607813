import { ActiveRemoteCameraCard } from './ActiveRemoteCameraCard';
import { Room, RoomSession } from '@chessclub/video-conf';
import { InactiveRemoteCameraCard } from './InactiveRemoteCameraCard';
import React from 'react';
import { useConferenceStore } from '../../../../../../../store/conferenceStore';
import { ChessclubUser } from '../../../../../../../interfaces/user';
import { useTasksStore } from '../../../../../../../store/tasks/TasksStore';


export function RemoteCameraCard({ user }: {user: ChessclubUser}) {

  const { streamSessions, room } = useConferenceStore();
  const { currentTaskBoardState } = useTasksStore();
  const session = findSession(user.socketId);
  const canMove = currentTaskBoardState?.canMove.includes(user.id);

  if (session && session.pc.stream) {
    return <ActiveRemoteCameraCard
      key={user.socketId}
      room={room as Room}
      session={session as RoomSession}
      user={user}
      canMove={canMove}
    />;
  }

  return <InactiveRemoteCameraCard
    user={user}
    canMove={canMove}
  />;


  function findSession(id) {
    return streamSessions.find((session) => session.user === id);
  }
}