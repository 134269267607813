import React from 'react';
import { SvgImage } from '../../../../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../../../../components/Icon/KnownIcons';
import { ChessclubUser } from '../../../../../../../interfaces/user';
import { useLessonStore } from '../../../../../../../store/lesson/LessonStore';
import { RedCameraCardButton } from './RedCameraCardButton';

export function MicButton({microEnabled, toggleMicroEnabled}: {
  microEnabled: boolean;
  toggleMicroEnabled: () => void
}) {

  return <RedCameraCardButton
    style={{gridColumn: 4}}
    onClick={() => toggleMicroEnabled && toggleMicroEnabled()}
    inOn={!microEnabled}
    icon={KnownIcons.micro_filled}
  />

}