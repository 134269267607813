import React, { SyntheticEvent } from 'react';

import { Link } from '../low-level-components/Link';
import { RawSvg } from './RawSvg';

import logo_large_light from '../../svg/images/large-logo-vertical.svg';
import logo_large_dark from '../../svg/images/large-logo-vertical-white.svg';

import logo_small_light from '../../svg/images/small-logo-horizontal.svg';
import logo_small_dark from '../../svg/images/small-logo-horizontal-white.svg';

import logo_tiny_light from '../../svg/images/tiny-logo.svg';
import logo_tiny_dark from '../../svg/images/tiny-logo-white.svg';

import { Stack } from '../low-level-components/Stack';
import { buildDate } from '../../helpers/buildDate';


export enum LogoSize {
  none = "none",
  tiny = 'tiny',
  small = 'small',
  large = 'large'
}

interface ILogo {
  size: LogoSize;
  active?: boolean;
}

function LargeLogo() {
  return <RawSvg
    width={250}
    rawSvg={logo_large_light}
    rawSvgDark={logo_large_dark}
  />;
}

function SmallLogo() {
  return <RawSvg
    width={160}
    rawSvg={logo_small_light}
    rawSvgDark={logo_small_dark}
  />;
}

function TinyLogo() {
  return <RawSvg
    width={44}
    rawSvg={logo_tiny_light}
    rawSvgDark={logo_tiny_dark}
  />;
}

export function Logo(props: ILogo) {
  const { size } = props;
  if (size === LogoSize.none)
    return null;
  return <Stack>
    <Link to={'/'}>
      {size === LogoSize.tiny && <TinyLogo />}
      {size === LogoSize.small && <SmallLogo />}
      {size === LogoSize.large && <LargeLogo />}
    </Link>

    { (size === LogoSize.large || size === LogoSize.small) && <div style={{
      fontFamily: 'Roboto',
      display: 'flex',
      fontSize: 9,
      justifyContent: 'end',
      color: '#777',
      marginTop: -5,
    }}>
      Build: {buildDate}
    </div>}
  </Stack>;
}
