import React, { useState } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { UserProfilePage } from './UserProfilePage';
import { useAuthStore } from '../../store/auth/AuthStore';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';

export function UserSettingsPage() {

  const { user, updateProfile } = useAuthStore();

  return <PageTemplate>
    {user?.profile && (
      <ContentContainer title='Настройки'>
        <UserProfilePage
          profile={user.profile}
          onChange={async () => updateProfile(user.profile)}
        />
      </ContentContainer>
    )}
  </PageTemplate>;
}
