import { lerp } from '@chessclub/web-game-server/src/shared/util/math';
import React from 'react';
import { SvgIconAnimation } from '../SvgIconAnimation';
import { uuid } from '@chessclub/web-game-server/src/client/util/uuid';

export function PlayPause(props: { active: boolean;}) {
  const id = uuid()
  return <SvgIconAnimation
    {...props}
    id={id}
    duration={300}
    viewport={12}
    size={32}
    transform={t => `rotate(${(1-t)*90})`}
    interpolator={t => {

      const x = -3, xc = 2.3, yc = 0, xs = 3,
        X = lerp(xs, xc,  t),
        Y = lerp(x, yc,  t);

      return [
        'M', x, x,
        'L', X, Y,
        'L', X, -Y,
        'L', x, xs,
        'z'
      ].join(' ');

    }} >
    <g className="stroke" fill="none"  strokeLinejoin={'round'} strokeWidth={0.8}>
      <use xlinkHref={"#" + id} />
    </g>

  </SvgIconAnimation>;
}