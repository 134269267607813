import styled from 'styled-components';

export const LessonToolbarButton = styled.span<{active?:boolean;}>`
  cursor: pointer;
  transition: all 0.15s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
  padding: 5px;
  background-color: ${({active, theme}) => active ? theme.currentTheme.hover : "none"};
  fill: ${({ theme }) => theme.currentTheme.iconMain};
  :hover {
    ${({ theme }) => `&:hover { background-color: ${theme.currentTheme.hover}; }`}
  }

`;