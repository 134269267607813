import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Logo, LogoSize } from '../../components/Icon/Logo';
import Input from '../../components/low-level-components/Input';
import { Link } from '../../components/low-level-components/Link';
import {TextLink} from '../../components/low-level-components/TextLink';
import PasswordInput from '../../components/low-level-components/PasswordInput';
import { StyledButton } from '../RegisterPage/RegisterSteps';
import { LoginPageStyledComponents } from './LoginPageStyledComponents';
import { useAuthStore } from '../../store/auth/AuthStore';
import { useStoredState } from '../../hooks/useStoredState';
import { useTheme } from 'styled-components';
import { PageFooter } from '../../components';
import { SocialAuth } from './SocialAuth';
import { useLocalization } from '../../l10n/UseLocalization';
import { Space } from '../../components/low-level-components/Space';

const {
  LinkWrapper, ButtonWrapper, InputWrapper, StyledLogoContainer,
} = LoginPageStyledComponents;

export interface ILoginForm {
  email: string;
  password: string;
}

const errors = {
  1: "Пользовтель не найден",
  5: "Неверное имя пользователя или пароль",

}

export function LoginPage() {

  const {
    authError,
    passwordAuth
  } = useAuthStore();

  const styledTheme = useTheme();
  const { auth } = useLocalization();
  // const { theme } = useThemeStore();
  const [email, setEmail] = useStoredState<string | undefined>('register-email', '');
  const [formData, setFormData] = useState<ILoginForm>({ email, password: '' });

  // const mainAuthMethods = socialAuthProviders
  //   .filter((_, i) => i < SOCIAL_AUTH_METHODS_COUNT);

  let errorText = authError?.text;
  if (auth.errors[errorText]) {
    errorText = auth.errors[errorText];
  }
  return <Box display='grid' height='100%' maxWidth='90%' margin='0 auto'>
    <Grid container justifyContent='center'>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <Grid container justifyContent='center'>
          <StyledLogoContainer>
            <Logo size={LogoSize.large}/>
          </StyledLogoContainer>
        </Grid>
        <Space size={50}/>
        {/*<Grid container justifyContent='center'>*/}
        {/*  <StyledMessage>{t('login_with')}</StyledMessage>*/}
        {/*</Grid>*/}

        <SocialAuth />

        {/*<Grid container justifyContent='center'>*/}
        {/*  <StyledOr>{t('or')}</StyledOr>*/}
        {/*</Grid>*/}
        <Grid
          style={{ maxWidth: '352px', margin: '0 auto' }}
          alignItems='center'
          container
          justifyContent='center'
          direction='column'
        >
          <div
            style={{
              alignSelf: 'flex-start',
              marginLeft: '1rem',
              color: styledTheme.currentTheme['error1'],
              marginTop: '2rem',
              marginBottom: '.5rem',
              fontFamily: "Roboto"
            }}
          >
            {errorText}&nbsp;
          </div>

          <InputWrapper>
            <Input
              label={auth.email}
              fullWidth
              value={formData.email}
              onChange={(e) => {
                setFormData((prev) => ({ ...prev, email: e.target.value }));
                setEmail(e.target.value);
              }}
              error={authError?.email}
            />
          </InputWrapper>
          <InputWrapper>
            <PasswordInput
              rows={1}
              label={auth.password}
              fullWidth
              value={formData.password}
              onChange={(e) => {
                setFormData((prev) => ({ ...prev, password: e.target.value }));
              }}
              error={authError?.password}
            />
          </InputWrapper>
          <ButtonWrapper>
            <StyledButton
              disabled={!formData.email || !formData.password} fullWidth
              onClick={() => passwordAuth(formData.email, formData.password)}>
              {auth.login}
            </StyledButton>
          </ButtonWrapper>
        </Grid>
        <Grid container justifyContent='center' direction='row' style={{paddingTop:15}}>
          <LinkWrapper>
            <Link to={`/auth/recover/${formData.email}`}>{auth.forgot_password}</Link>
          </LinkWrapper>
          <div style={{margin:"0 10px"}}>|</div>
          <TextLink
            linkText={auth.sign_up}
            to={'/auth/register'}
          />
        </Grid>
      </Grid>
    </Grid>
    <PageFooter style={{ marginTop: '16px' }} />
  </Box>;

}
