import { KnownPieces, makePieceKey } from '@chessclub/web-game-server/index';
import React from 'react';
import { FigureButton } from './FigureButton';

export function PiecesGroup({ groupName, onMouseDown }: { groupName: string; onMouseDown?; }) {
  return <>
    {KnownPieces.getPiecesList(groupName).map(piece => {
      const img = KnownPieces.getResourcesPath(makePieceKey(piece));
      return <FigureButton key={piece.id} fig={piece} onMouseDown={onMouseDown}>
        <img src={img} width={32}/>
      </FigureButton>;
    })}
  </>;
}