import { SvgImage } from '../../../../../../../components/Icon/SvgImage';
import { DiagonalLineIconModifier } from '../../../../../../../components/Icon/DiagonalLineIconModifier';
import { Stack } from '../../../../../../../components/low-level-components/Stack';
import React, { CSSProperties } from 'react';
import { RawSvgString } from '*.svg';
import { useThemeStore } from '../../../../../../../store/themeStore';
import theme from '../../../../../../../theme';

type RedCameraCardButtonParams = {
  className?: string;
  inOn?: boolean;
  icon: RawSvgString;
  onClick?;
  style?: CSSProperties;
};

export function RedCameraCardButton(props: RedCameraCardButtonParams) {
  const color = theme.colors[useThemeStore().theme].error1;
  return <DiagonalLineIconModifier
    style={props.style}
    color={color}
    className={props.className}
    isOn={props.inOn}
    onClick={props.onClick}
  >
    <SvgImage
      filled
      color={color}
      icon={props.icon}
    />
  </DiagonalLineIconModifier>;

}