import styled from 'styled-components';
import React, { PropsWithChildren } from 'react';

export const TaskEditContainer = styled.div`
  height: 100%;
  display: grid;
  grid-column-gap: 10px;
  //padding-top: var(--top-gap);
  grid-row-gap: 0;
  grid-template-columns: repeat(12, 1fr);
  //grid-template-rows: 0fr auto;
  grid-template-areas:   
          'H H H H H H H H H H H H'
          'L L L C C C C C C R R R' 
          'L L L C C C C C C R R R' 
          'L L L C C C C C C R R R'
     
;

  & > * {
    gap: 12px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;
