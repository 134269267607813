import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

const StyledConteiner = styled.div<{ inMessage: boolean }>`
  margin-bottom: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.currentTheme.iconMain};
  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  border-left: ${({ inMessage, theme }) => inMessage && `2px solid ${theme.currentTheme.iconMain}`};
  padding-left: 10px;
  margin-left: 8px;
`;
const Label = styled.span`
  font: ${({ theme }) => theme.fonts.smallBold};
`;
const MessageRefText = styled.span`
  font: ${({ theme }) => theme.fonts.small};
  max-width: 320px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

export function MessageRelate(props: { headerText: string; messageText: string; inMessage?: boolean }) {
  const { headerText, messageText, inMessage = false } = props;

  return (
    <StyledConteiner inMessage={inMessage}>
      <Label>{headerText}</Label>
      <MessageRefText>
        {messageText.length > 0 && parse(decodeURIComponent(escape(window.atob(messageText))))}
      </MessageRefText>
    </StyledConteiner>
  );
}
