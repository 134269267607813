import { QuestionRank } from '@chessclub/grpc_wrapper';
import { many } from '@chessclub/web-game-server/src/shared/util/math';
import React from 'react';
import styled from 'styled-components';
import { KnownIcons } from '../../../components/Icon/KnownIcons';
import { SvgImage } from '../../../components/Icon/SvgImage';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import { TaskEditViewButton } from './TaskEditViewButton';

const StyledContainer = styled.div`
  display: flex;
  gap: 3px;
`;

export function TaskEditRank() {
  const { editingTask, updateEditingTaskData } = useEditingTaskStore();
  const cur = editingTask?.rank;
  return <StyledContainer>
    {many(11, i => i).map(rank => {
      return <TaskEditViewButton
        key={rank}
        onClick={() => updateEditingTaskData({ rank: rank as QuestionRank})}
        className={cur === rank ? 'selected' : ''}
      >
        {rank || <SvgImage size={24} icon={KnownIcons.cross} />}
      </TaskEditViewButton>;
    })}
  </StyledContainer>;
}

