import styled from 'styled-components';

export const TaskEditViewButton = styled.div<{pad?:number}>`
  padding: 0 ${p => p.pad || 0}px;
  min-width: 30px;
  height: 26px;
  border: 1.5px solid #7777;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font: var(--font-normal);
  color: var(--color-icon-main);
  &:hover {
    box-shadow: inset 0 0 6px #7777;
  }
  &.selected {
    border-color: var(--color-accent);
  }
  border-radius: 6px;
`;