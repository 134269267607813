import { TopicType } from '@chessclub/grpc_wrapper/src/api/types/ITopic';
import { randomIcon } from '../../components/Icon/KnownIcons';

export function newMaterialTopic() {
  return {
    type: TopicType.MATERIAL,
    name: 'new topic',
    customData:{
      icon: randomIcon()
    }
  }
}