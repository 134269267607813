import { getMNfromFen, SvgChessboard } from '@chessclub/web-game-server';
import React from 'react';
import { boardGray, boardSettings } from './boardSettings';
import { FEN, Marker } from '@chessclub/grpc_wrapper';

type SvgChessboardProps = {
  fen: FEN;
  altColor?: boolean;
  markers?: Marker[];
}

export function ChessboardSvg(props: SvgChessboardProps) {
  const { fen, altColor } = props;
  return <SvgChessboard
    params={{
      ...(altColor ? boardSettings : boardGray),
      sideCellCount: getMNfromFen(fen).N,
      boardImageSideSizePx: 1,
    }}
    fen={fen}
    markers={props.markers}
  />;
}