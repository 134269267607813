import { KnownIcons } from '../Icon/KnownIcons';
import { SvgImage } from '../Icon/SvgImage';
import React from 'react';
import { IconSelectWidgetProps } from './IconSelectWidgetProps';
import { useModalStore } from '../../store/modalStore';

export function IconSelectWidgetPopupContent(props: IconSelectWidgetProps) {
  const {closePopup} = useModalStore();
  return <div style={{ display: 'grid', gridTemplateColumns: "repeat(10, 1fr)", flexWrap: 'wrap', padding: 5, boxSizing: 'border-box' }}>
    {Object.entries(KnownIcons).map(([key, icon]) => {
      let onClick = () => {
        props.onChange(key);
        closePopup()
      };
      return <SvgImage
        withHover
        icon={icon}
        key={key}
        active={props.icon === key}
        onClick={onClick}
      />;
    })}
  </div>;
}