import {create} from 'zustand';

export enum DndType {
  LESSON_TAB = "lesson-tab",
  TASK = "task"
}

export type DragEvent = {
  type: DndType;
  data: string;
};

interface DndStoreState {
  dragEvent: DragEvent;
}

interface DndStoreActions {
  startDrag(dragEvent: DragEvent): void,
  endDrag(): void;
}

type DndStore = DndStoreState & DndStoreActions;

export const useDndStore = create<DndStore>((
  set,
  get
) : DndStore => {

  window.addEventListener("dragend", () => {
    set({dragEvent: null})
  })
  window.addEventListener("pointerup", () => {
    set({dragEvent: null})
  })

  return {

    dragEvent: null,

    startDrag(dragEvent: DragEvent) {
      set({ dragEvent });
    },

    endDrag() {
      set({ dragEvent: null });
    },
  };
});
