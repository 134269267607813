import { CameraCardUserName } from './CameraCardUserName';
import { ToggleCanMove } from '../buttons/ToggleCanMove';
import { CamButton } from '../buttons/CamButton';
import { MicButton } from '../buttons/MicButton';
import React from 'react';
import styled from 'styled-components';
import { ICameraCard } from './CameraCardBase';
import { useLessonStore } from '../../../../../../../store/lesson/LessonStore';

const CameraCardBottomPanelWrapper = styled.div`
  display: grid;
  //justify-content: space-between;
  grid-template-columns: 1fr 32px 32px 32px;
  grid-template-rows: 1fr 32px;
  //align-items: end;
  //padding: 0 4px;
  box-sizing: border-box;
  z-index: 2;
  pointer-events: none;
  & svg {
    pointer-events: all!important;
  }
  & > * {
    grid-row: 2;
  }
`;

export function CameraCardBottomPanel(props: ICameraCard & {canMoveVisible:boolean}) {
  return <CameraCardBottomPanelWrapper>
    <CameraCardUserName {...props} />
    <ToggleCanMove {...props} />
    <CamButton {...props} />
    <MicButton {...props} />
  </CameraCardBottomPanelWrapper>
}