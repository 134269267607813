import React from 'react';
import styled from 'styled-components';
import { IMessageAvatar } from '../../../../../../interfaces/chat-message';
import { useLessonStore } from '../../../../../../store/lesson/LessonStore';
import { UserId } from '@chessclub/grpc_wrapper';

const StyledMessageHeaderContainer = styled.div<{ me?: boolean }>`
  display: flex;
  flex-direction: ${({ me }) => (me ? 'row-reverse' : 'row')};
  align-items: center;
  justify-content: ${({ me }) => (me ? `flex-end` : 'space-between')};
  margin-bottom: ${({ me }) => me && `5px`};
`;

const StyledMessageAuthor = styled.span`
  font: ${({ theme }) => theme.fonts.small};
  color: ${({ theme }) => theme.currentTheme.iconMain};
`;

const StyledMessageAvatarContainer = styled.div`
  width: 30px;
  margin-right: 10px;
`;

const StyledMessageAvatar = styled.span<IMessageAvatar>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font: ${({ theme }) => theme.fonts.normalBold};
  color: ${({ theme }) => theme.currentTheme.background};
  background: ${({ avatarColor }) => avatarColor};
  border-radius: 50%;
`;

const StyledMe = styled(StyledMessageAuthor).attrs({
  children: '(Я)',
})`
  color: ${({ theme }) => theme.currentTheme.textMain};
  margin-right: 5px;
`;
const StyledMessageTime = styled.span`
  font: ${({ theme }) => theme.fonts.small};
  color: ${({ theme }) => theme.currentTheme.iconMain};
`;

const StyledAvatarAndAuthor = styled.span<{ me?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ me }) => (me ? 'row-reverse' : 'row')};
  gap: ${({ me }) => me && '10px'};
`;

const StyledAvatar = styled.img`
  border: 1px solid ${({ theme }) => theme.currentTheme.hover};
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export function ChatMessageHeader({ author }: { author: UserId }) {
  const { users } = useLessonStore();

  return (
    <StyledMessageHeaderContainer >
      <StyledAvatarAndAuthor>
        <StyledMessageAvatarContainer>
          <StyledAvatar alt='user' src={users.find(u => u.id === author)?.imageUrl} />
        </StyledMessageAvatarContainer>
        <StyledMessageAuthor>
          {users.find(u => u.id === author)?.displayName}
        </StyledMessageAuthor>
      </StyledAvatarAndAuthor>
    </StyledMessageHeaderContainer>
  );
}


