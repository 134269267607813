import React from 'react';
import { SvgImage } from '../../../../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../../../../components/Icon/KnownIcons';
import { ChessclubUser } from '../../../../../../../interfaces/user';
import { useLessonStore } from '../../../../../../../store/lesson/LessonStore';

export function ToggleCanMove(props: {user: ChessclubUser, canMoveVisible?:boolean}) {

  const { toggleCanMoveById } = useLessonStore();

  if (!props.canMoveVisible)
    return null;

  return <SvgImage
    style={{gridColumn: 2}}
    className='hidden'
    withHover
    icon={KnownIcons.pawn_with_arrows}
    onClick={() => toggleCanMoveById(props.user.id)}
  />

}