import React from 'react';
import { SvgIconAnimation } from '../SvgIconAnimation';
import { uuid } from '@chessclub/web-game-server/src/client/util/uuid';
import { max, min } from '@chessclub/web-game-server/src/shared/util/math';

export function Lamp(props: { active: boolean;}) {
  let id = uuid();
  return <SvgIconAnimation
    {...props}
    id={id}
    duration={300}
    viewport={32}
    size={32}
    strokeWidth={2}
    // transform={t => `rotate(${-t*90})`}
    interpolator={t => {
      t = 1-t
      let y0 = -5 -min(1, max(0, t-0.5) * 2) * 4
      let y1 = -5 -min(1, t * 2) * 7;
      return `M 0 ${y0} L 0 ${y1}`
    }} >

    <g transform={"translate(-16,-16)"}>
      <path d="M18 18.6586L17.6668 17.7157L17 17.9514V18.6586H18ZM14 18.6586H15V17.9514L14.3332 17.7157L14 18.6586ZM14 22H13V23H14V22ZM18 22V23H19V22H18ZM21 13C21 15.1755 19.6101 17.0289 17.6668 17.7157L18.3332 19.6014C21.0507 18.641 23 16.0494 23 13H21ZM16 8C18.7614 8 21 10.2386 21 13H23C23 9.13401 19.866 6 16 6V8ZM11 13C11 10.2386 13.2386 8 16 8V6C12.134 6 9 9.13401 9 13H11ZM14.3332 17.7157C12.3899 17.0289 11 15.1755 11 13H9C9 16.0494 10.9493 18.641 13.6668 19.6014L14.3332 17.7157ZM15 22V18.6586H13V22H15ZM18 21H14V23H18V21ZM17 18.6586L17 22H19L19 18.6586H17Z"
            className="fill"/>
      <rect x="14" y="22" width="4" height="3" rx="1" strokeWidth="2"
            className="fill stroke"  />
    </g>
    <g transform={"translate(0,-3)"} className="stroke" strokeWidth="2">
      <use xlinkHref={`#${id}`} transform={"rotate(0)"}/>
      <use xlinkHref={`#${id}`} transform={"rotate(-45)"}/>
      <use xlinkHref={`#${id}`} transform={"rotate(45)"}/>
      <use xlinkHref={`#${id}`} transform={"rotate(-90)"}/>
      <use xlinkHref={`#${id}`} transform={"rotate(90)"}/>
      <use xlinkHref={`#${id}`} transform={"rotate(-135)"}/>
      <use xlinkHref={`#${id}`} transform={"rotate(135)"}/>
    </g>

  </SvgIconAnimation>;
}