import React, { useState } from 'react';
import styled from 'styled-components';

import { EmailStep } from './EmailStep';
import { CaptchaStep } from '../../components/Captcha/CapthcaStep';
import { CompleteStep } from './CompleteStep';
import { useStoredState } from '../../hooks/useStoredState';
import { Button } from '../../components/low-level-components/Button';
import { RegisterStep } from '../../store/auth/AuthStoreApi';
import { useLocalization } from '../../l10n/UseLocalization';

export const InputWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium}px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.large}px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  padding: 14px 0;
  height: 3rem;
  padding-top: 0.813rem;
  background: ${({ theme, disabled }) =>
    disabled ? theme.currentTheme.iconMain : theme.currentTheme.button} !important;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  &:hover {
    background: ${({ theme, disabled }) => !disabled && theme.currentTheme.buttonHover} !important;
  }
`;

export const RegisterSteps = () => {
  const [email, setEmail] = useStoredState<string | undefined>('register-email', '');
  const [phase, setPhase] = useState<RegisterStep>(RegisterStep.EMAIL);
  const lang = useLocalization();
  switch (phase) {
    case RegisterStep.EMAIL:
      return <EmailStep email={email} setEmail={setEmail} setPhase={setPhase} />;
    case RegisterStep.CAPTCHA:
      return <CaptchaStep confirmButtonText={lang.auth.confirm} email={email} setPhase={setPhase} />;
    case RegisterStep.COMPLETE:
      return <CompleteStep email={email} />;
  }
};
