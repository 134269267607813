import { LessonSideBarProps } from './LessonSideBarProps';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { LessonSideBarHeader } from './LessonSideBarHeader';
import { LessonSideBarHiddenMode } from './LessonSideBarHiddenMode';
import React from 'react';
import { useLessonUiStore } from '../../../../../store/lesson-ui/LessonUiStoreImpl';
import { LayoutSlots } from '../../../../../store/lesson-ui/LessonUiStoreApi';

const StyledSideBar = styled(Grid)<{
  $gridArea: string;
  $reverse: boolean;
}>`
  grid-area: ${props => props.$gridArea};
  flex-grow: 1;
  grid-template-rows: 0fr;
  margin-top: 10px;
  grid-row-gap: 12.5px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: ${props => props.$reverse? "row-reverse" : "row"};
`;

const StyledSideBarContentWrapper = styled.div`
  width: 330px;
  height: 100%;
  display: flex;
  flex-direction: column;
  transform-origin: left;
  position: static;
  top: 0;
  left: 0;
`;

export function LessonSideBar(props: LessonSideBarProps) {
  // debugger
  const {tabsLayout} = useLessonUiStore();
  const { gridArea, slot } = props;
  const tabs = props.tabs.filter(t => t)
  const activeTab = tabs.find(t => tabsLayout.visible[t.type]);
  const reverse = slot === LayoutSlots.TOP_RIGHT || slot === LayoutSlots.BOTTOM_RIGHT;
  return <StyledSideBar
    className='side-bar'
    $gridArea={gridArea}
    $reverse={reverse}
  >
    {activeTab ? (
      <StyledSideBarContentWrapper>
        <LessonSideBarHeader {...props} tabs={tabs}/>
        {activeTab.content}
      </StyledSideBarContentWrapper>
    ) : (
      <LessonSideBarHiddenMode {...props} tabs={tabs}/>
    )}
  </StyledSideBar>;
}