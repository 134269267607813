import { ITask } from '@chessclub/grpc_wrapper';


export enum FENEnum {
  empty = '8/8/8/8/8/8/8/8 w - - 0 1',
  initial = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  afterCreation = '3b4/6Q1/BN1B4/1K5Q/1N3k2/1B4Rb/4RR2/1Q2N3 w - - 0 1',
}

export enum CellsColorYellow {
  light = '#F8E1C0',
  dark = '#CD9D76',
}
export enum CellsColorBlue {
  light = '#D4DFE6',
  dark = '#93B4C8',
}
export enum CellsColorGreen {
  light = '#D1E8DC',
  dark = '#97B7A4',
}

export interface CellsColor {
  light: string;
  dark: string;
}

export interface IQuestion extends ITask {
  hideCheckbox?: boolean;
  selected?: boolean;
  chessboardHover?: boolean;
  viewing?: boolean;
  name?; // compile crutch, need to be removed
}

export interface IBg {
  active?: boolean;
  hover?: boolean;
  chessboardHover?: boolean;
}

export interface IChessboard {
  active?: boolean;
}
