import { SvgImage } from '../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../components/Icon/KnownIcons';
import { LessonToolbarButton } from '../components';
import React from 'react';
import { randomFen } from '../../../../logic/randomFen';
import { useTasksStore } from '../../../../store/tasks/TasksStore';
import { useLocalization } from '../../../../l10n/UseLocalization';
import { useLessonStore } from '../../../../store/lesson/LessonStore';
import { useAuthStore } from '../../../../store/auth/AuthStore';
import { TaskType } from '@chessclub/grpc_wrapper/src/api/types/QuestionBase';

export function LessonAddTaskButton() {

  const { addQuestion, tasks } = useTasksStore();
  const lan = useLocalization();
  const {  roomId, monitoringStudentId } = useLessonStore();
  const { user } = useAuthStore();

  function addQuestionAction() {
    let start = lan.lesson.newTask;
    let taken = tasks
      .filter(t => t.title.startsWith(start))
      .map(t => t.title.substring(start.length).trim());
    let i: any = '';
    while (taken.includes('' + i)) {
      i = !i ? 1 : i + 1;
    }
    addQuestion({
      isActive: false,
      type: TaskType.QUESTION,
      roomId: roomId,
      userId: monitoringStudentId || user.id,
      title: start + (i ? ' ' + i : ''),
      fen: randomFen(),
    });
  }

  return <LessonToolbarButton key={0} onClick={addQuestionAction}>
    <SvgImage icon={KnownIcons.plus} />
  </LessonToolbarButton>;
}