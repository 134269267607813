import React from 'react';
import { FooterIcon } from './FooterIcon';

export function Theme_light() {
  return <FooterIcon>
    <path
      d='M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z'
      className="mainColor"
    />
    <path
      fillRule='evenodd' clipRule='evenodd'
      d='M8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z'
      className='bgColor'
    />
    <path
      fillRule='evenodd' clipRule='evenodd'
      d='M22 12C22 12.5523 21.5523 13 21 13H18C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11H21C21.5523 11 22 11.4477 22 12Z'
      className='bgColor'
    />
    <path
      fillRule='evenodd' clipRule='evenodd'
      d='M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L16.7071 8.70711C16.3166 9.09763 15.6834 9.09763 15.2929 8.70711C14.9024 8.31658 14.9024 7.68342 15.2929 7.29289L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z'
      className='bgColor'
    />
    <path
      fillRule='evenodd' clipRule='evenodd'
      d='M7 12C7 12.5523 6.55228 13 6 13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H6C6.55228 11 7 11.4477 7 12Z'
      className='bgColor'
    />
    <path
      fillRule='evenodd' clipRule='evenodd'
      d='M8.70711 15.293C9.09763 15.6835 9.09763 16.3166 8.70711 16.7072L6.70711 18.7072C6.31658 19.0977 5.68342 19.0977 5.29289 18.7072C4.90237 18.3166 4.90237 17.6835 5.29289 17.293L7.29289 15.293C7.68342 14.9024 8.31658 14.9024 8.70711 15.293Z'
      className='bgColor'
    />
    <path
      fillRule='evenodd' clipRule='evenodd'
      d='M12 7C11.4477 7 11 6.55228 11 6V3C11 2.44772 11.4477 2 12 2C12.5523 2 13 2.44772 13 3V6C13 6.55228 12.5523 7 12 7Z'
      className='bgColor'
    />
    <path
      fillRule='evenodd' clipRule='evenodd'
      d='M8.70711 8.70705C8.31658 9.09757 7.68342 9.09757 7.29289 8.70705L5.29289 6.70705C4.90237 6.31652 4.90237 5.68336 5.29289 5.29283C5.68342 4.90231 6.31658 4.90231 6.70711 5.29283L8.70711 7.29283C9.09763 7.68336 9.09763 8.31652 8.70711 8.70705Z'
      className='bgColor'
    />
    <path
      fillRule='evenodd' clipRule='evenodd'
      d='M12 22C11.4477 22 11 21.5523 11 21V18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18V21C13 21.5523 12.5523 22 12 22Z'
      className='bgColor'
    />
    <path
      fillRule='evenodd' clipRule='evenodd'
      d='M18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L15.2929 16.7071C14.9024 16.3166 14.9024 15.6834 15.2929 15.2929C15.6834 14.9024 16.3166 14.9024 16.7071 15.2929L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071Z'
      className='bgColor'
    />

  </FooterIcon>;
}