import Dexie from 'dexie';
import { UserId } from '@chessclub/grpc_wrapper';

const dexie = new Dexie('chessclub--conference');

dexie.version(1).stores({
  cam: 'userId',
  mic: 'userId',
});

const cam: Dexie.Table
  // @ts-ignore
  = dexie.cam;

const mic: Dexie.Table
  // @ts-ignore
  = dexie.mic;

export const conferenceStateDb = {

  async isCam(userId: UserId): Promise<boolean> {
    let newVar = await cam.get(userId);
    return newVar?.isOn
  },

  async setCam(userId: UserId, isOn: boolean) {
    await cam.put({isOn, userId})
  },

  async isMic(userId: UserId) {
    let newVar = await mic.get(userId);
    return newVar?.isOn
  },

  async setMic(userId: UserId, isOn: boolean) {
    await mic.put({isOn, userId})
  }

}