import React from 'react';
import { useLessonUiStore } from '../../../../store/lesson-ui/LessonUiStoreImpl';
import { LessonTabs, LessonViewMode } from '../../../../store/lesson-ui/LessonUiStoreApi';
import { KnownIcons } from '../../../../components/Icon/KnownIcons';
import { HandeHochButton } from '../buttons/HandeHochButton';
import { Chat } from './Chat/Chat';
import { useLessonStore } from '../../../../store/lesson/LessonStore';
import { RoomOwnerCamera } from '../LessonCamerasTab/Cameras/RoomOwnerCamera';
import { LessonSideBarTabProps } from '../components';
import { RemoteCameraCard } from '../LessonCamerasTab/Cameras/CameraCard/variants/RemoteCameraCard';

export function LessonChatTab() : LessonSideBarTabProps{
  const { studentViewSettings, isStudentLayout, monitoringStudentId, users, isOnline } = useLessonStore();
  const {view, tabsLayout} = useLessonUiStore();
  const allowedViews = [LessonViewMode.STANDARD, LessonViewMode.CHESSBOARDS];
  const actualView = monitoringStudentId ? LessonViewMode.STANDARD : view;
  const shouldShowChat = isStudentLayout ? studentViewSettings?.chat : true;
  if (!allowedViews.includes(actualView)  || !shouldShowChat)
    return;
  return {
    type: LessonTabs.CHAT,
    icon: KnownIcons.chat,
    content: <>
      {!tabsLayout.visible[LessonTabs.CAMS]  && isOnline && <>
        {monitoringStudentId ? <div style={{ marginRight: 7, marginBottom: 5 }}>
          <RemoteCameraCard user={users.find(u => u.id === monitoringStudentId)}/>
        </div> : <RoomOwnerCamera />}
      </>
        }
      <Chat />
    </>,
    additionalButtons: []
  }
}