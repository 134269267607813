import React from 'react';
import styled from 'styled-components';
import { LessonSideBarTabButton } from './LessonSideBarTabButton';
import { LessonSideBarProps } from './LessonSideBarProps';
import { Stick } from './Stick';
import { useLessonUiStore } from '../../../../../store/lesson-ui/LessonUiStoreImpl';
import { useLocalization } from '../../../../../l10n/UseLocalization';
import { DndType, useDndStore } from '../../../../../store/dndStore';
import { Stack } from '../../../../../components/low-level-components/Stack';
import { DropZone } from '../../../../../components/low-level-components/DropZone';
import { LayoutSlots } from '../../../../../store/lesson-ui/LessonUiStoreApi';

const StyledTitle = styled.div`
  padding-left: 5px;
  font: ${({ theme }) => theme.fonts.smallBold};
  color: ${({ theme }) => theme.currentTheme.textMain};
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
`;

export function LessonSideBarHeader({ tabs, slot }: LessonSideBarProps) {
  const { tabsLayout, changeLayout } = useLessonUiStore();
  const { endDrag} = useDndStore();

  const activeTab = tabs.filter(t => tabsLayout.visible[t.type]).pop();
  const reverse = slot === LayoutSlots.TOP_RIGHT || slot === LayoutSlots.BOTTOM_RIGHT;

  function onDrop(tab) {
    endDrag();
    changeLayout(slot, tab);
  }

  return <Stack style={{paddingBottom: 8}}>
    <DropZone
      type={DndType.LESSON_TAB}
      onDrop={onDrop}
    />
    <StyledHeader style={{flexDirection: reverse ? "row-reverse" : "row"}}>
      {/*<StyledTitle>{lesson[activeTab.type]}</StyledTitle>*/}
      <div style={{display: 'flex'}}>
        {tabs.map(tab => <LessonSideBarTabButton key={tab.type} {...tab} />)}
        {/*{activeTab?.additionalButtons.length > 0 && <Stick />}*/}
      </div>
      <div>
        {activeTab?.additionalButtons}
      </div>
    </StyledHeader>
  </Stack>;
}


