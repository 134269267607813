export const boardSettings = {
  boundary: true,
  cellSize: 90,
  padding: 45,
  outlineWidth: 0,
  lightColor: '#fee7c8',
  darkColor: '#847c68',
  outlineColor: '#5c5b5b',
  boardColor: '#DEDBCF',
}

export const boardGray = {
  boundary: true,
  cellSize: 90,
  padding: 45,
  outlineWidth: 0,
  lightColor: '#ececec',
  darkColor: '#acacac',
  outlineColor: '#5c5b5b',
  boardColor: '#DEDBCF',
}
