import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export interface ILink {
  to?: string;
  children?: any;
  style?: CSSProperties;
}

const StyledLink: any = styled(RouterLink)`
  color: ${({ theme }) => theme.currentTheme.button};
  font: ${({ theme }) => theme.fonts.normal};
  text-decoration: none;
`;

export function Link(props: ILink) {
  const { children, to} = props;
  return (
    <StyledLink to={to} {...props}>
      {children}
    </StyledLink>
  );
}

