import React from "react";
import { RawSvg } from "../../../../../../../components/Icon/RawSvg";
import bgw from '../../../../../../../svg/images/human-outline-white.svg'
import bgb from '../../../../../../../svg/images/human-outline.svg'

export function CameraCardBackground() {
  return <div style={{
    display: "flex",
    pointerEvents: 'none',
    boxSizing: 'border-box',
    boxShadow: "inset 0px 0 1px 0px  #777"
  }}>
    <RawSvg
      rawSvg={bgb}
      rawSvgDark={bgw}
      style={{ width: "100%" }}
    />
  </div>
}