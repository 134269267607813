import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AppDesktop } from './app/App-Desktop';
import { LocationEvent } from './app/locationEvent';

createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <LocationEvent/>
    <AppDesktop />
  </BrowserRouter>,
);


