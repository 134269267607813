import styled from 'styled-components';
import { Stack } from './Stack';

export const Card = styled(Stack)<{ accent?: boolean }>`
  box-shadow: 1px 1px 5px var(--color-icon-light);
  border-radius: 6px;
  transition: 0.1s;

  &:hover {
    box-shadow: 3px 3px 5px var(--color-icon-light);
    transform: 
              translate(-1px, -2px) 
              rotate3d(1, 2, 1, -1deg)
    ;
  }

  box-sizing: border-box;
  overflow: clip;
  border: 2px solid ${p => p.accent ? `var(--color-accent)` : 'transparent'};

  background: ${p => p.accent ?
          'radial-gradient(ellipse at -10px -30px, var(--color-accent), transparent 150px)' :
          'transparent'
  };

`;





