import {useState} from "react";

export function useStoredState<T>(key: string, defaultValue?: T){

    const item = 'chessclub-useStoredState-' + key;
    const stored = localStorage.getItem(item);
    const initial = stored ? JSON.parse(stored) : defaultValue;
    const [value, setValue] = useState(initial);

    return [
        value,
        (value: T) => {
            setValue(value);
            localStorage.setItem(item, JSON.stringify(value))
        }
    ]
}
