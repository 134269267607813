import styled from 'styled-components';

export const PopupMenuSeparator = styled.hr`
  width: 100%;
  padding: 0;
  margin: 5px 0;
  border-color: transparent;
  background-color: ${({ theme }) => theme.currentTheme.iconLight} !important;
  border-bottom: 0;
  opacity: 0.5;
`;