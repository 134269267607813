import React, { CSSProperties, FunctionComponent } from 'react';
import styled from 'styled-components';

enum ButtonTypes {
  primary = 'primary',
  secondary = 'secondary',
  auth = 'auth',
  notes = 'notes',
  warning = 'warning',
}

type ButtonType = keyof typeof ButtonTypes;

export interface IButton {
  children?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  type?: ButtonType | any;
  onClick?: any;
  style?: CSSProperties;
  silverStyle?: boolean;
}

const StyledButton = styled.button<IButton>`
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : null)}

  text-align: center;
  border-radius: 12px;
  border: 1px solid transparent;
  outline: none;

  padding: ${({ theme }) => `${theme.spacing.medium}px`};
  background: ${({ theme }) => theme.currentTheme.button};
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.colors.common.white};

  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.currentTheme.buttonHover};
  }

  ${({ theme, disabled }) => (disabled ? `background: ${theme.currentTheme.iconLight} !important;` : null)}
  ${({ disabled }) => disabled && 'pointer-events:none;'}
`;

const StyledAuthButton = styled(StyledButton)`
  background: ${({ theme }) => theme.currentTheme.hover} !important;
  color: ${({ theme }) => theme.currentTheme.iconMain};
`;

const StyledNotesButton = styled(StyledButton)`
  background: ${({ theme }) => theme.colors.common.yellow} !important;
  color: ${({ theme }) => theme.colors.common.black};
  border-color: ${({ theme }) => theme.currentTheme.iconMain};
`;

const StyledSecondaryButton = styled(StyledButton)`
  background: ${({ theme }) => theme.currentTheme.hover};
  color: ${({ theme }) => theme.currentTheme.iconMain};

  &:hover {
    background: ${({ theme }) => theme.currentTheme.iconLight};
  }
`;

const StyledWarningButton = styled(StyledButton)`
  background: ${({ theme }) => theme.currentTheme.error1};
  color: ${({ theme }) => theme.colors.common.white};

  &:hover {
    background: ${({ theme }) => theme.currentTheme.error1 + '99'};
  }
`;

// todo
export const Button: FunctionComponent<IButton> = ({
  children = null,
  fullWidth,
  disabled,
  type = 'primary',
  ...props
}) => {

  if (type === 'secondary')
    return (
      <StyledSecondaryButton disabled={disabled} fullWidth={fullWidth} type={type} {...props}>
        {children}
      </StyledSecondaryButton>
    );
  if (type === 'warning')
    return (
      <StyledWarningButton disabled={disabled} fullWidth={fullWidth} type={type} {...props}>
        {children}
      </StyledWarningButton>
    );
  if (type === 'auth')
    return (
      <StyledAuthButton disabled={disabled} fullWidth={fullWidth} type={type} {...props}>
        {children}
      </StyledAuthButton>
    );
  if (type === 'notes')
    return (
      <StyledNotesButton disabled={disabled} fullWidth={fullWidth} type={type} {...props}>
        {children}
      </StyledNotesButton>
    );
  else
    return (
      <StyledButton disabled={disabled} fullWidth={fullWidth} type={type} {...props}>
        {children}
      </StyledButton>
    );
};
