import React from 'react';
import { LessonSideBarTabProps } from './LessonSideBarTabProps';
import { LessonToolbarButton } from '../LessonToolbarButton';
import { SvgImage } from '../../../../../components/Icon/SvgImage';
import { useLessonUiStore } from '../../../../../store/lesson-ui/LessonUiStoreImpl';
import { DndType, useDndStore } from '../../../../../store/dndStore';

export function LessonSideBarTabButton(tab: LessonSideBarTabProps) {
  const {toggleTab, tabsLayout} = useLessonUiStore();
  const {startDrag} = useDndStore();
  const dragStart = e => startDrag({type: DndType.LESSON_TAB, data: tab.type});
  const click = () => toggleTab(tab.type);
  return <LessonToolbarButton onClick={click} draggable onDragStart={dragStart}>
    <SvgImage icon={tab.icon} filled={tabsLayout.visible[tab.type]}/>
  </LessonToolbarButton>
}