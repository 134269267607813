import React, { CSSProperties } from 'react';
import { StyledButton } from '../ChessTools/common';
import styled from 'styled-components';
import { SvgImage } from '../Icon/SvgImage';
import { KnownIcons } from '../Icon/KnownIcons';
import { PlayPause } from '../AnimatedIcon/variants/PlayPause';
import { Lamp } from '../AnimatedIcon/variants/Lamp';
import { Stopwatch } from '../AnimatedIcon/variants/Stopwatch';
import { ChessRules } from '../AnimatedIcon/variants/ChessRules';

const StyledPanel = styled.div`
  display: flex;
  padding-top: 10px;
  justify-content: center;
`;

export interface UnderChessboardPanelProps {
  style?:CSSProperties;

  isReversed: boolean;
  reverse();

  isRulesOn: boolean;
  toggleRules();

  isLegalMovesOn: boolean;
  toggleLegalMoves();

  isTimerOn: boolean;
  toggleTimer();

  isGameRunning: boolean;
  toggleGameRunning();

  clearMarkers();
}

export function UnderChessboardPanel(props: UnderChessboardPanelProps) {
  return <StyledPanel className='under-chessboard-panel' style={props.style}>

    <StyledButton onClick={props.clearMarkers}>
      <SvgImage icon={KnownIcons.trash}/>
    </StyledButton>

    <StyledButton onClick={props.reverse}>
      <SvgImage icon={KnownIcons.rotate} filled={props.isReversed}/>
    </StyledButton>

    <StyledButton onClick={props.toggleRules}>
      <ChessRules active={props.isRulesOn} />
    </StyledButton>

    <StyledButton onClick={props.toggleLegalMoves}>
      <Lamp active={props.isLegalMovesOn} />
    </StyledButton>

    <StyledButton onClick={props.toggleTimer}>
      <Stopwatch active={props.isTimerOn}/>
      {/*<SvgImage icon={KnownIcons.stopwatch} filled={props.isTimerOn}/>*/}
    </StyledButton>

    <StyledButton onClick={props.toggleGameRunning}>
      <PlayPause active={props.isGameRunning}/>
    </StyledButton>

  </StyledPanel>;
}



