import { TaskId } from '@chessclub/grpc_wrapper';
import { FullMoveDescription } from '@chessclub/web-game-server/src/shared/core/calcMoves';

export enum LessonViewMode {
  STANDARD = 'standard',
  CAMERAS = 'all-cameras',
  CHESSBOARDS = 'all-chessboards'
}

export enum MovesDisplayMode {
  TABLE = 'table',
  CHAIN = 'chain',
  TEXT = 'text'
}

export enum PiecesColor {
  WHITE = 'w',
  BLACK = 'b'
}

export enum LayoutSlots {
  TOP_LEFT = 'topLeft',
  BOTTOM_LEFT = 'bottomLeft',
  TOP_RIGHT = 'topRight',
  BOTTOM_RIGHT = 'bottomRight'
}

export enum LessonTabs {
  CAMS = 'cams',
  CHAT = 'chat',
  TASKS = 'tasks',
  MOVES = 'moves'
}

export interface TabsLayout {
  slots: { [key in LayoutSlots]: LessonTabs[] };
  tabs: { [key in LessonTabs]: LayoutSlots };
  visible: { [key in LessonTabs]: boolean };
}

export interface LessonUiStoreState {
  mouseOverMove?: FullMoveDescription & {index: number};
  tabsLayout: TabsLayout;
  view: LessonViewMode;
  movesMode: MovesDisplayMode;
  boardRotations: { [id: TaskId]: boolean };
}

export interface LessonUiStoreActions {
  setMouseOverStep(mouseOverMove: FullMoveDescription & {index:number}): void;
  setView(view: LessonViewMode): void;
  setMovesDisplayMode(mdm: MovesDisplayMode): void;
  rotateBoard(id: TaskId): void;
  setBoardRotation(id: TaskId, isRotated: boolean): void;
  toggleTab(tab: LessonTabs): void;
  changeLayout(targetSlot: LayoutSlots, tab: LessonTabs);
}

export type LessonUiStore = LessonUiStoreState & LessonUiStoreActions;