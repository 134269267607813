import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';

export function DemoIcon(props: SvgIconProps) {
  return <SvgIcon {...props} colored>
    <rect x='5' y='7' width='22' height='18' rx='3' className="filled"/>
    <g className="bgStroke">
      <path d='M16 14L16 21' />
      <path d='M12 16L16 12L20 16' />
    </g>
  </SvgIcon>;
}

