import { ConfirmationPopupMenu } from '../PopupMenu/ConfirmationPopupMenu';
import { PropsWithChildren } from 'react';
import { useLessonStore } from '../../store/lesson/LessonStore';
import { useRoomsStore } from '../../store/rooms/RoomsStore';
import React from 'react';
import { RoomId } from '@chessclub/grpc_wrapper';

export function OnlineButton(props: PropsWithChildren<{ roomId: RoomId }>) {

  const { items, goOffline: offRoom, goOnline: onRoom } = useRoomsStore();
  const { goOffline: offLesson, goOnline: onLesson } = useLessonStore();
  const room = items.find(room => room.id === props.roomId);

  const toggle = async (e) => {

    e && e.stopPropagation()
    e && e.preventDefault()

    if (room.isActive) {
      await offRoom(props.roomId);
      await offLesson(props.roomId);
    } else {
      await onRoom(props.roomId);
      await onLesson(props.roomId);
    }
  };

  if (!room?.isActive || !room?.anyStudentPresents) {
    return <div onClick={toggle}>
      {props.children}
    </div>
  }

  return <ConfirmationPopupMenu
    text={'Выйти?'}
    action={toggle}
  >
    {props.children}
  </ConfirmationPopupMenu>;
}

