import { randomFen } from '../../logic/randomFen';
import { IMaterial } from '@chessclub/grpc_wrapper/src/api/types/IMaterial';
import { BoardLabels, TaskType } from '@chessclub/grpc_wrapper/src/api/types/QuestionBase';

export function newMaterial() : IMaterial {
  return {
    fen: randomFen(),
    title: "new material",
    moves:[],
    tools: {},
    rank: 0,
    type: TaskType.TASK,
    customData: {
      boardLabels: BoardLabels.OUTSIDE,
      rulesSettingOn: false,
      highlightMovesSettingOn: false,
      timerSettingOn: false
    }
  }
}