import React, { CSSProperties } from 'react';
import { Grid } from '@material-ui/core';
import { IQuestion } from '../../interfaces/question';
import { StyledCastling, StyledCastlings, StyledRedDiagonalLine } from './CastlingStyledComponents';
import { Stack } from '../low-level-components/Stack';
import { QuestionBase } from '@chessclub/grpc_wrapper';

export function Castling({ question, edit }: {
  question: QuestionBase;
  edit?:(string) => void;
}) {

  const castling = question?.fen.trim().split(" ")[2];
  const p = { castling, edit }

  const style : CSSProperties = {
    padding: '0 0 5px 5px',
    pointerEvents: edit ? "all" : "none",
    // justifyContent: "space-between"
    gap: 50
  };

  return <Grid container style={style}>
    <StyledCastlings>
      <OneCastling {...p} type={"K"}/>
      <OneCastling {...p} type={"Q"}/>
    </StyledCastlings>
    <StyledCastlings>
      <OneCastling {...p} type={"k"}/>
      <OneCastling {...p} type={"q"}/>
    </StyledCastlings>
  </Grid>;
}


export function OneCastling({ type, castling, edit } : {
  type: string;
  castling: string;
  edit?: Function;
}) {
  const disabled = !castling?.includes(type);
  const onClick = edit ? () => edit(type) : null;
  const transform = disabled ? "scale(1)" : "scale(0)";
  const text = type.toLowerCase() === "q" ? "0-0-0" : "0-0"
  return <Stack onClick={onClick}>
    <StyledCastling disabled={disabled}>
      {text}
    </StyledCastling>
    <StyledRedDiagonalLine style={{transform}}/>
  </Stack>
}
