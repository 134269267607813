import Dexie from 'dexie';
import { ITopic, UserId } from '@chessclub/grpc_wrapper';
import { MaterialSearchParams } from '@chessclub/grpc_wrapper/src/api/types/IMaterial';

const dexie = new Dexie('chessclub--materials');

dexie.version(1).stores({
  selectedTopic: 'userId',
  searchCriteria: 'userId'
});


const selectedTopic: Dexie.Table
  // @ts-ignore
  = dexie.selectedTopic;

export const selectedTopicDb = {
  async getSelectedTopic(userId: UserId) : Promise<ITopic> {
    return (await selectedTopic.get(userId))?.topic;
  },

  async saveSelectedTopic(userId: UserId, topic: ITopic) {
    await selectedTopic.put({topic, userId});
  },

  async deleteSelectedTopic(userId: UserId) {
    await selectedTopic.delete(userId);
  },
}





const searchCriteria: Dexie.Table
  // @ts-ignore
  = dexie.searchCriteria;

export const searchCriteriaDb = {

  async createMaterial(search: MaterialSearchParams): Promise<void> {
    return searchCriteria.put(search);
  },


  async getSearchCriteria(userId: UserId): Promise<MaterialSearchParams> {
    return searchCriteria
      .where('userId').equals(userId)
      .toArray()[0];
  },



}