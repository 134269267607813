import { emitCentrifugeEvent } from '@chessclub/realtime_infrastructure';
import { makeRoomChannelKey, RoomChannel } from '../../transport/messaging/RoomChannel';
import { useRoomsStore } from '../rooms/RoomsStore';
import { ServerApi } from '../../transport/ServerApi';
import { useAuthStore } from '../auth/AuthStore';

export async function uploadAvatar(userId, profileImage) {

  const avatar = await ServerApi.profileService.setProfileImage(profileImage, userId);

  useRoomsStore.getState().items.forEach(room => {
    room.isActive && emitCentrifugeEvent(makeRoomChannelKey(room.id),
      RoomChannel.USER_PROFILE_CHANGED, { userId, avatar: avatar.url });
  });

  let { setUser, user } = useAuthStore.getState();
  setUser( {...user, imageUrl: avatar.url})
}