import React from 'react';
import { useLocalization } from '../../../l10n/UseLocalization';
import { PopupMenuItem } from '../../PopupMenu/PopupMenuItem';
import { KnownIcons } from '../../Icon/KnownIcons';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import { getMNfromFen } from '@chessclub/web-game-server/src/shared/core/chess_ts';

export function ChessboardSizesDropdown() {
  const { lesson } = useLocalization();
  const { updateSize, editingTask } = useEditingTaskStore()
  const { N } = getMNfromFen(editingTask.fen)
  return <>
    <PopupMenuItem active={N === 8} text={lesson.sizeStandard} icon={KnownIcons.x8} onClick={() => updateSize(8)}/>
    <PopupMenuItem active={N === 10} text={lesson.sizeLarge} icon={KnownIcons.x10}  onClick={() => updateSize(10)}/>
    <PopupMenuItem active={N === 16} text={lesson.sizeHuge} icon={KnownIcons.x16} onClick={() => updateSize(16)}/>
  </>
}


