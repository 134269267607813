import { L10n } from './L10n';

export const Lang_cn : L10n = {
  "landing": {
    "mainTitle": "新的学习高度",
    "mainSubTitle": "国际象棋远程学习平台",
    "aboutUs": "关于我们",
    "aboutUsText": "ChessClub Training平台的使命是使学习过程尽可能舒适、高效和有趣，适合教师和学生。",
    "ourCapabilitiesTitle": "我们的能力",
    "ourCapabilitiesElements": [
      ["/img/landing/icons/crown.svg", "进行个人和小组教学"],
      ["/img/landing/icons/crown.svg", "游戏化和实施新的游戏形式在儿童国际象棋教学中"],
      ["/img/landing/icons/crown.svg", "在线举办比赛的能力"],
      ["/img/landing/icons/crown.svg", "教师/教练的扩展工具包"],
      ["/img/landing/icons/crown.svg", "远程学习具有存在感"]
    ],
    "ourPlatformTitle": "我们的平台适合您，如果您:",
    "ourPlatformElements": [
      ["/img/landing/icons/knight.svg", "国际象棋教育自由职业者"],
      ["/img/landing/icons/rook.svg", "国际象棋俱乐部教师"],
      ["/img/landing/icons/king.svg", "专业国际象棋教练"]
    ],
    "ourAdvantagesTitle": "我们的优势",
    "ourAdvantagesElements": [
      ["/img/landing/icons/pawns-3.svg", "进行不同年龄组教学的能力"],
      ["/img/landing/icons/ai.svg", "人工智能技术的应用"],
      ["/img/landing/icons/pawns-6.svg", "在线小组教学的能力"],
      ["/img/landing/icons/pc.svg", "适应PC、笔记本电脑、智能手机、平板电脑、移动版本的界面"],
      ["/img/landing/icons/two-knights.svg", "在线举办比赛的能力"],
      ["/img/landing/icons/arrows.svg", "虚拟指针与光标同步"],
      ["/img/landing/icons/lamp.svg", "公平游戏控制"],
      ["/img/landing/icons/server.svg", "与TimeWeb服务的协同"]
    ],
    "ourExpertsTitle": "我们的专家",
    "ourExpertsElements": [
      ["/img/landing/experts/vladimir-bykov.png", "<b>Владимир Быков</b><br/><br/>圣彼得堡国际象棋联合会执行董事，「 Медный Всадник」队长"],
      ["/img/landing/experts/ekaterina-sudoplatova.png", "<b>Екатерина Судоплатова</b><br/><br/>国际女子特级大师，国际男子大师。俄罗斯双冠军和世界女子冠军，俄罗斯国家队成员，俄罗斯女子冠军，圣彼得堡多次冠军。教练，非正式活动教师，额外教育教师"],
      ["/img/landing/experts/alina-balayan.png", "<b>Алина Балаян</b><br/><br/>国际女子大师，俄罗斯体育大师。俄罗斯女子冠军，俄罗斯女子快速冠军，圣彼得堡多次冠军。教练，额外教育教师"],
      ["/img/landing/experts/valeriy-popov.png", "<b>Валерий Попов</b><br/><br/>国际特级大师，高级教练，欧洲冠军，圣彼得堡国际象棋学校高级教练"],
      ["/img/landing/experts/dina-belenkaya.png", "<b>Дина Беленькая</b><br/><br/>女子国际特级大师，圣彼得堡多次冠军，俄罗斯国家队成员，国际象棋教师"],
      ["/img/landing/experts/anastasia-bondaruk.png", "<b>Анастасия Боднарук</b><br/><br/>国际大师，圣彼得堡和俄罗斯多次冠军，俄罗斯国家队成员"]
    ]
  },
  "footer": {
    "help": "帮助",
    "contacts": "联系我们",
    "supportUs": "支持我们",
    "termsOfService": "服务条款"
  },
  "lesson": {
    "newTask": "new task",
    "chain": "链",
    "table": "表",
    "clear": "清除",
    "room": "房间",
    "defaultRoom": "默认房间",
    "ok": "确定",
    "empty": "空",
    "sizeHuge": "巨大",
    "sizeLarge": "大",
    "sizeStandard": "标准",
    "markersBlue": "蓝色标记",
    "markersGreen": "绿色标记",
    "markersOff": "关闭标记",
    "markersRed": "红色标记",
    "markersDelete": "删除标记",
    "withoutTitles": "无标题",
    "titlesAtCellCorners": "单元格角标题",
    "titlesInEveryCell": "每个单元格标题",
    "titlesAroundChessboard": "围棋盘标题",
    "standardView": "标准视图",
    "allChessboardsView": "所有棋盘视图",
    "allCamsView": "所有摄像头视图",
    "size": "大小",
    "exit": "退出",
    "materials": "知识库",
    "rooms": "房间",
    "profile": "个人资料",
    "edit": "编辑",
    "paste": "粘贴",
    "copy": "复制",
    "save": "保存",
    "cancel": "取消",
    "delete": "删除",
    "add": "添加",
    "waitForTrainer": "等待教练加入",
    "chat": "聊天",
    "cams": "摄像头",
    "online": "在线",
    "view": "视图",
    "demonstration": "示范",
    "settings": "设置",
    "offline": "离线",
    "black": "黑棋",
    "white": "白棋",
    "tasks": "任务",
    "moves": "步骤",
    "activeLessons": "在线课程",
    "activeLesson": "在线课程",
    "student": "学生",
    "trainer": "教练",
    "lesson": "课程"
  },
  "captcha": {
    "language": "简体中文",
    "errors": {
      "0": "未发现错误",
      "3": "验证码参数错误",
      "501": "验证码不存在",
      "502": "验证码参数错误",
      "503": "验证码邮箱错误",
      "504": "验证码过期",
      "505": "验证码答案过期",
      "506": "验证码答案错误"
    }
  },
  "auth": {
    "new_password": "New password",
    "repeat_new_password": "Repeat new password",
    "consider_new_password": "Create a new password and enter the code sent to your email for confirmation",
    "confirm": "Confirm",
    "agree_with": "I agree with",
    "terms": "Terms of service",
    "do_sign_up": "Sign up",
    "continue": "Continue",
    "code": "Code from email",
    "email_to_restore": "Enter email address to restore access",
    "receive_code_in_mail": "Get code in email",
    "repeat_password": "Repeat password",
    "login_with": "使用以下方式登录",
    "login": "登录",
    "forgot_password": "忘记密码",
    "or": "或",
    "email": "电子邮件",
    "password": "密码",
    "no_account_yet": "还没有账户?",
    "sign_up": "注册",
    "yandex": "Яндекс",
    "ok": " Одноклассники",
    "mail": "Майл.ру",
    "fb": "Facebook",
    "twitter": "Twitter",
    "vk": "Вконтакте",
    "google": "Google",
    "choose_lg": "选择语言",
    "language": "简体中文",
    "errors": {
      "0": "无错误",
      "1": "用户不存在",
      "2": "用户已经存在",
      "3": "访问参数错误",
      "4": "用户未激活",
      "5": "密码错误",
      "6": "激活码错误",
      "7": "会话标识符错误",
      "8": "用户未认证",
      "9": "恢复码错误",
      "10": "用户已经激活",
      "11": "认证码错误",
      "12": "设置错误",
      "13": "社交媒体提供商错误",
      "14": "密码太弱",
      "15": "获取用户信息错误",
      "16": "OAuth应用程序已经存在",
      "17": "OAuth应用程序不存在",
      "18": "OAuth消费者不存在",
      "19": "OAuth应用程序数量达到最大值",
      "20": "OAuth授权码不存在",
      "21": "OAuth访问令牌不存在",
      "22": "SMTP邮件发送错误",
      "23": "图像类型错误",
      "24": "图像发送错误"
    }
  },
  "permissions": {
    "OtherError": {
      "otherError": "您的摄像头不可用。请使用其他设备。"
    },
    "BlockedAtSystemLevelMacOs": {
      "camera": "摄像头未连接或{{browserName}}被操作系统级别阻止访问摄像头。",
      "check": "请检查摄像头是否在其他应用程序中工作正常（例如 Photo Booth）。如果摄像头工作正常，请确保{{browserName}}有权访问摄像头。",
      "p1_menu": "选择 Apple 菜单",
      "p1_pref": "→ «系统偏好设置»，点击 «安全性与隐私»，然后点击 «隐私»;",
      "p2": "选择 «摄像头」，在{{browserName}}应用程序旁边勾选框;",
      "p3": "选择 «麦克风」，在{{browserName}}应用程序旁边勾选框;",
      "p4": "重新启动{{browserName}}，然后再次尝试进行面试。",
      "more": "在 Apple 支持网站上了解更多信息",
      "support": "Apple 支持"
    },
    "NoSoundWindowsFirefox": {
      "sureConnected": "如果您确信您的麦克风已连接，可能是 Windows 的隐私设置阻止了访问。",
      "goToStart": "转到开始",
      "parameters": "参数",
      "privacy": "隐私",
      "mic": "麦克风。确保:",
      "p1": "麦克风设备的访问权限已启用;",
      "p2": "«允许应用程序访问麦克风»处于启用状态;",
      "p3": "«允许经典应用程序访问麦克风»（如果存在）处于启用状态;",
      "p4": "更改设置后，请刷新页面。",
      "more": "在 Microsoft 支持网站上了解更多信息",
      "support": "Microsoft 支持"
    },
    "NotAllowedAndroidChrome": {
      "check": "无法获取摄像头和麦克风的访问权限。",
      "p1": "在网站地址左侧点击锁定图标",
      "p2": "点击权限部分;",
      "p3": "允许摄像头和麦克风的访问权限;",
      "p4": "刷新页面。",
      "i1": "如果您不见权限部分，意味着 Android 操作系统级别阻止了摄像头和麦克风的访问权限。在 Android 设置的应用程序部分，确保 Chrome 具有摄像头和麦克风的访问权限。然后，返回 Chrome 并刷新页面。更多信息请访问",
      "more": "Android 支持网站"
    },
    "NotAllowedBlink": {
      "check": "您未授予摄像头或麦克风的访问权限。",
      "p1": "在网站地址左侧点击锁定图标",
      "p2": "允许摄像头和麦克风的访问权限;",
      "p3": "刷新页面",
      "p3_check": "或点击这里"
    },
    "NotAllowedBlinkWindowsSystem": {
      "check": "无法获取麦克风/摄像头的访问权限。可能是 Windows 10 的隐私设置阻止了访问。",
      "goToStart": "转到开始",
      "parameters": "参数",
      "privacy": "隐私",
      "cameraAndSure": "转到麦克风部分，然后转到摄像头部分，确保:",
      "p1": "麦克风/摄像头设备的访问权限已启用;",
      "p2": "«允许应用程序访问麦克风»/«允许应用程序访问摄像头»处于启用状态;",
      "p3": "«允许经典应用程序访问麦克风»/«允许经典应用程序访问摄像头»（如果存在）处于启用状态;",
      "p4": "更改设置后，请刷新页面。",
      "more": "在 Microsoft 支持网站上了解更多信息",
      "support": "Microsoft 支持"
    },
    "NotAllowedFirefox": {
      "check": "您未授予摄像头或麦克风的访问权限。",
      "p1": "在网站地址左侧点击带叉号的摄像头或麦克风图标;",
      "p2": "重置摄像头和麦克风的访问权限;",
      "p3": "刷新页面;",
      "p4": "点击允许，当浏览器再次请求访问。",
      "more": "如果建议不起作用，请使用",
      "support": "Firefox 文档"
    },
    "NotAllowedMacOsSafari": {
      "check": "您未授予摄像头或麦克风的访问权限。",
      "p1": "选择 Safari 菜单 → «设置»，然后点击 «网站»;",
      "p2": "依次选择摄像头和麦克风，并将 «允许» 设置为 huntica.works 网站;",
      "p3": "关闭设置并刷新页面。",
      "more": "在 Apple 支持网站上了解更多信息",
      "support": "Apple 支持"
    },
    "NotConnected": {
      "notConnected": "您的摄像头似乎未连接。请连接摄像头。"
    },
    "NotReadableErrorAudioWindows": {
      "check": "无法获取麦克风的访问权限。可能是 Windows 10 的隐私设置阻止了访问。",
      "goToStart": "转到开始",
      "parameters": "参数",
      "privacy": "隐私",
      "mic": "麦克风。确保:",
      "p1": "麦克风设备的访问权限已启用;",
      "p2": "«允许应用程序访问麦克风»处于启用状态;",
      "p3": "«允许经典应用程序访问麦克风»（如果存在）处于启用状态;",
      "p4": "更改设置后，请刷新页面。",
      "more": "在 Microsoft 支持网站上了解更多信息",
      "support": "Microsoft 支持"
    },
    "NotReadableErrorVideoWindows": {
      "check": "无法获取摄像头的访问权限。可能的原因:",
      "p1": "摄像头当前被其他应用程序使用（例如 Skype 或 Zoom）。通常在这种情况下，摄像头上会有指示灯。关闭所有可能使用摄像头的应用程序，然后刷新页面。",
      "p2": "如果您确信摄像头当前未被使用，那么访问可能被 Windows 10 的隐私设置阻止。",
      "goToStart": "转到开始",
      "parameters": "参数",
      "privacy": "隐私",
      "cameraAndSure": "摄像头和确保:",
      "a": "摄像头设备的访问权限已启用;",
      "b": "«允许应用程序访问摄像头»处于启用状态;",
      "c": "«允许经典应用程序访问摄像头»（如果存在）处于启用状态;",
      "d": "更改设置后，请刷新页面。",
      "more": "在 Microsoft 支持网站上了解更多信息",
      "support": "Microsoft 支持"
    }
  },
  "rooms": {
    "rooms": "房间"
  }
};