import React from 'react';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { LandingPageStyledComponents } from './LadingPageStyledComponents';
import { useLocalization } from '../../l10n/UseLocalization';
import { GenerativeBackground } from './GenerativeBackground';
import { Space } from '../../components/low-level-components/Space';
import { LogoSize } from '../../components/Icon/Logo';

const {
  Section, Title, MainSubTitle, MainTitle, CardCapabilities, CardOurPlatform, CardAdvantages, CardExpert, CardList,
} = LandingPageStyledComponents;

export function LandingPage() {
  const { landing } = useLocalization();
  return <GenerativeBackground>
    <PageTemplate >

      <div style={{ maxWidth: 980, margin: '0 auto' }}>

        <Space size={122} />
        <MainTitle text={landing.mainTitle} />
        <MainSubTitle text={landing.mainSubTitle} />
        <Space size={400} />

        {/*<Section justify={"center"}>*/}
        {/*  <svg width='121' height='40' viewBox='0 0 121 40'>*/}
        {/*    <path d='M10 10L59.4585 30L110.5 10' stroke='#939393' fill='none' strokeWidth='3' />*/}
        {/*  </svg>*/}
        {/*</Section>*/}

        <Space size={300} />

        <Title text={landing.ourCapabilitiesTitle} />
        <CardList justify={"center"} elements={landing.ourCapabilitiesElements} card={CardCapabilities} />

        <Space size={300} />

        <Title text={landing.ourPlatformTitle} style={{textAlign: "right"}} />
        <CardList justify={"right"} elements={landing.ourPlatformElements} card={CardOurPlatform} />

        <Space size={300} />

        <Title text={landing.ourAdvantagesTitle} />
        <CardList justify={"left"} elements={landing.ourAdvantagesElements} card={CardAdvantages} />

        <Space size={300} />

        <Space size={300} />

        <Title text={landing.ourExpertsTitle} />
        <CardList justify={"center"} elements={landing.ourExpertsElements} card={CardExpert} />

        <Space size={100} />

      </div>

    </PageTemplate>
  </GenerativeBackground>;
}

