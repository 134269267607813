import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 20px;
  
  & .left {
    display: flex;
    margin: 0;
    padding: 0;
    font: ${({ theme }) => theme.fonts.bigBold};
    color: ${({ theme }) => theme.currentTheme.textMain};
  }
  
  & > .right {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export function EditViewHeader(props: PropsWithChildren<{text: string}>) {
  return <StyledWrapper>
    <div className='left'>
      {props.text}
    </div>
    <div className='right'>
      {props.children}
    </div>
  </StyledWrapper>;
}