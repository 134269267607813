import React, { CSSProperties, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { PreloaderAnimation1 } from './PreloaderAnimation1';
import { PreloaderAnimation2 } from './PreloaderAnimation2';

const thickness = 1.5;
const duration = 5;
const dashes = 5;

const gradient = [...Array(dashes)].map((el, i, all) => {
  return `${i % 2 ? 'steelblue' : 'transparent'} ${100 * i / (all.length - 1)}%`;
});

const Animation = styled.div`
  @keyframes preloader-horizontal {
    0% {
      background-position: 100vw 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  @keyframes preloader-vertical {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 0 100vh;
    }
  }
`;


const horizontal: CSSProperties = {
  position: 'fixed',
  height: thickness,
  width: '100vw',
  animation: `preloader-horizontal ${duration}s infinite linear`,
  background: `linear-gradient(to right, ${gradient})`,
};

const vertical: CSSProperties = {
  position: 'fixed',
  width: thickness,
  height: '100vh',
  animation: `preloader-vertical ${duration}s infinite linear`,
  background: `linear-gradient(to bottom, ${gradient})`,
};

export function Preloader() {
  return <Animation>
    <div style={{ ...horizontal, animationDirection: 'reverse' }} />
    <div style={{ ...vertical, right: 0 }} />
    <div style={{ ...horizontal, bottom: 0 }} />
    <div style={{ ...vertical, animationDirection: 'reverse' }} />
    {/*<PreloaderAnimation2/>*/}
    <PreloaderAnimation1/>
  </Animation>;
}

