import { create } from 'zustand';
import { AdminKnowledgesStoreActions, AdminKnowledgesStoreState } from './AdminKnowledgesStoreApi';
import { IMaterial } from '@chessclub/grpc_wrapper/src/api/types/IMaterial';
import { ServerApi } from '../../transport/ServerApi';
import { replaceById } from '../../helpers/replaceById';
import { ITopic } from '@chessclub/grpc_wrapper';

type AdminKnowledgesStore = AdminKnowledgesStoreState & AdminKnowledgesStoreActions;

export const useAdminKnowledgesStore = create<AdminKnowledgesStore>((
  set,
  get,
) => {

  init()

  async function init() {
    const topics = await ServerApi.knowledgeService.getKnowledgeTopicAll();
    const selectedTopic = topics[0];
    const knowledges = await loadKnowledges(selectedTopic);
    set({ topics, selectedTopic, knowledges });
  }

  async function loadKnowledges(selectedTopic: ITopic): Promise<IMaterial[]> {
    return selectedTopic ?
      await ServerApi.knowledgeService.getNextKnowledgeByTopicId(selectedTopic.id, null, 11) : [];
  }

  return {
    topics: [],
    knowledges: [],

    async selectTopic(selectedTopic: ITopic) {
      const knowledges = await loadKnowledges(selectedTopic);
      set({ selectedTopic, knowledges });
    },

    async putTopic(topic: ITopic) {
      if (topic.id) {
        await ServerApi.knowledgeService.updateKnowledgeTopic(topic);
        set({topics: replaceById(get().topics, topic)});
      } else {
        topic.id = await ServerApi.knowledgeService.createKnowledgeTopic(topic);
        set({topics: [...get().topics, topic]});
      }
    },

    async putKnowledge(knowledge: IMaterial) {
      if (knowledge.id) {
        await ServerApi.knowledgeService.updateKnowledgeFields(knowledge);
        set({knowledges: replaceById(get().knowledges, knowledge)})
      } else {
        knowledge.topicId = get().selectedTopic?.id
        knowledge.id = await ServerApi.knowledgeService.createKnowledge(knowledge);
        set({knowledges: [...get().knowledges, knowledge]})
      }
    },

    async deleteTopic(topic: ITopic) {
      await ServerApi.knowledgeService.deleteKnowledgeTopicById(topic.id);
      set({ topics: get().topics.filter(t => t.id !== topic.id) });
    },

    async deleteKnowledge(knowledge: IMaterial) {
      await ServerApi.knowledgeService.deleteKnowledgeById(knowledge.id);
      set({ knowledges: get().knowledges.filter(k => k.id !== knowledge.id) });
    },
  };
});