import React from 'react';
import { ApplyCancelToolbarVariant } from '../../../parts/ApplyCancelToolbarVariant';
import { useEditingTopicStore } from '../../../store/editing-topic/EditingTopicStore';
import { useMaterialsStore } from '../../../store/materials/MaterialsStore';

export function MaterialsPageTopicEditToolbarVariant () {

  const { editingTopic, stopEditTopic } = useEditingTopicStore()
  const { putTopic } = useMaterialsStore();

  function applyEditTopic() {
    putTopic(editingTopic)
    stopEditTopic();
  }

  return  <ApplyCancelToolbarVariant
    apply={applyEditTopic}
    cancel={stopEditTopic}
  />
}