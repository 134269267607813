import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import { Resizer } from '../../../components/Resizer/Resizer';
import { ChessboardWithLocalLogic } from '../../../components/Chessboard/ChessboardWithLocalLogic';
import React, { useEffect } from 'react';
import { GameState } from '@chessclub/web-game-server';
import { StyledButton, StyledFigures } from '../../../components/ChessTools/common';
import { SvgImage } from '../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../components/Icon/KnownIcons';
import { PiecesPanel } from '../../../components/ChessTools/PiecesPanel';
import { ChessboardCellsTitleDropdown } from '../../../components/ChessTools/dropdowns/ChessboardCellsTitleDropdown';
import { ChessboardIcon } from '../../../components/Icons';
import { ChessboardSizesDropdown } from '../../../components/ChessTools/dropdowns/ChessboardSizesDropdown';
import { ChessboardExtraPiecesDropdown } from '../../../components/ChessTools/dropdowns/ChessboardExtraPiecesDropdown';
import { Tool } from '../../../components/ChessTools/ChessToolbar';
import { calcMoves } from '@chessclub/web-game-server/src/shared/core/calcMoves';
import { getMNfromFen } from '@chessclub/web-game-server/src/shared/core/chess_ts';
import { useTasksStore } from '../../../store/tasks/TasksStore';

export function TaskEditChessboard() {
  const {setCurrentTaskGameState, setCurrentTaskGameApi,  currentTaskGameApi} = useTasksStore();
  const { editingTask: task, snapshotId, updateEditingTaskData } = useEditingTaskStore();

  useEffect(() => {
    currentTaskGameApi && currentTaskGameApi.setPlayStop(false);
  }, [currentTaskGameApi])

  function updateStore(gs: GameState) {
    if (gs.snapshotId === 'initial')
      return;
    setCurrentTaskGameState(gs)
    // console.log('GameState: ', JSON.stringify(gs, null, 2));
    updateEditingTaskData({
      fen: gs.fen,
      moves: gs.moves,
      tools: gs.markers,
      fullMoveDescriptions: calcMoves(gs.fen, gs.moves),
      moveIndex: gs.moveIndex,
      customData: {
        boardLabels: gs.boardLabels,
        rulesSettingOn: gs.applyRules,
        highlightMovesSettingOn: gs.legalMoves,
        timerSettingOn: gs.isTimerOn,
      },
    });
  }

  let rulesOn = task.customData?.rulesSettingOn;
  let legalMovesOn = task.customData?.highlightMovesSettingOn;
  let timerSettingOn = task.customData?.timerSettingOn;
  // console.log(snapshotId);
  const { N } = getMNfromFen(task.fen)
  return <>
    <div style={{ display: 'flex', justifyContent: 'center' }}>

      <StyledFigures style={{ overflow: 'clip', marginRight: 30 }}>
        <Tool content={ChessboardCellsTitleDropdown}>
          <ChessboardIcon title={task.customData?.boardLabels} />
        </Tool>
        <Tool content={ChessboardSizesDropdown}>
          <SvgImage icon={N === 10 ? KnownIcons.x10 : N === 16? KnownIcons.x16 : KnownIcons.x8} />
        </Tool>
        {/*<Tool content={ChessboardArrowsDropdown}>*/}
        {/*  <SvgImage icon={KnownIcons.arrow} filled color={markerColor(markerHue)}/>*/}
        {/*</Tool>*/}

        <StyledButton onClick={() => currentTaskGameApi.setApplyRules(!rulesOn, true)}>
          <SvgImage icon={KnownIcons.rulemove} filled={rulesOn} />
        </StyledButton>
        <StyledButton onClick={() => currentTaskGameApi.setLegalMoves(!legalMovesOn, true)}>
          <SvgImage icon={KnownIcons.lamp} filled={legalMovesOn} />
        </StyledButton>
        <StyledButton onClick={() => currentTaskGameApi.setUseTimer(!timerSettingOn, true)}>
          <SvgImage icon={KnownIcons.stopwatch} filled={timerSettingOn} />
        </StyledButton>
      </StyledFigures>

      <Tool content={ChessboardExtraPiecesDropdown}>
        <SvgImage icon={KnownIcons.pacman} />
      </Tool>

      <PiecesPanel />
    </div>
    <div>

      <Resizer>
        <ChessboardWithLocalLogic
          onGameApiChanged={setCurrentTaskGameApi}
          onGameStateChanged={updateStore}
          task={{...task, taskId:`${task.id + snapshotId}_TaskId`}}
          reversed={false}
          editMode={true}
        />
      </Resizer>

    </div>
  </>;
}