import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CustomSwitch from '../../components/low-level-components/Switch';
import { Theme, useThemeStore } from '../../store/themeStore';
import { useUiStore } from '../../store/uiStore';
import { useChessboardStore } from '../../store/chessboard/ChessboardStore';
import { InteractivityMode } from '@chessclub/web-game-server/src/client/api';

const StyledSwitchToOSThemeTitle = styled.div`
  margin-right: 2rem;
  color: ${({ theme }) => theme.currentTheme.iconMain};
  font: ${({ theme }) => theme.fonts.normal};
`;

const ButtonWrapperRow = styled.div<{ longStyle?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;

  ${({ longStyle }) =>
    longStyle ? `& > *:first-child { margin-right: 16px; }` : `& > *:not(last-child) { margin-right: 16px; }`}
`;

const StyledButton = styled.button<IStyledButton>`
  background: ${({ active, theme }) => (active ? theme.currentTheme.hover : 'transparent')};
  outline: none;
  border: 1px solid ${({ theme }) => theme.currentTheme.hover};
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  // justify-content: center;
  cursor: pointer;
  padding: 12px;
  ${({ disabled }) => disabled && `opacity: 0.5; cursor: default;`}

  ${({ longStyle }) =>
    longStyle &&
    `width: 228px;height: 48px; padding-right: 16px;

     & *:nth-child(2) {
       margin-left: 8px;
     }
  `}

  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};

  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : null)};

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.currentTheme.hover};
  }
`;

const StyledCircle = styled.span<{ color?: string }>`
  width: 24px;
  height: 24px;
  border: 1.25px solid #868686;
  border-radius: 50%;
  box-sizing: border-box;

  ${({ color, theme }) => {
    return color && ` background: ${theme.currentTheme[color]}`;
  }}
`;

const StyledImg = styled.img`
  width: 24px;
  height: 24px;
`;

interface IStyledButton {
  active?: boolean;
  fullWidth?: boolean;
  longStyle?: boolean;
  disabled?: boolean;
}

interface IProps {}

type InterfaceClr = 'sky' | 'orange' | 'green' | 'violet';

const defaultAutodetectTheme = false;
const isAutodetectedDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

export const ThemeTab: FunctionComponent<IProps> = () => {
  const {interactivityMode, setInteractivityMode} =useChessboardStore();
  const {  interfaceClr, setInterfaceCLr } = useUiStore();
  const { theme, setTheme } = useThemeStore();

  const [autodetectTheme, setAutodetectTheme] = useState<boolean>(defaultAutodetectTheme);

  const renderNumber = useRef<number>(1);

  useEffect(() => {
    if (renderNumber.current > 1) {
      setTheme(isAutodetectedDarkMode ? Theme.dark : Theme.light);
    } else {
      renderNumber.current = renderNumber.current + 1;
    }
  }, [autodetectTheme, setTheme]);

  const toggleAutoDetectTheme = () => setAutodetectTheme((prev) => !prev);
  const setLightTheme = (e) => setTheme(Theme.light, {x:e.clientX, y:e.clientY});
  const setDarkTheme = (e) => setTheme(Theme.dark, {x:e.clientX, y:e.clientY});

  // const hasChanges =
  //   theme !== globalTheme ||
  //
  //   autodetectTheme !== defaultAutodetectTheme ||
  //   !isEqual(boardCellsColor, cellsColor);

  // const onSave = () => {
  //   setTheme(theme)
  //   setInterfaceCLr(interfaceClr);
  // };

  // View
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', width: '472px', justifyContent: 'space-between' }}>
        <StyledSwitchToOSThemeTitle>Автоматически использовать тему Вашей ОС</StyledSwitchToOSThemeTitle>
        <CustomSwitch checked={autodetectTheme} onChange={toggleAutoDetectTheme} />
      </div>

      <ButtonWrapperRow longStyle style={{ marginTop: '24px' }}>
        <StyledButton disabled={autodetectTheme} longStyle active={theme === Theme.light} onClick={setLightTheme}>
          <StyledCircle style={{ background: 'white' }} />
          <span>Светлая</span>
        </StyledButton>
        <StyledButton disabled={autodetectTheme} longStyle active={theme === Theme.dark} onClick={setDarkTheme}>
          <StyledCircle style={{ background: 'black' }} />
          <span>Темная</span>
        </StyledButton>
      </ButtonWrapperRow>

      <ButtonWrapperRow longStyle style={{ marginTop: '24px' }}>
        <StyledButton
          longStyle
          active={interactivityMode === InteractivityMode.DRAG}
          onClick={() => setInteractivityMode(InteractivityMode.DRAG)}
        >
          <span>DRAG</span>
        </StyledButton>
        <StyledButton
          longStyle
          active={interactivityMode === InteractivityMode.CLICK}
          onClick={() => setInteractivityMode(InteractivityMode.CLICK)}
        >
          <span>CLICK</span>
        </StyledButton>
      </ButtonWrapperRow>

      <ButtonWrapperRow style={{ marginTop: '16px' }}>
        <StyledButton active={interfaceClr === 'sky'} onClick={() => setInterfaceCLr('sky')}>
          <StyledCircle color="sky" />
        </StyledButton>
        <StyledButton active={interfaceClr === 'orange'} onClick={() => setInterfaceCLr('orange')}>
          <StyledCircle color="orange" />
        </StyledButton>
        <StyledButton active={interfaceClr === 'green'} onClick={() => setInterfaceCLr('green')}>
          <StyledCircle color="green" />
        </StyledButton>
        <StyledButton active={interfaceClr === 'violet'} onClick={() => setInterfaceCLr('violet')}>
          <StyledCircle color="violet" />
        </StyledButton>
      </ButtonWrapperRow>
      {/*<StyledInputGroupTitle>Цвет доски</StyledInputGroupTitle>*/}
      {/*<ButtonWrapperRow>*/}
      {/*  <StyledButton*/}
      {/*    onClick={() => {*/}
      {/*      setBoardCellsColor({ light: CellsColorYellow.light, dark: CellsColorYellow.dark });*/}
      {/*    }}*/}
      {/*    active={false}*/}
      {/*  >*/}
      {/*    <StyledImg src="/img/icons/chessboard-color-1.svg" />*/}
      {/*  </StyledButton>*/}
      {/*  <StyledButton*/}
      {/*    onClick={() => {*/}
      {/*      setBoardCellsColor({ light: CellsColorGreen.light, dark: CellsColorGreen.dark });*/}
      {/*    }}*/}
      {/*    active={false}*/}
      {/*  >*/}
      {/*    <StyledImg src="/img/icons/chessboard-color-2.svg" />*/}
      {/*  </StyledButton>*/}
      {/*  <StyledButton*/}
      {/*    onClick={() => {*/}
      {/*      setBoardCellsColor({ light: CellsColorBlue.light, dark: CellsColorBlue.dark });*/}
      {/*    }}*/}
      {/*    active={false}*/}
      {/*  >*/}
      {/*    <StyledImg src="/img/icons/chessboard-color-3.svg" />*/}
      {/*  </StyledButton>*/}
      {/*</ButtonWrapperRow>*/}
      {/*<TwoButtonsWrapper>*/}
      {/*  <Button fullWidth type="secondary">*/}
      {/*    Отменить*/}
      {/*  </Button>*/}
      {/*  <Button disabled={!hasChanges} fullWidth onClick={onSave}>*/}
      {/*    Сохранить*/}
      {/*  </Button>*/}
      {/*</TwoButtonsWrapper>*/}
    </div>
  );
};
