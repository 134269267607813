import React, { CSSProperties } from 'react';
import { useTheme } from 'styled-components';

const style : CSSProperties = {
  fontFamily: 'Roboto',
  textAlign: 'center',
  fontSize: 40,
  margin: '100px auto',
  width: 500,
};

export function LargeText({text}:{text:string}) {
  const {currentTheme} = useTheme()
  return <div style={{...style, color: currentTheme.textMain}}>
    {text}
  </div>;
}