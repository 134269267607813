import { RoomId } from '@chessclub/grpc_wrapper';


export function getRoomId() : RoomId {
  return document.location.pathname.split('/lesson/')[1] as RoomId ;
}

export function subscribeAtLessonChange(callback: (roomId: string) => void) {

  let roomId;

  function onChange () {
    const newRoomId = getRoomId();
    if (roomId === newRoomId)
      return
    roomId = newRoomId;
    // console.log("current room: " + roomId)
    roomId && roomId !== "none" && callback(roomId);
  }

  window.addEventListener("url", onChange)

  onChange()
}

