import React from 'react';
import { useLessonStore } from '../../../store/lesson/LessonStore';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import { LessonEditTaskToolbarVariant } from './LessonEditTaskToolbarVariant';
import { LessonOnlineToolbarVariant } from './LessonOnlineToolbarVariant';
import { LessonOfflineToolbarVariant } from './LessonOfflineToolbarVariant';

export function LessonPageToolbar() {
  const { editingTask } = useEditingTaskStore();
  const { isOnline } = useLessonStore();

  if (editingTask)
    return <LessonEditTaskToolbarVariant/>

  if (isOnline)
    return <LessonOnlineToolbarVariant />

  return <LessonOfflineToolbarVariant />
}