import React from 'react';
import { useModalStore } from '../../store/modalStore';
import { ModalBody } from './ModalBody';

export function Modal() {
  const {modal} = useModalStore();
  if (!modal)
    return null;
  return <ModalBody>{modal}</ModalBody>;
}
