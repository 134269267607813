import { Editor, EditorContent } from '@tiptap/react';
import React from 'react';
import { MenuBar } from './MenuBar';
import styled from 'styled-components';
import './TextEditor.css';

import { useUiStore } from '../../store/uiStore';
import { SvgImage } from '../Icon/SvgImage';
import { KnownIcons } from '../Icon/KnownIcons';

const StyledEditorWrapper = styled.div<{ focus: boolean; notChat: boolean; draggingFigure: boolean; isEditable:  boolean }>`
  padding: 10px;
  position: relative;
  outline: ${({ theme, focus, draggingFigure, isEditable }) =>
    isEditable ? (focus || draggingFigure ? `1px solid ${theme.currentTheme.button}` : `1px solid ${theme.currentTheme.iconLight}`) : 'none'};
  border-radius: 12px;
  min-height: ${({ notChat }) => (notChat ? `120px` : '100px')};

  margin-top: 10px;
  box-shadow: ${({ focus, theme, draggingFigure, isEditable }) =>
    isEditable && (focus || draggingFigure) ? `0 0 2px 4px ${theme.currentTheme.focus}` : null};
  color: ${({ theme }) => theme.currentTheme.iconMain};

  .ProseMirror {
    line-height: 1.5;
    overflow-y: ${({ notChat }) => !notChat && `auto`};
    max-height: ${({ notChat }) => !notChat && `120px`};
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* @media (max-width: 1920px) {
      min-height: ${({ notChat }) => notChat && `150px`};
      max-height: 150px;
    }
    @media (max-width: 950px) {
      min-height: ${({ notChat }) => notChat && `200px`};
      max-height: 200px;
    } */
  }
  /* @media (max-width: 1920px) {
    min-height: ${({ notChat }) => notChat && `150px`};
    max-height: 150px;
  }
  @media (max-width: 950px) {
    min-height: ${({ notChat }) => notChat && `200px`};
    max-height: 200px;
  } */

  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;
    border-color: ${({ theme }) => theme.currentTheme.textMain};

    td,
    th {
      border: 2px solid ${({ theme }) => theme.currentTheme.iconMain};
      box-sizing: border-box;
      min-width: 1em;
      padding: 3px 5px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: ${({ theme }) => theme.currentTheme.hover};
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgba(200, 200, 255, 0.4);
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      bottom: -2px;
      position: absolute;
      right: -2px;
      pointer-events: none;
      top: 0;
      width: 4px;
    }

    p {
      margin: 0;
    }
  }

  .tableWrapper {
    padding: 1rem 0;
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
  a {
    cursor: pointer;
  }
`;

interface IProps {
  withMenu: boolean;
  editor: Editor;
  focus: boolean;
  handleBlur: () => void;
  handleFocus: () => void;
  readFileAsDataURL: (file: File) => Promise<string>;
  handlePointerUp: () => void;
  label?: JSX.Element | string;
  onClear?: () => void;
}

function TextEditor(props: IProps) {
  let {
    withMenu,
    editor,
    focus,
    handleBlur,
    handleFocus,
    handlePointerUp,
    readFileAsDataURL,
    label,
    onClear,
  } = props;

  const { draggingFigure } = useUiStore();

  return (
    <StyledEditorWrapper focus={focus} notChat={withMenu} draggingFigure={!!draggingFigure} isEditable={withMenu}>
      {label && label}
      {withMenu && <MenuBar editor={editor} readFileAsDataURL={readFileAsDataURL} />}
      <EditorContent
        editor={editor}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onPointerUp={handlePointerUp}
        style={{
          padding: '10px',
          paddingBottom: `${draggingFigure ? '50px' : '10px'}`,
        }}
      />
      {editor?.isEditable && (editor?.getHTML() !== '<p></p>') ? (
        <SvgImage
          icon={KnownIcons.cross}
          onClick={() => {
            editor.commands.clearContent();
            if (onClear) onClear();
          }}
          style={{ position: 'absolute', top: `10px`, right: '8px', zIndex: 2 }}
        />
      ) : (
        <></>
      )}
    </StyledEditorWrapper>
  );
}

export default TextEditor;
