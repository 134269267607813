import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useLocalization } from '../../../l10n/UseLocalization';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import { PiecesColor } from '../../../store/lesson-ui/LessonUiStoreApi';
import { TaskEditViewButton } from './TaskEditViewButton';
import { useTasksStore } from '../../../store/tasks/TasksStore';

const StyledContainer = styled.div`
  display: flex;
  gap: 3px;
`;

export function TaskEditFirstMove() {
  const { editingTask } = useEditingTaskStore();
  const {lesson} = useLocalization();
  const { currentTaskGameApi } = useTasksStore();
  return <StyledContainer>
    <FirstMoveButton side={PiecesColor.WHITE}>
      {lesson.white}
    </FirstMoveButton>
    <FirstMoveButton side={PiecesColor.BLACK}>
      {lesson.black}
    </FirstMoveButton>
    <TaskEditViewButton
      pad={10}
      onClick={() => {
        currentTaskGameApi.setStepIndex(-1);
        currentTaskGameApi.requestUpdate();
      }}
    >
      В начало
    </TaskEditViewButton>

    <TaskEditViewButton
      pad={10}
      onClick={() => {
        let actualMove = editingTask.fullMoveDescriptions[editingTask.moveIndex];
        if (!actualMove)
          return;
        currentTaskGameApi.init({ ...editingTask, fen: actualMove.fen, moves: [], moveIndex: -1 });
        currentTaskGameApi.requestUpdate();
      }}
    >
      Очистить
    </TaskEditViewButton>
  </StyledContainer>
}

function FirstMoveButton(props: PropsWithChildren<{side: PiecesColor}>) {
  const {updateEditingTaskSide, editingTask} = useEditingTaskStore()
  const className = editingTask.fen.split(" ")[1] === props.side ? "selected" : "";
  const onClick = () => updateEditingTaskSide(props.side);
  return <TaskEditViewButton className={className} onClick={onClick} pad={10}>
    {props.children}
  </TaskEditViewButton>
}

