import React from 'react';
import { ApplyCancelToolbarVariant } from '../../../parts/ApplyCancelToolbarVariant';
import { useMaterialsStore } from '../../../store/materials/MaterialsStore';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';

export function MaterialsPageTaskEditToolbarVariant () {

  const { editingTask, stopEditTask } = useEditingTaskStore()
  const { putMaterial } = useMaterialsStore();

  function applyEditTask() {
    putMaterial(editingTask);
    stopEditTask();
  }

  return  <ApplyCancelToolbarVariant
    apply={applyEditTask}
    cancel={stopEditTask}
  />
}