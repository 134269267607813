import React from 'react';
import {  LessonTabs, LessonViewMode } from '../../../../store/lesson-ui/LessonUiStoreApi';
import { HandeHochButton } from '../buttons/HandeHochButton';
import { VideoRoom } from './Cameras/VideoRoom';
import { KnownIcons } from '../../../../components/Icon/KnownIcons';
import { useLessonUiStore } from '../../../../store/lesson-ui/LessonUiStoreImpl';
import { useLessonStore } from '../../../../store/lesson/LessonStore';
import { RoomOwnerCamera } from './Cameras/RoomOwnerCamera';
import { LessonSideBarTabProps } from '../components';

export function LessonCamerasTab() :  LessonSideBarTabProps{
  const { studentViewSettings,  isStudentLayout, monitoringStudentId, isOnline } = useLessonStore();
  const {view} = useLessonUiStore();
  const allowedViews = [LessonViewMode.STANDARD, LessonViewMode.CHESSBOARDS];
  const actualView = monitoringStudentId ? LessonViewMode.STANDARD : view;
  const shouldShowCameras = isStudentLayout ? studentViewSettings?.cameras : true;
  if (!allowedViews.includes(actualView)  || !shouldShowCameras)
    return;
  return {
    type: LessonTabs.CAMS,
    icon: KnownIcons.camera,
    content: <>
      <RoomOwnerCamera />
      <VideoRoom />
    </>,
    additionalButtons : []
  }
}