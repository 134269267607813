import { useLessonStore } from '../store/lesson/LessonStore';
import { useTasksStore } from '../store/tasks/TasksStore';

export function useTaskToDisplay() {

  const {
    demonstrationState,
    isStudentLayout,
    monitoringStudentId,
    monitoringStudentDisplayingQuestionId
  } = useLessonStore();

  const {activeTask, tasks} = useTasksStore();

  if (isStudentLayout) {
    return demonstrationState ? {taskToDisplay: demonstrationState.task} : find(activeTask?.id)
  } else if (monitoringStudentId) {
    return find(monitoringStudentDisplayingQuestionId)
  } else {
    return find(activeTask?.id);
  }

  function find(id) {
    return {taskToDisplay: tasks.find(t => t.id === id)};
  }

}

