import styled from 'styled-components';

const StyledTitle = styled.h3`
  margin: 0px 0px 10px 0px;
  padding: 0px;

  font: ${({ theme }) => theme.fonts.normalBold};
  color: ${({ theme }) => theme.currentTheme.textMain};
`;

const StyledParagraph = styled.p`
  margin: 0px;
  padding: 10px 0px;

  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};

  &:last-child {
    margin-bottom: 50px;
  }
`;

const StyledList = styled.ol``;

const StyledListItem = styled.li`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};
`;

const StyledPhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 95px;

  img {
    width: 100%;
  }
`;

export const HelpPageStyledComponents = {
  StyledList,StyledListItem,StyledParagraph,StyledPhotoContainer,StyledTitle
}