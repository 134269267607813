import { Castling } from '../../../components/Castling/Castling';
import React from 'react';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';

export function TaskEditCastlingPanel() {
  const {editingTask, updateEditingTaskCastling} = useEditingTaskStore();
  return <Castling
    question={editingTask}
    edit={updateEditingTaskCastling}
  />
}
