
import styled from 'styled-components';

export const Stack = styled.div`
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    & > * {
        grid-column: 1;
        grid-row: 1;
    }
`;

