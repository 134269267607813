import React, { ComponentType, PropsWithChildren } from 'react';

import { CSSProperties } from 'styled-components';
import { StyledButton, StyledFigures } from './common';
import { PopupMenu } from '../PopupMenu/PopupMenu';
import { ChessboardExtraPiecesDropdown } from './dropdowns/ChessboardExtraPiecesDropdown';
import { PiecesPanel } from './PiecesPanel';
import { SvgImage } from '../Icon/SvgImage';
import { KnownIcons } from '../Icon/KnownIcons';

export interface IChessToolsProps {
  noMargin?: boolean;
  style?: CSSProperties;
  longVariant?: boolean;
}

const icon40px = { width: '40px', height: '40px', display: 'flex', justifyContent: 'center', padding: 8 };

export function ChessToolbar() {
  return <>
    <StyledFigures style={{ overflow: 'clip', marginRight: 30 }}>
      <Tool content={ChessboardExtraPiecesDropdown}>
        <SvgImage icon={KnownIcons.pacman} />
      </Tool>
    </StyledFigures>
    <PiecesPanel />
  </>;
}

export function Tool({ content, children }: PropsWithChildren<{ content: ComponentType; }>) {
  return <PopupMenu popupContent={content}>
    <StyledButton style={icon40px}>
      {children}
    </StyledButton>
  </PopupMenu>;
}

