import React from 'react';
import { Box } from '@material-ui/core';
import { Logo, LogoSize } from '../Icon/Logo';

import { PageHeaderStyledComponents } from './PageHeaderStyledComponents';
import { UserProfileWidget } from './UserProfileWidget';
import { NavLinks } from './NavLinks';
import { StudentsIcons } from './StudentssIcons/StudentsIcons';

const {
  StyledGridContainer,
  StyledNav,
} = PageHeaderStyledComponents;

type PageHeaderParams = {
  logo: LogoSize;
}

export function PageHeader(props:PageHeaderParams) {
  return (
    <StyledGridContainer
      // style={{background: "linear-gradient(to bottom, #111, #fff0)"}}
    >
      <div
        className='nav-wrapper'
        style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      >
        <StyledNav>
          <Logo size={props.logo} />
          <Box
            display={'flex'}
            style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center', gap: 0 }}
          >
            <NavLinks />
            <StudentsIcons />
            <UserProfileWidget />
          </Box>
        </StyledNav>
      </div>
    </StyledGridContainer>
  );
}


