import styled from 'styled-components';
import { IBg } from '../../../../../interfaces/question';

export const StyledQuestionTitle = styled.div<{ active: boolean; shouldShowTitleBg?: boolean }>`
  padding-right: 4px;
  background: ${({ active, theme, shouldShowTitleBg }) =>
  active && shouldShowTitleBg
    ? theme.currentTheme.select
    : shouldShowTitleBg
      ? theme.currentTheme.background
      : 'none'};

  opacity: 0.7;
  padding-top: 0.4rem;
  font: ${({ theme }) => theme.fonts.small};
  color: ${({ theme }) => theme.currentTheme.textMain};
  display: flex;
  align-items: center;
`;

export const StyledQuestionIcons = styled.span`
  display: flex;
  opacity: 0;
  pointer-events: none;
  flex-direction: row;
  align-items: center;
  padding-left: 6px;
`;

export const StyledQuestionRow = styled.div<{
  active?: boolean;
  border?:string;
}>`
  height: 150px;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  border-left: 3px solid ${({ border }) => border || "transparent"};
  //border-radius: 4px;
  box-shadow: inset 0px 0 1px 0px  #aaa;

  ${({ active, theme }) => (active ? `background-color: ${theme.currentTheme.select} !important` : null)};
  
  &:hover {
    background-color: ${({ theme }) => theme.currentTheme.hover};
    & ${StyledQuestionTitle} {
      background: none !important;
    }
    
    & ${StyledQuestionIcons} {
      opacity: 1;
      pointer-events: all;
    }
  }

  * {
    text-decoration: none;
  }
`;

export const StyledQuestionInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  top: 0px;
  /* z-index: 2; */
`;

export const StyledQuestionBg = styled.div<IBg>`
  width: 100px;
  right: 4px;
  height: 100%;
  z-index: ${({ chessboardHover }) => (chessboardHover ? 2 : 1)};
  position: absolute;
  top: 0px;
  box-sizing: border-box;
  padding: 4px 0px;
  display: flex;
  justify-content: flex-end;
`;



export const StyledQuestionDescription = styled.div<{ chessboardHover?: boolean; buttonsHover?: boolean }>`
  font: ${({ theme }) => theme.fonts.small};
  color: ${({ theme }) => theme.currentTheme.iconMain};
  display: flex;
  align-items: center;
  z-index: ${({ chessboardHover, buttonsHover }) => (chessboardHover || buttonsHover ? 1 : 2)};
`;

export const StyledQuestionDifficulty = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border: 1px solid var(--color-text-main);
  margin-right: 6px;
  margin-left: 3px;
  //background: var(--color-hover);
`;

