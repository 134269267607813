import React from 'react';
import { useLessonStore } from '../../../store/lesson/LessonStore';
import { HandeHochButton } from '../LessonView/buttons/HandeHochButton';
import { LessonStatusPanelWrapper } from './LessonStatusPanelWrapper';

export function StudentLessonStatusPanel() {
  const { teacherName, roomId, isOnline } = useLessonStore();

  if (!roomId)
    return null;

  const active = document.location.href.includes('/lesson/');

  return <LessonStatusPanelWrapper
    $active={active} to={'/lesson/' + roomId}
    $online={isOnline}
    $student={true}
  >
    <div>{teacherName}</div>
    {isOnline && <div><HandeHochButton /></div>}
  </LessonStatusPanelWrapper>;
}