import React from 'react';
import { useAuthStore } from '../../../store/auth/AuthStore';
import { UserProfileStyledComponents } from './UserProfileStyledComponents';
import { SvgImage } from '../../Icon/SvgImage';
import { KnownIcons } from '../../Icon/KnownIcons';

const {
  StyledUserInfo, StyledAvatar, StyledUserProfile,  StyledName,
} = UserProfileStyledComponents;


export function UserProfile() {

  const { user } = useAuthStore();

  return (
    <StyledUserProfile className="withHover">
      <StyledAvatar src={user?.imageUrl} />
      <StyledUserInfo>
        {/*<StyledName>{user.email}</StyledName>*/}
        <SvgImage icon={KnownIcons.arrow_right} rotate={90}/>
      </StyledUserInfo>
    </StyledUserProfile>
  );
}

