import React, { PropsWithChildren, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { PageHeader } from '../PageHeader/PageHeader';
import { PageFooter } from '../PageFooter/PageFooter';
import styled from 'styled-components';
import { useUiStore } from '../../store/uiStore';
import { LogoSize } from '../Icon/Logo';

type PageTemplateParams = {
  showFooter?: boolean;
  showNavigation?: boolean;
  placeholder?:string;
  logo?: LogoSize;
}

const StyledBox = styled(Box)`
  display: grid;
  grid-template-rows: 0fr auto 0fr;
  height: 100%;
  margin: 0 auto;
  padding: 0!important;
  width: 90%;
  transition: 0.1s;
  max-width: 1800px;

  @media (max-width: 1024px) {
    width: 98%;
  }
`

export function PageTemplate(props: PropsWithChildren<PageTemplateParams>) {
  const {setPlaceholder} = useUiStore();

  useEffect(() => {
    setPlaceholder(props.placeholder);
  }, [props.placeholder]);

  return <StyledBox>
    <PageHeader {...props} logo={props.logo || LogoSize.small}/>
    {props.children}
    {props.showFooter !== false && <PageFooter />}
  </StyledBox>

}
