import React, { FunctionComponent, useState } from 'react';
import { Button } from '../../components/low-level-components/Button';
import Input from '../../components/low-level-components/Input';
import { StyledInputWrapper } from '../../components/Modal/common';
import PasswordInput from '../../components/low-level-components/PasswordInput';
import { InputWrapper, OneButtonWrapper, StyledInputGroupTitle, TwoButtonsWrapper } from './common';
import cloneDeep from 'lodash/cloneDeep';
import { useAuthStore } from '../../store/auth/AuthStore';
import { ServerApi } from '../../transport/ServerApi';
import { IProfile } from '@chessclub/grpc_wrapper';

interface IProps {
  onChange(err: any): void;
  profile: IProfile;
}

export const SecurityTab: FunctionComponent<IProps> = ({ onChange, profile }) => {
  const [showCode, setShowCode] = useState(false);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [code, setCode] = useState('');
  const [differenceController] = useState(null);


  const { user } = useAuthStore();

  const changePassword = async () => {
    const result = await ServerApi.accessService.changePassword(user.email);
    if (result ) {
      console.log('changePassword return: ' + result)
    }
  }

  const sendCode = async () => {
    if (password === password2) {
      await changePassword();

      setShowCode(true);
    } else {
      setPasswordError('Пароли не совпадают');
    }
  };

  // Handlers
  const hasChanges = () => {
    return password && password === password2 && code;
  };

  const onSave = async () => {
    if (password !== '') {
      if (password === password2) {
        const result = await ServerApi.accessService.completeChangePassword(user.email, password, code);
        if (result) {
          setPasswordError(result);
        }

        setPassword('');
        setPassword2('');
        setCode('');
        setShowCode(false);
      } else {
        setPasswordError('Пароли не совпадают');
      }
    }
    differenceController !== null &&
      onChange((error) => {
        if (error && error.getType() !== 0) {
          setPasswordError(error.getType());
        } else {
          differenceController.etalon = cloneDeep(profile);
        }
      });
  };

  // View
  return (
    <div style={{ width: '540px' }}>
      {passwordError}
      <InputWrapper>
        <PasswordInput
          label="Пароль"
          value={password}
          onChange={(e) => {
            setPasswordError('');
            setPassword(e.target.value);
          }}
          error={!!passwordError}
          fullWidth
        />
      </InputWrapper>
      <InputWrapper>
        <PasswordInput
          label="Повторить пароль"
          value={password2}
          onChange={(e) => {
            setPasswordError('');
            setPassword2(e.target.value);
          }}
          error={!!passwordError}
          fullWidth
        />
      </InputWrapper>
      {showCode ? (
        <StyledInputWrapper
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr auto',
            gridGap: '1rem',
          }}
        >
          <Input
            label="Код подтверждения, отправленный на почту"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            fullWidth
          />
          <Button
            fullWidth
            type="secondary"
            onClick={changePassword}
          >
            Повторить отправку кода
          </Button>
        </StyledInputWrapper>
      ) : (
        <>
          <OneButtonWrapper>
            <Button fullWidth onClick={sendCode}>
              Получить код в письме
            </Button>
          </OneButtonWrapper>
        </>
      )}
      <TwoButtonsWrapper>
        <Button fullWidth type="secondary">
          Отменить
        </Button>
        <Button disabled={!hasChanges()} fullWidth onClick={onSave}>
          Сохранить
        </Button>
      </TwoButtonsWrapper>
    </div>
  );
};


