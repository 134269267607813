import React, { useRef } from 'react';
import { useAnimationFrame } from '@chessclub/web-game-server/src/client/react-canvas/useAnimationFrame';

export function PreloaderAnimation1() {
  const ref = useRef<SVGSVGElement>();
  const ref1 = useRef<SVGPathElement>();
  const ref2 = useRef<SVGPathElement>();
  // const ref3 = useRef<SVGPathElement>();
  // const ref4 = useRef<SVGPathElement>();

  useAnimationFrame((t) => {
    let path = calcPath(t / 2000);
    ref1.current.setAttribute('d', path);
    ref2.current.setAttribute('d', path);
    // ref3.current.setAttribute('d', path);
    // ref4.current.setAttribute('d', path);
    // ref.current.setAttribute('transform', `rotate(${t/31.4})`);
  });

  return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <svg ref={ref} width={300} height={300} viewBox={'-10 -10 20 20'}>
      <rect x={-2.5} y={-2.5} width={5} height={5} fill={'#999'} />
      <rect x={-2.4} y={-2.4} width={4.8} height={4.8} fill={'#fff'} />
      <path ref={ref1} fill={'#999'} />
      <path ref={ref2} fill={'#999'} transform={'scale(-1,-1)'} />
      {/*<g transform="rotate(90)">*/}
      {/*  <path ref={ref3} fill={'#999'} />*/}
      {/*  <path ref={ref4} fill={'#999'} transform={'scale(-1,-1)'} />*/}
      {/*</g>*/}
    </svg>
  </div>
}


function calcPath(t): string {
  // t = Math.sin(t)*0.5+0.5;
  let stage = Math.sign(t % 2 - 1);
  t = t % 1;
  t = 3 * t * t - 2 * t * t * t;
  t = 3 * t * t - 2 * t * t * t;
  t = 3 * t * t - 2 * t * t * t;
  t *= Math.PI;

  let c = [stage * 2.5, -2.5];

  let p0 = rotate(stage > 0 ? [2.5, -2.5] : [-2.5, -7.5], c, t);
  let p1 = rotate(stage > 0 ? [2.5, +2.5] : [+2.5, -7.5], c, t);
  let p2 = rotate(stage > 0 ? [7.5, +2.5] : [+2.5, -2.5], c, t);
  let p3 = rotate(stage > 0 ? [7.5, -2.5] : [-2.5, -2.5], c, t);

  return `M ${p0} L ${p1} L ${p2} L ${p3} Z`;
}

function rotate(pt, cntr, rad) {
  const cs = Math.cos(rad), sn = Math.sin(rad),
    x = pt[0] - cntr[0], y = pt[1] - cntr[1];

  return [
    +x * cs + y * sn + cntr[0],
    -x * sn + y * cs + cntr[1],
  ];
}