import React from 'react';
import styled from 'styled-components';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import { ConfirmationPopupMenu } from '../../components/PopupMenu/ConfirmationPopupMenu';
import { useEditingTopicStore } from '../../store/editing-topic/EditingTopicStore';
import { ITopic } from '@chessclub/grpc_wrapper';
import { TextNode } from '../../components/low-level-components/TextNode';

const Entry = styled.div<{ selected?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 32px 1fr 64px;
  align-items: center;
  padding: 5px;
  border-left: 3px solid ${p => p.selected ? 'var(--color-accent)' : 'transparent'};
  box-sizing: border-box;

  & > :nth-child(3) {
    opacity: 0;
    transition: 0.3s;
  }

  &:hover > :nth-child(3) {
    opacity: 1;
  }

  &:hover {
    background: var(--color-hover);
  }
`;

type TopicEntryParams = {
  topic: ITopic;
  selected?: boolean,
  onClick?: () => void;
  onDelete?: () => void;
};

export function TopicEntry(props: TopicEntryParams) {
  const {startEditTopic} = useEditingTopicStore();
  return <Entry selected={props.selected} onClick={props.onClick}>
    <SvgImage icon={KnownIcons[props.topic.customData?.icon] || KnownIcons.pacman} />
    <div style={{ display: 'flex' }}>
      <TextNode style={{textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap",maxWidth: 250}}>&nbsp;{props.topic.name}</TextNode>
      &nbsp;
      <TextNode style={{ color: 'var(--color-accent)' }}>({props.topic.itemsCount})</TextNode>
    </div>
    {props.onDelete && <div style={{ display: 'flex' }}>

      <ConfirmationPopupMenu text={"Удалить?"} action={props.onDelete}>
        <SvgImage icon={KnownIcons.trash} withHover />
      </ConfirmationPopupMenu>

      <SvgImage icon={KnownIcons.pencil} withHover onClick={() => startEditTopic(props.topic)} />
    </div>}
  </Entry>;
}