import { L10n } from "./L10n";

export const Lang_en: L10n = {
  "landing": {
    "mainTitle": "Learn<br/>at a new level",
    "mainSubTitle": "a platform for distance learning of chess",
    "aboutUs": "About Us",
    "aboutUsText": "The mission of the <b>ChessClub Training</b> platform is to make the learning process as comfortable, effective, and engaging as possible, for both teachers and students.",
    "ourCapabilitiesTitle": "Our Capabilities",
    "ourCapabilitiesElements": [
      ["/img/landing/icons/crown.svg", "Conducting both individual and group classes"],
      ["/img/landing/icons/crown.svg", "Gamification and implementation of new game forms in teaching children chess"],
      ["/img/landing/icons/crown.svg", "Ability to conduct online tournaments"],
      ["/img/landing/icons/crown.svg", "Expanded toolkit for teachers/coaches"],
      ["/img/landing/icons/crown.svg", "Remote learning with a presence effect"]
    ],
    "ourPlatformTitle": "Our Platform is for You, if You:",
    "ourPlatformElements": [
      ["/img/landing/icons/knight.svg", "Freelance in chess education"],
      ["/img/landing/icons/rook.svg", "Chess club teacher"],
      ["/img/landing/icons/king.svg", "Professional chess coach"]
    ],
    "ourAdvantagesTitle": "Our Advantages",
    "ourAdvantagesElements": [
      ["/img/landing/icons/pawns-3.svg", "Ability to conduct classes with different age categories"],
      ["/img/landing/icons/ai.svg", "Application of artificial intelligence technologies"],
      ["/img/landing/icons/pawns-6.svg", "Ability to conduct group online classes"],
      ["/img/landing/icons/pc.svg", "Adaptive interfaces for PC, laptop, smartphone, tablet, mobile version"],
      ["/img/landing/icons/two-knights.svg", "Ability to conduct online tournaments"],
      ["/img/landing/icons/arrows.svg", "Virtual pointer with cursor and figure synchronization"],
      ["/img/landing/icons/lamp.svg", "Fair play control"],
      ["/img/landing/icons/server.svg", "Synergy with TimeWeb services (reliable hosting on servers)"],
    ],
    "ourExpertsTitle": "Our Experts",
    "ourExpertsElements": [
      ["/img/landing/experts/vladimir-bykov.png", "<b>Vladimir Bykov</b><br/><br/>Executive Director of the St. Petersburg Chess Federation, captain of the \"Medny Vsadnik\" team"],
      ["/img/landing/experts/ekaterina-sudoplatova.png", "<b>Ekaterina Sudoplatova</b><br/><br/>International Woman Grandmaster, International Master. Two-time Russian champion and world champion among girls, European champion as part of the Russian team, member of the Russian Olympic team, Russian women's champion, multiple-time St. Petersburg champion. Coach, extracurricular activities teacher, additional education pedagogue"],
      ["/img/landing/experts/alina-balayan.png", "<b>Alina Balayan</b><br/><br/>International Woman Master, Master of Sports of Russia. Champion of Russia in the team, champion of Russia among women in rapid chess, multiple-time champion of St. Petersburg. Coach, additional education pedagogue"],
      ["/img/landing/experts/valeriy-popov.png", "<b>Valeriy Popov</b><br/><br/>International Grandmaster, Coach of the highest category, European Champion, Senior Coach of the St. Petersburg State Budgetary Institution of Additional Education \"Chess and Checkers School\""],
      ["/img/landing/experts/dina-belenkaya.png", "<b>Dina Belenkaya</b><br/><br/>Woman International Grandmaster, multiple-time champion of St. Petersburg, multiple-time prize-winner of the Russian Championship in the team, chess teacher."],
      ["/img/landing/experts/anastasia-bondaruk.png", "<b>Anastasia Bondaruk</b><br/><br/>International Master, multiple-time champion of St. Petersburg, Russia, member of the Russian national team."],
    ]
  },
  "footer": {
    "help": "Help",
    "termsOfService": "Terms of service",
    "contacts": "Contacts",
    "supportUs": "Support us",
  },
  "lesson": {
    "newTask": "New task",
    "chain": "Chain",
    "table": "Table",
    "clear": "Clear",
    "room": "Room",
    "defaultRoom": "Default room",
    "ok": "Ok",
    "empty": "Empty",
    "sizeHuge": "Huge",
    "sizeLarge": "Large",
    "sizeStandard": "Standard",
    "markersBlue": "Blue marker",
    "markersGreen": "Green marker",
    "markersOff": "Turn off markers",
    "markersRed": "Red marker",
    "markersDelete": "Delete markers",
    "withoutTitles": "Without titles",
    "titlesAtCellCorners": "At cell corners",
    "titlesInEveryCell": "In every cell",
    "titlesAroundChessboard": "Around chessboard",
    "standardView": "Standard",
    "allChessboardsView": "All chessboards",
    "allCamsView": "All cams",
    "size": "Size",
    "exit": "Exit",
    "materials": "Materials",
    "rooms": "Rooms",
    "profile": "Profile",
    "edit": "Edit",
    "paste": "Paste",
    "copy": "Copy",
    "save": "Save",
    "cancel": "Cancel",
    "delete": "Delete",
    "add": "Add",
    "waitForTrainer": "Wait for trainer",
    "chat": "Chat",
    "cams": "Cams",
    "online": "Online",
    "view": "View",
    "demonstration": "Demonstration",
    "settings": "Settings",
    "offline": "Offline",
    "black": "Black",
    "white": "White",
    "tasks": "Tasks",
    "moves": "Moves",
    "activeLessons": "Active lessons",
    "activeLesson": "Active lesson",
    "student": "Student",
    "trainer": "Trainer",
    "lesson": "Lesson",
  },
  "captcha" : {
    "errors": {
      "0": "No error found",
      "3": "Invalid captcha params",
      "501": "Captcha not found",
      "502": "Invalid captcha params",
      "503": "'Wrong captcha email",
      "504": "'Captcha is expired",
      "505": "'Answer is expired",
      "506": "Wrong captcha answer",
    },
    "language": "English"
  },
  "auth": {
    "new_password": "New password",
    "repeat_new_password": "Repeat new password",
    "consider_new_password": "Create a new password and enter the code sent to your email for confirmation",
    "confirm": "Confirm",
    "agree_with": "I agree with",
    "terms": "Terms of service",
    "do_sign_up": "Sign up",
    "continue": "Continue",
    "code": "Code from email",
    "email_to_restore": "Enter email address to restore access",
    "receive_code_in_mail": "Get code in email",
    "repeat_password": "Repeat password",
    "login_with": "Login with",
    "login": "Login",
    "forgot_password": "Restore password",
    "or": "or",
    "email": "Email",
    "password": "Password",
    "no_account_yet": "Don't have an account yet?",
    "sign_up": "Sign up",
    "yandex": "Yandex",
    "ok": "Ok",
    "mail": "Mail.ru",
    "fb": "Facebook",
    "twitter": "Twitter",
    "vk": "Vkontakte",
    "google": "Google",
    "choose_lg": "Choose your language",
    "language": "English",
    "errors": {
      "0": "No error found",
      "1": "User not found",
      "2": "User already exists",
      "3": "Invalid access params",
      "4": "User not activated",
      "5": "Wrong password",
      "6": "Wrong activation code",
      "7": "Wrong session sign",
      "8": "User is not authenticated",
      "9": "Wrong restore code",
      "10": "User already active",
      "11": "Wrong auth code",
      "12": "Wrong config passed",
      "13": "Wrong social provider",
      "14": "Password to weak",
      "15": "Error during getting user info",
      "16": "oauth app already exists",
      "17": "oauth app not found",
      "18": "OAUTH consumer not found",
      "19": "Max oauth apps limit reached",
      "20": "OAUTH authorization code not found",
      "21": "OAUTH access token not found",
      "22": "SMTP send mail error",
      "23": "Wrong image type",
      "24": "Send image errorr"
    }
  },
  "permissions": {
    "OtherError" : {
      "otherError": "Your camera is unavailable. Please try another device."
    },
    "BlockedAtSystemLevelMacOs": {
      "camera": "Your camera is not connected or {{browserName}} access to the camera is denied at the operating-system level.",
      "check": "Check if the camera works in other applications (like Photo Booth). If everything works, make sure {{browserName}} can access the camera.",
      "p1_menu": "Select the Apple menu",
      "p1_pref": "→ \"System Preferences\", click \"Security & Privacy\", then click \"Privacy\";",
      "p2": "Select Camera and check the box next to the {{browserName}} app;",
      "p3": "Select Microphone and check the box next to the {{browserName}} application;",
      "p4": "Restart {{browserName}} and try the interview again.",
      "more": "Learn more on the",
      "support": "Apple Support site"
    },
    "NoSoundWindowsFirefox": {
      "sureConnected": "If you are sure that your microphone is connected, the most likely issue is that access is blocked in your Windows privacy settings.",
      "goToStart": "Go to Start",
      "parameters": "Settings",
      "privacy": "Privacy",
      "mic": "Microphone. Make sure that:",
      "p1": "Microphone access is enabled for this device;",
      "p2": "\"Allow applications to access microphone\" is set to \"On\";",
      "p3": "\"Allow desktop apps to access my microphone\" (if any) is set to \"On\";",
      "p4": "Refresh the page after making any changes to the settings.",
      "more": "Learn more on the",
      "support": "Microsoft Support site"
    },
    "NotAllowedAndroidChrome": {
      "check": "Failed to access camera and microphone.",
      "p1": "To the left of the site address, click on the lock icon",
      "p2": "Click on the Permissions section;",
      "p3": "Allow access to Camera and Microphone;",
      "p4": "Reload the page.",
      "i1": "If you do not see a Permissions section, then access to the camera and microphone is blocked at the Android operating system level. In Android Settings in the Applications section, make sure that Chrome is allowed to access the Camera and Microphone. After that, go back to Chrome and reload the page. Learn more on the",
      "more": "Android Support site"
    },
    "NotAllowedBlink": {
      "check": "You have not given permission to access your camera or microphone.",
      "p1": "To the left of the site address, click on the lock icon",
      "p2": "Allow access to your camera and microphone;",
      "p3": "Refresh the page",
      "p3_check": "or click here"
    },
    "NotAllowedBlinkWindowsSystem": {
      "check": "Unable to access your microphone and/or camera. Most likely, access to the microphone/camera is denied in the privacy settings of Windows 10.",
      "goToStart": "Go to Start",
      "parameters": "Settings",
      "privacy": "Privacy",
      "cameraAndSure": "Click on Microphone and Camera settings and make sure that your system allows access to both devices.",
      "p1": "Microphone/Camera access is enabled for this device;",
      "p2": "\"Allow apps to access the microphone\" / \"Allow applications to access camera\" is set to \"On\";",
      "p3": "\"Allow desktop apps to access microphone\" / \"Allow desktop apps to access my camera\" (if any) is set to \"On\";",
      "p4": "Refresh the page after making any changes to the settings.",
      "more": "Learn more on the",
      "support": "Microsoft Support site"
    },
    "NotAllowedFirefox": {
      "check": "You have not given permission to access your camera or microphone.",
      "p1": "To the left of the site address, click on the crossed-out camera or microphone icon;",
      "p2": "Remove the ban on camera and microphone access;",
      "p3": "Refresh the page",
      "p4": "Click Allow when Firefox requests access again.",
      "more": "If these recommendations do not help, use",
      "support": "the Firefox support service"
    },
    "NotAllowedMacOsSafari": {
      "check": "You have not given permission to access your camera or microphone.",
      "p1": "Choose the menu tab \"Safari\" → \"Preferences\" and click \"Websites\";",
      "p2": "Select first Camera and then Microphone and set \"Allow\" for huntica.works;",
      "p3": "Close the settings and reload the page.",
      "more": "Learn more on the",
      "support": "Apple Support site"
    },
    "NotConnected": {
      "notConnected": "It looks like your camera is not connected. Please connect your camera."
    },
    "NotReadableErrorAudioWindows": {
      "check": "Unable to access your microphone. Most likely, access to the microphone is denied in the privacy settings of Windows 10.",
      "goToStart": "Go to Start",
      "parameters": "Settings",
      "privacy": "Privacy",
      "mic": "Microphone. Make sure that:",
      "p1": "Microphone access is enabled for this device;",
      "p2": "\"Allow applications to access microphone\" is set to \"On\";",
      "p3": "\"Allow desktop apps to access my microphone\" (if any) is set to \"On\";",
      "p4": "Refresh the page after making any changes to the settings",
      "more": "Learn more on the",
      "support": "Microsoft Support site"
    },
    "NotReadableErrorVideoWindows":{
      "check": "Unable to access your camera. There are two possible reasons:",
      "p1": "Your camera is currently being used by another application (such as Skype or Zoom). Usually, the indicator on the camera will light up. Close all other applications that might be using your camera and then reload the page.",
      "p2": "If you are sure that the camera is not currently in use, then access to it is denied in the privacy settings of Windows 10.",
      "goToStart": "Go to Start",
      "parameters": "Settings",
      "privacy": "Privacy",
      "cameraAndSure": "Camera and make sure that:",
      "a": "Camera access for this device is enabled;",
      "b": "\"Allow apps to access the camera\" is set to \"On\";",
      "c": "\"Allow desktop apps to access camera\" (if any) is set to \"On\";",
      "d": "After making any changes to Settings, refresh the page.",
      "more": "Learn more on the",
      "support": "Microsoft Support site"
    }
  },
  "rooms": {
    "rooms": "Rooms"
  }
};