import pawn from '../../../svg/figures/figure-pawn-white-dark.svg'
import rook from '../../../svg/figures/figure-rook-white-dark.svg'
import knight from '../../../svg/figures/figure-horse-white-dark.svg'
import bishop from '../../../svg/figures/figure-bishop-white-dark.svg'
import queen from '../../../svg/figures/figure-queen-white-dark.svg'
import king from '../../../svg/figures/figure-king-white-dark.svg'
import { FigureType } from './FigureType';

export const FiguresIconsWhite_DarkTheme = {
  [FigureType.pawn]: pawn,
  [FigureType.rook]: rook,
  [FigureType.knight]: knight,
  [FigureType.bishop]: bishop,
  [FigureType.queen]: queen,
  [FigureType.king]: king,
}

