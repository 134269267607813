import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledNav = styled.nav`
  flex-wrap: nowrap ;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
  justify-content: flex-start;
  & .withHover:hover {
    cursor: pointer;
    border-radius: 12px;
    background: ${p => p.theme.currentTheme.hover};
  }
`;


const StyledNavItem = styled(Link)<{
  theme?: any;
  $active?: boolean;
}>`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.iconMain};
  padding: 13px 6px;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 12px;
  background: ${p => p.$active ? 'var(--color-selected) !important' : ""};
  //box-shadow: inset .5px .5px 2px #666;
`;

const StyledGridContainer = styled.div`
  padding-top: 14px;
  display: flex;
  min-width: 0;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-between;
`;

const StyledActiveRoomsDropdown = styled.div<{ interfaceClr: any }>`
  color: ${({ theme, interfaceClr }) => theme.currentTheme[interfaceClr]};
  stroke: ${({ theme, interfaceClr }) => theme.currentTheme[interfaceClr]};
`;

const StyledNavItems = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  //max-width: 600px;
  //width: 100%;
`;



export const PageHeaderStyledComponents = {
  StyledActiveRoomsDropdown,
  StyledGridContainer,
  StyledNav,
  StyledNavItem,
  StyledNavItems,

}