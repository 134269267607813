import React from 'react';
import { MovesDropdown } from './MovesDropdown';
import { LessonTabs } from '../../../../store/lesson-ui/LessonUiStoreApi';
import { KnownIcons } from '../../../../components/Icon/KnownIcons';
import { useLessonStore } from '../../../../store/lesson/LessonStore';
import { LessonSideBarTabProps } from '../components';
import { LessonMovesPanel } from './LessonMovesPanel';

export function LessonMovesTab(): LessonSideBarTabProps {

  const { isStudentLayout, demonstrationState, studentViewSettings } = useLessonStore();

  function calcShowGameTab() {
    if (!isStudentLayout) {
      return true;
    } else if (demonstrationState) {
      return demonstrationState.shouldShowSteps;
    } else {
      return studentViewSettings.moves;
    }
  }

  if (!calcShowGameTab())
    return null;


  return {
    type: LessonTabs.MOVES,
    icon: KnownIcons.stopwatch,
    additionalButtons: isStudentLayout ? [] : [<MovesDropdown key={1} />],
    content: <LessonMovesPanel />,
  };

}