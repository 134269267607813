import { L10n } from './L10n';

export const Lang_hi: L10n = {
  "landing": {
    "mainTitle": "नए स्तर पर सीखें<br/>",
    "mainSubTitle": "शतरंज खेलने के लिए दूरस्थ शिक्षा प्लेटफ़ॉर्म",
    "aboutUs": "हमारे बारे में",
    "aboutUsText": "चेस क्लब ट्रेनिंग प्लेटफ़ॉर्म का मिशन शिक्षकों और छात्रों दोनों के लिए शिक्षा की प्रक्रिया को अधिक से अधिक आरामदायक, प्रभावी और आकर्षक बनाना है। ",
    "ourCapabilitiesTitle": "हमारी क्षमताएं",
    "ourCapabilitiesElements": [
      ["/img/landing/icons/crown.svg", "व्यक्तिगत और समूह सत्र आयोजित करना"],
      ["/img/landing/icons/crown.svg", "बच्चों को शतरंज सिखाने में नए गेमिंग फ़ॉर्म का परिचय"],
      ["/img/landing/icons/crown.svg", "ऑनलाइन टूर्नामेंट आयोजित करने की क्षमता"],
      ["/img/landing/icons/crown.svg", "शिक्षकों के लिए विस्तारित उपकरण"],
      ["/img/landing/icons/crown.svg", "दूरस्थ शिक्षा के साथ उपस्थिति का प्रभाव"]
    ],
    "ourPlatformTitle": "हमारा प्लेटफ़ॉर्म आपके लिए, यदि आप:",
    "ourPlatformElements": [
      ["/img/landing/icons/knight.svg", "शतरंज खेलने के क्षेत्र में फ्रीलांसिंग"],
      ["/img/landing/icons/rook.svg", "शतरंज क्लब में शिक्षक"],
      ["/img/landing/icons/king.svg", "पेशेवर शतरंज प्रशिक्षक"]
    ],
    "ourAdvantagesTitle": "हमारे फायदे",
    "ourAdvantagesElements": [
      ["/img/landing/icons/pawns-3.svg", "विभिन्न आयु वर्गों के साथ सत्र आयोजित करने की क्षमता"],
      ["/img/landing/icons/ai.svg", "आर्टिफ़िशियल इंटेलिजेंस तकनीकों का उपयोग"],
      ["/img/landing/icons/pawns-6.svg", "समूह ऑनलाइन सत्र आयोजित करने की क्षमता"],
      ["/img/landing/icons/pc.svg", "पीसी, लैपटॉप, स्मार्टफ़ोन, टैबलेट, मोबाइल संस्करण के लिए अनुकूल इंटरफ़ेस"],
      ["/img/landing/icons/two-knights.svg", "ऑनलाइन टूर्नामेंट आयोजित करने की क्षमता"],
      ["/img/landing/icons/arrows.svg", "विर्टुअल पॉइंटर के साथ कर्सर और आकार की गति का समन्वय"],
      ["/img/landing/icons/lamp.svg", "निष्पक्ष खेल का नियंत्रण"],
      ["/img/landing/icons/server.svg", "टाइमवेब सेवाओं के साथ सिनर्जी (सर्वर पर विश्वसनीय होस्टिंग)"],
    ],
    "ourExpertsTitle": "हमारे विशेषज्ञ",
    "ourExpertsElements": [
      ["/img/landing/experts/vladimir-bykov.png", "<b>व्लादिमीर बिकोव</b><br/><br/>सेंट पीटर्सबर्ग शतरंज संघ के कार्यकारी निदेशक, 'मेडनी व्सादनिक' टीम के कप्तान"],
      ["/img/landing/experts/ekaterina-sudoplatova.png", "<b>एकातेरिना सुदोप्लाटोवा</b><br/><br/>अंतरराष्ट्रीय महिला ग्रैंडमास्टर, अंतरराष्ट्रीय पुरुष मास्टर। रूस और विश्व चैंपियनशिप की दो बार की विजेता, यूरोपीय चैंपियनशिप की विजेता, रूसी महिला चैंपियनशिप की विजेता, सेंट पीटर्सबर्ग की कई बार की चैंपियन। शिक्षक, प्रशिक्षक"],
      ["/img/landing/experts/alina-balayan.png", "<b>अलीना बालायन</b><br/><br/>अंतरराष्ट्रीय महिला मास्टर, रूसी खेल की मास्टर। रूसी टीम की चैंपियन, रूसी महिला रैपिड चैंपियनशिप की विजेता, सेंट पीटर्सबर्ग की कई बार की चैंपियन। शिक्षक, प्रशिक्षक"],
      ["/img/landing/experts/valeriy-popov.png", "<b>वैलेरी पोपोव</b><br/><br/>अंतरराष्ट्रीय ग्रैंडमास्टर, उच्च श्रेणी के प्रशिक्षक। यूरोपीय चैंपियन, सेंट पीटर्सबर्ग शतरंज स्कूल के वरिष्ठ प्रशिक्षक"],
      ["/img/landing/experts/dina-belenkaya.png", "<b>दीना बेलेंकाया</b><br/><br/>महिला अंतरराष्ट्रीय ग्रैंडमास्टर, सेंट पीटर्सबर्ग की कई बार की चैंपियन, रूसी टीम चैंपियनशिप की विजेता, शतरंज प्रशिक्षक"],
      ["/img/landing/experts/anastasia-bondaruk.png", "<b>अनास्तासिया बोंदरुक</b><br/><br/>अंतरराष्ट्रीय मास्टर, सेंट पीटर्सबर्ग की कई बार की चैंपियन, रूसी चैंपियनशिप की विजेता, रूसी टीम की सदस्य"]
    ]
  },
  "footer": {
    "help" : "मदद",
    "contacts": "संपर्क",
    "supportUs": "परियोजना का समर्थन करें",
    "termsOfService": "सेवा की शर्तें"
  },
  "lesson": {
    "newTask": "New task",
    "chain": "श्रृंखला",
    "table": "टेबल",
    "clear": "साफ़ करें",
    "room": "कमरा",
    "defaultRoom": "डिफ़ॉल्ट कमरा",
    "ok": "ठीक है",
    "empty": "खाली",
    "sizeHuge": "बहुत बड़ा",
    "sizeLarge": "बड़ा",
    "sizeStandard": "मानक",
    "markersBlue": "नीला मार्कर",
    "markersGreen": "हरा मार्कर",
    "markersOff": "मार्कर बंद करें",
    "markersRed": "लाल मार्कर",
    "markersDelete": "मार्कर हटाएं",
    "withoutTitles": "शीर्षक के बिना",
    "titlesAtCellCorners": "कोशिका के कोनों पर शीर्षक",
    "titlesInEveryCell": "प्रत्येक कोशिका में शीर्षक",
    "titlesAroundChessboard": "शतरंज बोर्ड के चारों ओर शीर्षक",
    "standardView": "मानक दृश्य",
    "allChessboardsView": "सभी शतरंज बोर्ड दृश्य",
    "allCamsView": "सभी कैमरा दृश्य",
    "size": "आकार",
    "exit": "निकास",
    "materials": "ज्ञान का आधार",
    "rooms": "कमरे",
    "profile": "प्रोफ़ाइल",
    "edit": "संपादित करें",
    "paste": "चिपकाएं",
    "copy": "कॉपी करें",
    "save": "सहेजें",
    "cancel": "रद्द करें",
    "delete": "हटाएं",
    "add": "जोड़ें",
    "waitForTrainer": "प्रशिक्षक की प्रतीक्षा करें",
    "chat": "चैट",
    "cams": "कैमरे",
    "online": "ऑनलाइन",
    "view": "दृश्य",
    "demonstration": "प्रदर्शन",
    "settings": "सेटिंग्स",
    "offline": "ऑफ़लाइन",
    "black": "काला",
    "white": "सफ़ेद",
    "tasks": "प्रश्न",
    "moves": "चालें",
    "activeLessons": "ऑनलाइन पाठ",
    "activeLesson": "ऑनलाइन पाठ",
    "student": "छात्र",
    "trainer": "प्रशिक्षक",
    "lesson": "पाठ"
  },
  "captcha": {
    "language": "हिंदी",
    "errors": {
      "0": "कोई त्रुटि नहीं मिली",
      "3": "कैप्चा पарамет्र गलत हैं",
      "501": "कैप्चा नहीं मिली",
      "502": "कैप्चा पарамет्र गलत हैं",
      "503": "कैप्चा ईमेल गलत है",
      "504": "कैप्चा समाप्त हो गई",
      "505": "उत्तर समाप्त हो गया",
      "506": "कैप्चा उत्तर गलत है"
    }
  },
  "auth": {
    "new_password": "नया पासवर्ड",
    "repeat_new_password": "नया पासवर्ड दोहराएं",
    "consider_new_password": "एक नया पासवर्ड बनाएं और अपने ईमेल पर भेजे गए कोड को दर्ज करके इसे सत्यापित करें",
    "confirm": "सत्यापित करें",
    "agree_with": "मैं सहमत हूं ",
    "terms": "सर्विस के नियमों के साथ",
    "do_sign_up": "रजिस्टर करें",
    "continue": "जारी रखें",
    "code": "ईमेल से कोड",
    "email_to_restore": "पहुंच बहाल करने के लिए ईमेल पता दर्ज करें",
    "receive_code_in_mail": "ईमेल में कोड प्राप्त करें",
    "repeat_password": "पासवर्ड दोहराएं",
    "login_with": "इसके साथ लॉगिन करें",
    "login": "लॉगिन करें",
    "forgot_password": "पासवर्ड वापस प्राप्त करें",
    "or": "या",
    "email": "ईमेल पता",
    "password": "पासवर्ड",
    "no_account_yet": "अभी तक कोई खाता नहीं है?",
    "sign_up": "रजिस्टर करें",
    "yandex": "यांडेक्स",
    "ok": "ओके",
    "mail": "मेल.रु",
    "fb": "फेसबुक",
    "twitter": "ट्विटर",
    "vk": "वीके",
    "google": "गूगल",
    "choose_lg": "अपनी भाषा चुनें",
    "language": "हिंदी",
    "errors": {
      "0": "कोई त्रुटि नहीं है",
      "1": "उपयोगकर्ता नहीं मिला",
      "2": "उपयोगकर्ता पहले से मौजूद है",
      "3": "अनधिकृत पहुँच पарамет्र",
      "4": "उपयोगकर्ता सक्रिय नहीं है",
      "5": "गलत पासवर्ड",
      "6": "गलत सक्रियण कोड",
      "7": "गलत सेशन साइन",
      "8": "उपयोगकर्ता प्रमाणीकृत नहीं है",
      "9": "गलत पुनर्प्राप्ति कोड",
      "10": "उपयोगकर्ता पहले से सक्रिय है",
      "11": "गलत प्रमाणीकरण कोड",
      "12": "गलत सेटिंग्स प्राप्त हुए",
      "13": "गलत सामाजिक प्रदाता",
      "14": "पासवर्ड बहुत कमजोर है",
      "15": "उपयोगकर्ता के बारे में जानकारी प्राप्त करने में त्रुटि",
      "16": "OAuth एप्लिकेशन पहले से मौजूद है",
      "17": "OAuth एप्लिकेशन नहीं मिला",
      "18": "OAuth उपभोक्ता नहीं मिला",
      "19": "OAuth एप्लिकेशन की अधिकतम संख्या प्राप्त हुई",
      "20": "OAuth प्रमाणीकरण कोड नहीं मिला",
      "21": "OAuth एक्सेस टोकन नहीं मिला",
      "22": "SMTP मेल भेजने में त्रुटि",
      "23": "गलत इमेज टाइप",
      "24": "इमेज भेजने में त्रुटि"
    }
  },
  "permissions": {
    "OtherError": {
      "otherError": "आपका कैमरा अनुपलब्ध है. कृपया दूसरे डिवाइस का उपयोग करें."
    },
    "BlockedAtSystemLevelMacOs": {
      "camera": "कैमरा कनेक्ट नहीं है, या {{browserName}} को कैमरा एक्सेस ब्लॉक है सिस्टम लेवल पर.",
      "check": "जांचें कि कैमरा दूसरे एप्लिकेशन्स (जैसे फोटो बूथ) में काम कर रहा है. अगर कैमरा ठीक है, तो सुनिश्चित करें कि {{browserName}} को कैमरा एक्सेस है.",
      "p1_menu": "एप्पल मेनू चुनें",
      "p1_pref": "→ «सिस्टम सेटिंग्स», «प्राइवेसी» पर क्लिक करें, फिर «कैमरा» पर क्लिक करें;",
      "p2": "«कैमरा» पर क्लिक करें, {{browserName}} के साथ फ्लैग सेट करें;",
      "p3": "«माइक्रोफोन» पर क्लिक करें, {{browserName}} के साथ फ्लैग सेट करें;",
      "p4": "{{browserName}} को रीस्टार्ट करें और फिर से इंटरव्यू ट्राय करें.",
      "more": "वेबसाइट पर अधिक जानकारी",
      "support": "एप्पल सपोर्ट"
    },
    "NoSoundWindowsFirefox": {
      "sureConnected": "अगर आप सुनिश्चित हैं कि आपका माइक्रोफोन कनेक्ट है, तो संभवतः विंडोज प्राइवेसी सेटिंग्स में एक्सेस ब्लॉक है.",
      "goToStart": "स्टार्ट मेनू पर जाएं",
      "parameters": "पैरामीटर्स",
      "privacy": "प्राइवेसी",
      "mic": "माइक्रोफोन. सुनिश्चित करें कि:",
      "p1": "माइक्रोफोन एक्सेस इस डिवाइस के लिए एनेबल है;",
      "p2": "«एप्लिकेशन्स को माइक्रोफोन एक्सेस» एनेबल है;",
      "p3": "«क्लासिक एप्लिकेशन्स को माइक्रोफोन एक्सेस» (यदि है) एनेबल है;",
      "p4": "सेटिंग्स में बदलाव करने के बाद पेज को रिफ्रेश करें.",
      "more": "वेबसाइट पर अधिक जानकारी",
      "support": "माइक्रोसॉफ्ट सपोर्ट"
    },
    "NotAllowedAndroidChrome": {
      "check": "कैमरा और माइक्रोफोन एक्सेस नहीं मिला.",
      "p1": "साइट के एड्रेस बार में लॉक आइकन पर क्लिक करें",
      "p2": "पेरमिशन सेक्शन पर क्लिक करें;",
      "p3": "कैमरा और माइक्रोफोन एक्सेस को एनेबल करें;",
      "p4": "पेज को रिफ्रेश करें.",
      "i1": "अगर आप पेरमिशन सेक्शन नहीं देख पा रहे हैं, तो संभवतः एंड्रॉयड सिस्टम लेवल पर कैमरा और माइक्रोफोन एक्सेस ब्लॉक है. एंड्रॉयड सेटिंग्स में एप्लिकेशन्स सेक्शन में जाएं और सुनिश्चित करें कि क्रोम को कैमरा और माइक्रोफोन एक्सेस है. फिर से पेज को रिफ्रेश करें. वेबसाइट पर अधिक जानकारी",
      "more": "एंड्रॉयड सपोर्ट"
    },
    "NotAllowedBlink": {
      "check": "आपने कैमरा या माइक्रोफोन एक्सेस की अनुमति नहीं दी.",
      "p1": "साइट के एड्रेस बार में लॉक आइकन पर क्लिक करें",
      "p2": "कैमरा और माइक्रोफोन एक्सेस को एनेबल करें;",
      "p3": "पेज को रिफ्रेश करें",
      "p3_check": "या यहां क्लिक करें"
    },
    "NotAllowedBlinkWindowsSystem": {
      "check": "माइक्रोफोन/कैमरा एक्सेस नहीं मिला. संभवतः विंडोज 10 की प्राइवेसी सेटिंग्स में माइक्रोफोन एक्सेस ब्लॉक है.",
      "goToStart": "स्टार्ट मेनू पर जाएं",
      "parameters": "पैरामीटर्स",
      "privacy": "प्राइवेसी",
      "cameraAndSure": "माइक्रोफोन सेक्शन में जाएं, फिर कैमरा सेक्शन में जाएं और सुनिश्चित करें कि डिवाइस एक्सेस नहीं ब्लॉक है:",
      "p1": "माइक्रोफोन/कैमरा एक्सेस इस डिवाइस के लिए एनेबल है;",
      "p2": "«एप्लिकेशन्स को कैमरा एक्सेस» / «एप्लिकेशन्स को माइक्रोफोन एक्सेस» एनेबल है;",
      "p3": "«क्लासिक एप्लिकेशन्स को कैमरा एक्सेस» / «क्लासिक एप्लिकेशन्स को माइक्रोफोन एक्सेस» (यदि है) एनेबल है;",
      "p4": "सेटिंग्स में बदलाव करने के बाद पेज को रिफ्रेश करें.",
      "more": "वेबसाइट पर अधिक जानकारी",
      "support": "माइक्रोसॉफ्ट सपोर्ट"
    },
    "NotAllowedFirefox": {
      "check": "आपने कैमरा या माइक्रोफोन एक्सेस की अनुमति नहीं दी.",
      "p1": "साइट के एड्रेस बार में कैमरा या माइक्रोफोन आइकन पर क्लिक करें;",
      "p2": "कैमरा और माइक्रोफोन एक्सेस को एनेबल करें;",
      "p3": "पेज को रिफ्रेश करें;",
      "p4": "जब ब्राउज़र फिर से एक्सेस का अनुरोध करे, तो एनेबल करें.",
      "more": "अगर सुझाव काम नहीं करते हैं, तो",
      "support": "फायरफॉक्स डॉक्यूमेंटेशन का उपयोग करें"
    },
    "NotAllowedMacOsSafari": {
      "check": "आपने कैमरा या माइक्रोफोन एक्सेस की अनुमति नहीं दी.",
      "p1": "सफारी मेनू → सेटिंग्स पर जाएं और वेब साइट्स पर क्लिक करें;",
      "p2": "कैमरा और माइक्रोफोन को एक-एक करके चुनें और हंटिका डॉट वर्क्स साइट के लिए एनेबल करें;",
      "p3": "सेटिंग्स को बंद करें और पेज को रिफ्रेश करें.",
      "more": "वेबसाइट पर अधिक जानकारी",
      "support": "एप्पल सपोर्ट"
    },
    "NotConnected": {
      "notConnected": "लगता है कि आपका कैमरा कनेक्ट नहीं है. कृपया कैमरा कनेक्ट करें."
    },
    "NotReadableErrorAudioWindows": {
      "check": "माइक्रोफोन एक्सेस नहीं मिला. संभवतः विंडोज 10 की प्राइवेसी सेटिंग्स में माइक्रोफोन एक्सेस ब्लॉक है.",
      "goToStart": "स्टार्ट मेनू पर जाएं",
      "parameters": "पैरामीटर्स",
      "privacy": "प्राइवेसी",
      "mic": "माइक्रोफोन. सुनिश्चित करें कि:",
      "p1": "माइक्रोफोन एक्सेस इस डिवाइस के लिए एनेबल है;",
      "p2": "«एप्लिकेशन्स को माइक्रोफोन एक्सेस» एनेबल है;",
      "p3": "«क्लासिक एप्लिकेशन्स को माइक्रोफोन एक्सेस» (यदि है) एनेबल है;",
      "p4": "सेटिंग्स में बदलाव करने के बाद पेज को रिफ्रेश करें.",
      "more": "वेबसाइट पर अधिक जानकारी",
      "support": "माइक्रोसॉफ्ट सपोर्ट"
    },
    "NotReadableErrorVideoWindows": {
      "check": "कैमरा एक्सेस नहीं मिला. संभवतः कैमरा दूसरे एप्लिकेशन (जैसे स्काइप या जूम) द्वारा उपयोग में है. कैमरा उपयोग में नहीं है, तो संभवतः विंडोज 10 की प्राइवेसी सेटिंग्स में कैमरा एक्सेस ब्लॉक है.",
      "p1": "सभी एप्लिकेशन्स को बंद करें जो कैमरा उपयोग में हैं, फिर पेज को रिफ्रेश करें.",
      "p2": "अगर आप सुनिश्चित हैं कि कैमरा उपयोग में नहीं है, तो संभवतः विंडोज 10 की प्राइवेसी सेटिंग्स में कैमरा एक्सेस ब्लॉक है.",
      "goToStart": "स्टार्ट मेनू पर जाएं",
      "parameters": "पैरामीटर्स",
      "privacy": "प्राइवेसी",
      "cameraAndSure": "कैमरा और सुनिश्चित करें कि:",
      "a": "कैमरा एक्सेस इस डिवाइस के लिए एनेबल है;",
      "b": "«एप्लिकेशन्स को कैमरा एक्सेस» एनेबल है;",
      "c": "«क्लासिक एप्लिकेशन्स को कैमरा एक्सेस» (यदि है) एनेबल है;",
      "d": "सेटिंग्स में बदलाव करने के बाद पेज को रिफ्रेश करें.",
      "more": "वेबसाइट पर अधिक जानकारी",
      "support": "माइक्रोसॉफ्ट सपोर्ट"
    }
  },
  "rooms": {
    "rooms": "कमरे"
  }



};