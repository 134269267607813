import { ChessclubUser } from '../interfaces/user';
import { getLocalSocketId } from '../transport/Socket';
import {IUser} from "@chessclub/grpc_wrapper";

export function loggedUserFromGrpcUser(user: IUser): ChessclubUser {

  return {
    ...user,
    // roomId: null,
    // handUp: false,
    // microEnabled: false,
    // cameraEnabled: false,
    socketId: getLocalSocketId(),
  };
}