import { Theme, useThemeStore } from '../../store/themeStore';
import { PiecesColor } from '../../store/lesson-ui/LessonUiStoreApi';
import { allFiguresWhite } from './figures-icons/FiguresWhite';
import { allFiguresBlack } from './figures-icons/FiguresBlack';
import { StyledButton } from './common';
import { SvgImage } from '../Icon/SvgImage';
import { FigureButton } from './FigureButton';
import React from 'react';
import { useTheme } from 'styled-components';
import { KnownIcons } from '../Icon/KnownIcons';
import { selectFiguresIcons } from './figures-icons/selectFiguresIcons';
import { useChessboardStore } from '../../store/chessboard/ChessboardStore';

export function PiecesPanel() {
  const { togglePiecesColor, piecesColor } = useChessboardStore();
  const { theme } = useThemeStore();

  const isDark = theme === Theme.dark;
  const isWhite = piecesColor === PiecesColor.WHITE;
  const arr = isWhite ? allFiguresWhite : allFiguresBlack;

  const icons = selectFiguresIcons(isWhite, isDark);

  return <div style={{ display: 'flex', background: useTheme().currentTheme.hover, borderRadius: 12 }}>

    <StyledButton onClick={togglePiecesColor}>
      {/*<SvgImage icon={makeCircleIcon(piecesColor === PiecesColor.BLACK ? '#fff' : '#000')} />*/}
      <SvgImage icon={KnownIcons.ing_yang} rotate={isWhite ? 180 : 0} style={{transition: '0.3s'}}/>
    </StyledButton>

    {arr.map((piece, i) => {
      return <FigureButton key={i} fig={piece.piece}>
        <SvgImage icon={icons[piece.icon]} />
      </FigureButton>;
    })}

  </div>;
}