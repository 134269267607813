import {create} from 'zustand';
import { ReactNode } from 'react';


interface ModalStoreInitialState {
    modal: ReactNode;
    popup: {
        content: ReactNode;
        anchorEl: Element;
        id: number;
    } | null;
}

interface ModalStoreActions {
    openModal(modal: ReactNode): void,
    closeModal(): void,
    openPopup(id, content, anchorEl): void;
    closePopup(): void
}

type ModalStore = ModalStoreInitialState & ModalStoreActions;

export const useModalStore = create<ModalStore>((set, get) => ({
    modal: null,
    popup: null,
    openModal: (modal: ReactNode) => {
        set({ modal })
    },
    closeModal: () => {
        set({ modal: null })
    },
    openPopup(id, content, anchorEl) {
        set({ popup: { id, content, anchorEl } });
    },
    closePopup() {
        set({ popup: null })
    }
} as ModalStore));
