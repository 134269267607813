import React from 'react';
import { LargeText } from '../../components';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { JustText } from '../../components/JustText/JustText';
import { Space } from '../../components/low-level-components/Space';

export function SupportUs() {
  return <PageTemplate>

    <div style={{display:'flex', alignItems:'center', flexDirection: 'column'}}>
      <JustText>Support us</JustText>
      <Space size={20}/>
      <div style={{display:'flex', gap: 10}}>
        <CryptoIcon icon='14446252' name={'USDT'} />
        <CryptoIcon icon='14446160' name={'ETH'} />
      </div>
      <Space size={20}/>
      <JustText style={{userSelect:'text'}}>0x53a210adA95b196393137205Ad1D3ED2d9F1A287</JustText>
    </div>
  </PageTemplate>;
}

function CryptoIcon({ name, icon }: { name, icon }) {
  return <div style={{minWidth: 70, display: 'flex', flexDirection: 'column', alignItems:'center', gap: 10}}>
    <img src={`https://cdn-icons-png.flaticon.com/128/14446/${icon}.png`} width={32}/>
    <JustText>{name}</JustText>
  </div>;
}