import { ApplyCancelToolbarVariant } from '../../../../parts/ApplyCancelToolbarVariant';
import React from 'react';
import { useEditingTaskStore } from '../../../../store/editing-task/EditingTaskStore';
import { useAdminKnowledgesStore } from '../../../../store/admin/AdminKnowledgesStore';

export function KnowledgeEditingTaskToolbar() {

  const { editingTask, stopEditTask } = useEditingTaskStore();
  const { putKnowledge } = useAdminKnowledgesStore();

  async function saveKnowledge() {
    await putKnowledge(editingTask);
    await stopEditTask();
  }
  
  return <ApplyCancelToolbarVariant apply={saveKnowledge} cancel={stopEditTask} />;
}