import { useLocalization } from '../../l10n/UseLocalization';
import { useHistory, useLocation } from 'react-router-dom';
import { useModalStore } from '../../store/modalStore';
import { useAuthStore } from '../../store/auth/AuthStore';
import React from 'react';
import { PopupMenuItem } from '../PopupMenu/PopupMenuItem';
import { PopupMenuSeparator } from '../PopupMenu/PopupMenuSeparator';
import { KnownIcons } from '../Icon/KnownIcons';

export function UserProfilePopupMenu() {
  const { lesson } = useLocalization();
  const location = useLocation();
  const { exit } = useAuthStore();
  const history = useHistory();

  const route = (route: string) => ({
    onClick: () => history.push(route),
    active: route === location.pathname
  });

  return <>
    <PopupMenuItem text={lesson.settings} icon={KnownIcons.gear} {...route('/user-settings')}/>
    <PopupMenuItem text={lesson.rooms} icon={KnownIcons.arrow_up_in_rect} {...route('/rooms')}/>
    <PopupMenuItem text={lesson.materials} icon={KnownIcons.two_and_half_lines} {...route('/materials')}/>
    <PopupMenuSeparator/>
    <PopupMenuItem text={lesson.exit} icon={KnownIcons.exit} onClick={() => exit()}/>
  </>
}

