import Centrifuge from 'centrifuge'
import {SignallingTransportInterface} from '@chessclub/video-conf'
import {
    addCentrifugeEventListener, centrifugeInstance, createChannelKey,
    emitCentrifugeEvent,
    joinChannelIfAlreadyNot
} from '@chessclub/realtime_infrastructure'

export interface Event {
    event: string
    data: any
    channel: string
}

export interface JoinResult {
    userId: string
    users: string[]
    history: Centrifuge.HistoryResult
}

export class CentrifugeTransport implements SignallingTransportInterface {
    socket: Centrifuge

    eventsQueue: Event[] = []
    token: string
    socketId: string

    constructor(socketUrl: string, token: string) {
        this.socket = centrifugeInstance.socket;
    }

    async emit(channel: string, event: string, data: any): Promise<void> {
        await emitCentrifugeEvent(
            createChannelKey(channel),
            { eventName: event },
            data
        )
    }

    async join(channel: string): Promise<JoinResult> {
        const {subscription, alreadySubscribed} = await joinChannelIfAlreadyNot(createChannelKey(channel), this.eventsQueue)

        if (alreadySubscribed) {
            return new Promise(async (resolve, reject) => {
                const presence = await subscription.presence();
                const history = await subscription.history({
                    limit: 1000,
                    reverse: true
                });

                const users = Object.keys(presence.presence).filter((user) => user !== this.socketId);
                resolve({
                    userId: this.socketId,
                    users,
                    history,
                })
            })
        }

        return new Promise((resolve, reject) => {
            // User joined
            subscription.once('join', async (data) => {
                const presence = await subscription.presence();
                const history = await subscription.history({
                    limit: 1000,
                    reverse: true
                });

                this.socketId = data.info.client;

                const users = Object.keys(presence.presence).filter((user) => user !== data.info.client);
                resolve({
                    userId: data.info.client,
                    users,
                    history,
                })
            })
        })

    }

    on(channel: string, event: string, handler: (data: any) => void): void {
        addCentrifugeEventListener(
            createChannelKey(channel),
            {eventName: event},
            (data) => {
                handler(data);
            }
        )
    }
}
