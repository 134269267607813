import React, { CSSProperties, FunctionComponent } from 'react';
import { BoardLabels } from '@chessclub/grpc_wrapper';

interface IProps {
  title: BoardLabels;
  size?: number;
  style?: CSSProperties;
}

export const ChessboardIcon: FunctionComponent<IProps> = ({ title, style, size = 64 }) => {

  let light =  '#fee7c8';
  let dark =  '#847c68';

  const LargeTitle = (
    <>
      <path
        d='M13.0767 50.8457H8.01318L6.87598 54H5.23242L9.84766 41.9141H11.2422L15.8657 54H14.2305L13.0767 50.8457ZM8.49463 49.5342H12.6035L10.5449 43.8813L8.49463 49.5342ZM22.1411 54H20.5972V43.7651L17.501 44.9023V43.5078L21.9004 41.856H22.1411V54Z'
        fill={light}
      />
      <path
        d='M38.4028 54V41.9141H42.354C43.6655 41.9141 44.6506 42.1852 45.3091 42.7275C45.9731 43.2699 46.3052 44.0723 46.3052 45.1348C46.3052 45.6992 46.1447 46.2 45.8237 46.6372C45.5028 47.0688 45.0656 47.4036 44.5122 47.6416C45.1652 47.8242 45.6799 48.1729 46.0562 48.6875C46.438 49.1966 46.6289 49.8053 46.6289 50.5137C46.6289 51.5983 46.2775 52.4505 45.5747 53.0703C44.8719 53.6901 43.8786 54 42.5947 54H38.4028ZM39.9966 48.3472V52.6968H42.6279C43.3695 52.6968 43.9533 52.5059 44.3794 52.124C44.811 51.7367 45.0269 51.2054 45.0269 50.5303C45.0269 49.0749 44.2355 48.3472 42.6528 48.3472H39.9966ZM39.9966 47.0688H42.4038C43.1011 47.0688 43.6572 46.8945 44.0723 46.5459C44.4928 46.1973 44.7031 45.7241 44.7031 45.1265C44.7031 44.4624 44.5094 43.981 44.1221 43.6821C43.7347 43.3778 43.1453 43.2256 42.354 43.2256H39.9966V47.0688ZM53.6431 54H52.0991V43.7651L49.0029 44.9023V43.5078L53.4023 41.856H53.6431V54Z'
        fill={dark}
      />
      <path
        d='M13.0767 18.8457H8.01318L6.87598 22H5.23242L9.84766 9.91406H11.2422L15.8657 22H14.2305L13.0767 18.8457ZM8.49463 17.5342H12.6035L10.5449 11.8813L8.49463 17.5342ZM25.0132 22H17.0942V20.896L21.2778 16.2476C21.8976 15.5448 22.3237 14.9748 22.5562 14.5376C22.7941 14.0949 22.9131 13.6383 22.9131 13.168C22.9131 12.5371 22.7222 12.0197 22.3403 11.6157C21.9585 11.2118 21.4494 11.0098 20.813 11.0098C20.0493 11.0098 19.4544 11.2284 19.0283 11.6655C18.6077 12.0972 18.3975 12.7004 18.3975 13.4751H16.8618C16.8618 12.3628 17.2188 11.4635 17.9326 10.7773C18.652 10.0911 19.6121 9.74805 20.813 9.74805C21.9364 9.74805 22.8245 10.0441 23.4775 10.6362C24.1305 11.2228 24.457 12.0059 24.457 12.9854C24.457 14.1751 23.6989 15.5918 22.1826 17.2354L18.9453 20.7466H25.0132V22Z'
        fill={dark}
      />
      <path
        d='M38.4028 22V9.91406H42.354C43.6655 9.91406 44.6506 10.1852 45.3091 10.7275C45.9731 11.2699 46.3052 12.0723 46.3052 13.1348C46.3052 13.6992 46.1447 14.2 45.8237 14.6372C45.5028 15.0688 45.0656 15.4036 44.5122 15.6416C45.1652 15.8242 45.6799 16.1729 46.0562 16.6875C46.438 17.1966 46.6289 17.8053 46.6289 18.5137C46.6289 19.5983 46.2775 20.4505 45.5747 21.0703C44.8719 21.6901 43.8786 22 42.5947 22H38.4028ZM39.9966 16.3472V20.6968H42.6279C43.3695 20.6968 43.9533 20.5059 44.3794 20.124C44.811 19.7367 45.0269 19.2054 45.0269 18.5303C45.0269 17.0749 44.2355 16.3472 42.6528 16.3472H39.9966ZM39.9966 15.0688H42.4038C43.1011 15.0688 43.6572 14.8945 44.0723 14.5459C44.4928 14.1973 44.7031 13.7241 44.7031 13.1265C44.7031 12.4624 44.5094 11.981 44.1221 11.6821C43.7347 11.3778 43.1453 11.2256 42.354 11.2256H39.9966V15.0688ZM56.5151 22H48.5962V20.896L52.7798 16.2476C53.3996 15.5448 53.8257 14.9748 54.0581 14.5376C54.2961 14.0949 54.415 13.6383 54.415 13.168C54.415 12.5371 54.2241 12.0197 53.8423 11.6157C53.4604 11.2118 52.9513 11.0098 52.3149 11.0098C51.5513 11.0098 50.9564 11.2284 50.5303 11.6655C50.1097 12.0972 49.8994 12.7004 49.8994 13.4751H48.3638C48.3638 12.3628 48.7207 11.4635 49.4346 10.7773C50.154 10.0911 51.1141 9.74805 52.3149 9.74805C53.4383 9.74805 54.3265 10.0441 54.9795 10.6362C55.6325 11.2228 55.959 12.0059 55.959 12.9854C55.959 14.1751 55.2008 15.5918 53.6846 17.2354L50.4473 20.7466H56.5151V22Z'
        fill={light}
      />
    </>
  );

  const SmallTitle = (
    <>
      <path
        d='M27.5078 59.9492H23.9121L23.2285 62H21.0479L24.7529 52.0469H26.6533L30.3789 62H28.1982L27.5078 59.9492ZM24.4658 58.2881H26.9541L25.7031 54.5625L24.4658 58.2881Z'
        fill={light}
      />
      <path d='M7.47559 44H5.5V36.3848L3.1416 37.1162V35.5098L7.26367 34.0332H7.47559V44Z' fill={light} />
      <path
        d='M9.49902 13H2.67676V11.6465L5.89648 8.21484C6.33854 7.73177 6.66439 7.31022 6.87402 6.9502C7.08822 6.59017 7.19531 6.24837 7.19531 5.9248C7.19531 5.48275 7.08366 5.13639 6.86035 4.88574C6.63704 4.63053 6.31803 4.50293 5.90332 4.50293C5.45671 4.50293 5.10352 4.65788 4.84375 4.96777C4.58854 5.27311 4.46094 5.67643 4.46094 6.17773H2.47852C2.47852 5.57161 2.62207 5.0179 2.90918 4.5166C3.20085 4.0153 3.611 3.62337 4.13965 3.34082C4.66829 3.05371 5.26758 2.91016 5.9375 2.91016C6.96289 2.91016 7.75814 3.15625 8.32324 3.64844C8.8929 4.14062 9.17773 4.83561 9.17773 5.7334C9.17773 6.22559 9.05013 6.72689 8.79492 7.2373C8.53971 7.74772 8.10221 8.34245 7.48242 9.02148L5.21973 11.4072H9.49902V13Z'
        fill={dark}
      />
      <path
        d='M53.8887 62V52.0469H57.375C58.5827 52.0469 59.4987 52.2793 60.123 52.7441C60.7474 53.2044 61.0596 53.8812 61.0596 54.7744C61.0596 55.262 60.9342 55.6927 60.6836 56.0664C60.4329 56.4355 60.0843 56.7067 59.6377 56.8799C60.1481 57.0075 60.5492 57.265 60.8408 57.6523C61.137 58.0397 61.2852 58.5137 61.2852 59.0742C61.2852 60.0312 60.9798 60.7559 60.3691 61.248C59.7585 61.7402 58.888 61.9909 57.7578 62H53.8887ZM55.9395 57.666V60.3525H57.6963C58.1794 60.3525 58.5553 60.2386 58.8242 60.0107C59.0977 59.7783 59.2344 59.4593 59.2344 59.0537C59.2344 58.1423 58.7627 57.6797 57.8193 57.666H55.9395ZM55.9395 56.2168H57.457C58.4915 56.1986 59.0088 55.7861 59.0088 54.9795C59.0088 54.5283 58.8766 54.2048 58.6123 54.0088C58.3525 53.8083 57.9401 53.708 57.375 53.708H55.9395V56.2168Z'
        fill={dark}
      />
    </>
  );
  const Letters = <>{
    title === 'cell-center' ? LargeTitle :
      title === 'perimeter-inside' ? SmallTitle :
        null
  }</>;

  return (
    <svg style={style} width={size} height={size} viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='63' height='63' fill={light} stroke={dark} />
      <rect width='32' height='32' transform='matrix(-1 0 0 1 64 0)' fill={dark} />
      <rect width='32' height='32' transform='matrix(-1 0 0 1 32 32)' fill={dark} />
      {Letters}
    </svg>
  );
};

