import React, { CSSProperties } from 'react';
import { PropsWithChildren } from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

type TextProps = PropsWithChildren<{
  style?: CSSProperties;
  size?: number;
  className?: string;
}>

const Text = styled.div<TextProps>`
  font-family: Roboto,serif;
  display: flex;
  align-items: center;
  color: var(--color-icon-main) !important;
  font-size: ${p => p.size ? p.size + "px" : 'unset'}!important;
`;


export function TextNode(props: TextProps) {
  return <Text {...props} size={props.size}>
    {props.children}
  </Text>
}