import { ApplyCancelToolbarVariant } from '../../../../parts/ApplyCancelToolbarVariant';
import React from 'react';
import { useEditingTopicStore } from '../../../../store/editing-topic/EditingTopicStore';
import { useAdminKnowledgesStore } from '../../../../store/admin/AdminKnowledgesStore';

export function KnowledgeEditingTopicToolbar() {

  const { editingTopic, stopEditTopic } = useEditingTopicStore();
  const { putTopic} = useAdminKnowledgesStore();

  async function saveTopic() {
    await putTopic(editingTopic);
    await stopEditTopic();
  }

  return <ApplyCancelToolbarVariant apply={saveTopic} cancel={stopEditTopic} />;
}