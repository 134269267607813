import { Chess } from '@chessclub/ext_chess.ts';
import { FEN } from '@chessclub/grpc_wrapper';

const chess = new Chess();

function rndi(x,y = 0) {
  return y + Math.random() * x | 0;
}

function pick(arr) {
  return arr[rndi(arr.length)];
}

export function randomFen() : FEN {
  chess.clear();
  const count = 5 + (Math.random() * 10)|0;
  for (let i = 0; i < count; i++) {
    const piece = {type: pick("pnbrqk"), color:  pick("wb")};
    const square = pick('abcdefjh') + rndi(8,1);
    chess.put(piece, square)
  }
  const fen = chess.fen();
  return fen as FEN;
}