import React from 'react';
import { HidingScrollbar } from '../../../../../components/low-level-components/HidingScrollbar';
import { useLessonStore } from '../../../../../store/lesson/LessonStore';
import { useConferenceStore } from '../../../../../store/conferenceStore';
import { Role } from '@chessclub/grpc_wrapper';
import { LocalCameraCard } from './CameraCard/variants/LocalCameraCard';
import { RemoteCameraCard } from './CameraCard/variants/RemoteCameraCard';


export function VideoRoom() {
  const { teacherId, users: usersRoom, currentUser, isStudentLayout, isOnline } = useLessonStore();

  const filteredUsers = usersRoom
    .filter(u => isOnline ? u.socketId : true)
    .filter(u => u.id !== teacherId )
    .filter(u => u.id !== currentUser?.id);

  return <HidingScrollbar
    style={{
      overflowX: 'hidden',
      overflowY: 'scroll',
      border: 'none',
      height: '100%',
    }}
  >
    {isStudentLayout && <LocalCameraCard key={-1}/>}
    {filteredUsers.map((user, i) => <div key={i} style={{ marginTop: 3, marginRight: 1 }}>
      <RemoteCameraCard user={user} />
    </div>)}
  </HidingScrollbar>;
}
