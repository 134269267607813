import { create } from 'zustand';
import { ServerApi } from '../transport/ServerApi';
import { IMessage, MessageId, RoomId } from '@chessclub/grpc_wrapper';
import { subscribeAtLessonChange } from '../helpers/subscribeAtLessonChange';
import { addCentrifugeEventListener } from '@chessclub/realtime_infrastructure';
import {
  ChatChannel,
  ChatMessage,
  ChatMessageId,
  FullChatMessage,
  makeChatChannelKey,
} from '../transport/messaging/ChatChannel';
import { ChannelEvent } from '@chessclub/realtime_infrastructure/src/RealtimeInfrastructure';

export interface ChatStoreInitialState {
  messages: IMessage[];
}

export interface ChatStoreActions {
  createChatMessage(chatMessage: IMessage): void;

  deleteChatMessage(id: MessageId): void;

  updateChatMessage(chatMessage: IMessage): void;
}

export type ChatStore = ChatStoreInitialState & ChatStoreActions;

export const useChatStore = create<ChatStore>((set, get) => {

  const subscriptions = [];

  subscribeAtLessonChange(async (roomId: RoomId) => {
    console.log('init chat for room: ' + roomId);

    function on<T>(evt: ChannelEvent<T>, callback: (data: T) => void) {
      subscriptions.push(addCentrifugeEventListener(makeChatChannelKey(roomId), evt, callback));
    }

    subscriptions.forEach(off => off());
    subscriptions.splice(0, subscriptions.length);

    on(ChatChannel.CREATE_EVENT, (msg: FullChatMessage) => {
      const message : IMessage = {
        roomId: msg.roomId,
        text: msg.text,
        userId: msg.userId,
        id: msg.id as MessageId,
        responseId: msg.responseId as MessageId,
        epoch: msg.epoch
      }
      set({ messages: [...get().messages, message] });
    });

    on(ChatChannel.DELETE_EVENT, (id: ChatMessageId) => {
      set({ messages: get().messages.filter(m => m.id !== id) });
    });

    on(ChatChannel.EDIT_EVENT, (msg: ChatMessage)  =>{
        function edit(m: IMessage) {
          if (m.id === msg.id)
            m.text = msg.text;
          return m;
        }
        set({ messages: get().messages.map(edit) });
      }
    );

    const messages = await ServerApi.chatService.getPreviousMessageByRoomId(roomId, null, 100);

    set({ messages: messages.reverse() });
  });



  return {
    messages: [],

    async createChatMessage(chatMessage: IMessage) {
      await ServerApi.chatService.createMessage(chatMessage);
    },

    async deleteChatMessage(id: MessageId) {
      await ServerApi.chatService.deleteMessageById(id);
    },

    async updateChatMessage(chatMessage: IMessage) {
      await ServerApi.chatService.updateMessage(chatMessage);
    },

  } as ChatStore;
});
